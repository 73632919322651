import { Button, Input, Select, Switch } from "antd";
import { useCallback, useContext, useState } from "react";
import { SiteContext } from "../../../Contexts";
import APIManager from "../../../scripts/APIManager";
import Modal from "../../Modal";

export default function ActionModal(props) {
  const { actioningReports, setActioningReports, refreshList } = props;
  const { guildId, openNotification, activeModal, setActiveModal } =
    useContext(SiteContext);
  const [action, setAction] = useState(null);
  const [deleted, setDeleted] = useState(false);
  const [reasoning, setReasoning] = useState("");
  const [actionMessage, setActionMessage] = useState("");
  const [timeoutLength, setTimeoutLength] = useState(null);
  const [falsePositive, setFalsePositive] = useState(false);

  const timeoutOptions = [
    { label: "60 seconds", value: 1 },
    { label: "5 minutes", value: 5 },
    { label: "10 minutes", value: 10 },
    { label: "1 hour", value: 60 },
    { label: "1 day", value: 1440 },
    { label: "1 week", value: 10080 },
  ];

  const actionOptions = [
    { label: <span className="italic">None</span>, value: "" },
    { label: "Warn", value: "warn" },
    { label: "Timeout", value: "timeout" },
    { label: "Kick", value: "kick" },
    { label: "Ban", value: "ban" },
  ];

  const sendActions = useCallback(
    async () =>
      await APIManager.sendRequest(
        "set_report_actions",
        {
          guild_id: guildId,
          ids: actioningReports.map(({ id }) => id),
          messages: actioningReports.map(({ message }) => message),
          action,
          deleted,
          timeout_length: timeoutLength,
          enforcement_reason: reasoning,
          action_message: actionMessage,
          false_positive: falsePositive,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.suggested)
          openNotification(
            "success",
            "Suggested",
            "Your change has been suggested."
          );
        else if (data.success) {
          openNotification(
            "success",
            "Submitted",
            "Your actions have been submitted."
          );
        } else if (!data.success || data.message) {
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message
          );
        }
      }),
    [
      actioningReports,
      action,
      deleted,
      reasoning,
      actionMessage,
      timeoutLength,
      falsePositive,
      openNotification,
      guildId,
    ]
  );

  return (
    <Modal
      className="flex flex-col gap-10 max-w-sm p-10"
      isActive={activeModal === "action"}
      onClose={() => {
        setActioningReports([]);
        setActiveModal(null);
        setAction(null);
        setDeleted(false);
        setReasoning("");
        setActionMessage("");
        setTimeoutLength(null);
        setFalsePositive(false);
      }}
      header="Take Actions"
    >
      <div className="flex flex-col gap-3 mx-2">
        {actioningReports ? (
          <p className="text-sm font-normal softer-text">
            You are taking action on {actioningReports.length} report
            {actioningReports.length === 1 ? "" : "s"}.
          </p>
        ) : null}
        <p className="text-sm softer-text">
          The chosen actions will be taken on the message(s) and user(s).
        </p>
        <div
          className={`flex flex-col gap-3 transition-opacity ${
            falsePositive ? "opacity-30" : ""
          }`}
        >
          <div className="flex flex-row flex-wrap items-center justify-between gap-6">
            <Select
              disabled={falsePositive}
              size="large"
              placeholder="Action Message"
              onChange={(value) => setAction(value !== "" ? value : null)}
              value={action}
              options={actionOptions}
              className="grow"
            />
            <label className="flex flex-row gap-3">
              <Switch
                disabled={falsePositive}
                checked={deleted}
                onChange={(value) => setDeleted(value)}
              />
              <p className="whitespace-nowrap">Delete Message</p>
            </label>
          </div>
          <div className="h-12">
            <div
              className={`shrink-0 flex-row items-center gap-3 ${
                falsePositive || action !== "timeout" ? "hidden" : "flex"
              }`}
            >
              <label className="flex flex-row items-center gap-3">
                <p className="w-24 whitespace-nowrap">Timeout for</p>
              </label>
              <Select
                disabled={falsePositive || action !== "timeout"}
                size="large"
                placeholder="Time"
                onChange={(value) => setTimeoutLength(value)}
                value={timeoutLength}
                options={timeoutOptions}
                className="grow"
              />
            </div>
          </div>

          <label className="flex flex-col gap-2">
            <p>Reasoning</p>
            <Input
              disabled={falsePositive}
              size="large"
              placeholder="Justification of action"
              onChange={({ target }) => setReasoning(target.value)}
              value={reasoning}
              className="grow"
            />
          </label>

          <label className="flex flex-col gap-2">
            <p>Message</p>
            <Input
              disabled={falsePositive}
              size="large"
              placeholder="Message to user"
              onChange={({ target }) => setActionMessage(target.value)}
              value={actionMessage}
              className="grow"
            />
          </label>
        </div>
        <hr className="border-black/10 -mx-2" />
        <label className="flex flex-row items-center gap-3 transition-opacity">
          <Switch
            checked={falsePositive}
            onChange={(value) => setFalsePositive(value)}
          />
          <p className="whitespace-nowrap">False Positive</p>
        </label>
      </div>
      <div className="flex flex-row-reverse gap-2 m-2">
        <Button
          type="primary"
          size="large"
          className="w-32 font-sans"
          onClick={() => {
            setActiveModal(null);
            sendActions().then(() => {
              setActioningReports([]);
              setAction(null);
              setDeleted(false);
              refreshList(false);
              setReasoning("");
              setActionMessage("");
              setTimeoutLength(null);
              setFalsePositive(false);
            });
          }}
          disabled={
            !(action && reasoning && (action !== "timeout" || timeoutLength)) &&
            !(deleted && reasoning) &&
            !falsePositive
          }
        >
          Confirm
        </Button>
        <Button
          size="large"
          className="w-32 font-sans"
          onClick={() => {
            setActioningReports([]);
            setActiveModal(null);
            setAction(null);
            setDeleted(false);
            setReasoning("");
            setActionMessage("");
            setTimeoutLength(null);
            setFalsePositive(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
