import { CloseOutlined } from "@ant-design/icons";
import { Button, Skeleton } from "antd";
import { useContext } from "react";
import { ThemeContext } from "../Contexts";

export default function Card(props) {
  const { darkMode: darkModeSystem } = useContext(ThemeContext);
  const {
    children,
    className,
    darkMode: darkModeOverride,
    noMode,
    noShadow,
    onClick,
    opaque,
    header,
    closeButton,
    onClose,
    loading,
  } = props;
  const darkMode =
    darkModeOverride !== undefined ? darkModeOverride : darkModeSystem;

  return (
    <div
      className={`flex flex-col justify-stretch gap-2 p-2 rounded-[12px] border border-solid ${
        noShadow
          ? ""
          : noMode
          ? "site-shadow"
          : darkMode
          ? "dark-site-shadow border-[#303030]"
          : "site-shadow border-black/10"
      } ${
        noMode
          ? ""
          : opaque
          ? darkMode
            ? "bg-dark-white"
            : "bg-white"
          : darkMode
          ? "bg-dark-white/50"
          : "bg-white/50"
      } ${className}`}
      onClick={onClick}
    >
      {!!header ? (
        <div className="flex flex-row items-stretch -mx-2 -mt-2 border-0 border-b border-solid border-black/10 dark:border-[#303030]">
          <div className="grow self-center flex flex-row justify-stretch items-center gap-2 mx-4 my-3">
            {typeof header === "string" ? (
              <p className="font-medium">{header}</p>
            ) : (
              header
            )}
          </div>
          <div className="shrink self-start">
            {closeButton ? (
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={onClose}
                className="m-2"
              />
            ) : null}
          </div>
        </div>
      ) : null}
      {loading ? (
        <Skeleton className="p-2" paragraph={{ rows: 5 }} />
      ) : (
        children
      )}
    </div>
  );
}
