import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import * as am5percent from "@amcharts/amcharts5/percent";
import { Select, Spin } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { SiteContext } from "../../Contexts";
import Card from "../Card";

export default function UsersByAction(props) {
  const { darkMode } = useContext(SiteContext);
  const { className, chartClassName, data: inputData } = props;
  const [data, setData] = useState(null);
  const [selectedAction, setSelectedAction] = useState(false);
  const [actions, setActions] = useState(null);
  const chartDiv = useRef(null);

  useEffect(() => {
    if (!data) return;

    const root = am5.Root.new(chartDiv?.current);

    let themes = [am5themes_Animated.new(root)];
    if (darkMode) themes.push(am5themes_Dark.new(root));
    root.setThemes(themes);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalHorizontal,
      })
    );

    // Create series
    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "count",
        categoryField: "user",
        legendLabelText: "{category}",
        legendValueText: "{value}",
      })
    );
    series.slices.template.set("tooltipText", "{category}: {value}");
    series.labels.template.set("text", "{category}: {value}");
    series.data.setAll(data);

    return () => root.dispose();
  }, [darkMode, data]);

  useEffect(
    () =>
      setData(
        Object.entries(
          inputData.reduce((users, item) => {
            if (selectedAction && item.action !== selectedAction) return users;

            if (item.user in users) {
              users[item.user]++;
            } else {
              users[item.user] = 1;
            }

            return users;
          }, {})
        )
          .map(([user, count]) => ({ user, count }))
          .sort((item1, item2) => item2.count - item1.count)
      ),
    [inputData, selectedAction]
  );

  useEffect(
    () =>
      setActions(
        inputData.reduce((actions, item) => {
          if (!actions.map(({ label }) => label).includes(item.action))
            actions.push({ value: item.action, label: item.action });

          return actions;
        }, [])
      ),
    [inputData]
  );

  return (
    <Card
      className={className}
      header={
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row items-center gap-3">
            <h1>User Breakdown</h1>
          </div>
          {actions ? (
            <div className="flex flex-row items-center gap-2">
              <p>Filter Action:</p>
              <Select
                size="medium"
                dropdownMatchSelectWidth={false}
                onChange={setSelectedAction}
                value={selectedAction}
                options={[{ value: false, label: "All Actions" }, ...actions]}
              />
            </div>
          ) : (
            <Spin size="large" />
          )}
        </div>
      }
    >
      {data ? (
        <div
          ref={chartDiv}
          className={`w-full h-96 m-2 ${chartClassName}`}
        ></div>
      ) : (
        <div
          className={`flex flex-row justify-center items-center w-full h-96 m-2 ${chartClassName}`}
        >
          <Spin size="large" />
        </div>
      )}
    </Card>
  );
}
