import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import * as am5percent from "@amcharts/amcharts5/percent";
import { Select, Spin } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { SiteContext } from "../../Contexts";
import Card from "../Card";

export default function ActionsByUser(props) {
  const { darkMode } = useContext(SiteContext);
  const { className, chartClassName, data: inputData } = props;
  const [data, setData] = useState(null);
  const [selectedUser, setSelectedUser] = useState(false);
  const [users, setUsers] = useState(null);
  const chartDiv = useRef(null);

  useEffect(() => {
    if (!data) return;

    const root = am5.Root.new(chartDiv?.current);

    let themes = [am5themes_Animated.new(root)];
    if (darkMode) themes.push(am5themes_Dark.new(root));
    root.setThemes(themes);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalHorizontal,
      })
    );

    // Create series
    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "count",
        categoryField: "action",
        legendLabelText: "{category}",
        legendValueText: "{value}",
      })
    );
    series.slices.template.set("tooltipText", "{category}: {value}");
    series.labels.template.set("text", "{category}: {value}");
    series.data.setAll(data);

    return () => root.dispose();
  }, [darkMode, data]);

  useEffect(
    () =>
      setData(
        Object.entries(
          inputData.reduce((actions, item) => {
            if (selectedUser && item.user !== selectedUser) return actions;

            if (item.action in actions) {
              actions[item.action]++;
            } else {
              actions[item.action] = 1;
            }

            return actions;
          }, {})
        )
          .map(([action, count]) => ({ action, count }))
          .sort((item1, item2) => item2.count - item1.count)
      ),
    [inputData, selectedUser]
  );

  useEffect(
    () =>
      setUsers(
        inputData.reduce((users, item) => {
          if (!users.map(({ label }) => label).includes(item.user))
            users.push({ value: item.user, label: item.user });

          return users;
        }, [])
      ),
    [inputData]
  );

  return (
    <Card
      className={className}
      header={
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row items-center gap-3">
            <h1>Action Breakdown</h1>
          </div>
          {users ? (
            <div className="flex flex-row items-center gap-2">
              <p>Filter User:</p>
              <Select
                size="medium"
                dropdownMatchSelectWidth={false}
                onChange={setSelectedUser}
                value={selectedUser}
                options={[{ value: false, label: "All Users" }, ...users]}
              />
            </div>
          ) : (
            <Spin size="large" />
          )}
        </div>
      }
    >
      {data ? (
        <div
          ref={chartDiv}
          className={`w-full h-96 m-2 ${chartClassName}`}
        ></div>
      ) : (
        <div
          className={`flex flex-row justify-center items-center w-full h-96 m-2 ${chartClassName}`}
        >
          <Spin size="large" />
        </div>
      )}
    </Card>
  );
}
