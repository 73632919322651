import { faGavel, faScaleBalanced } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ConfigProvider, Skeleton, Tabs } from "antd";
import { useContext, useEffect, useState } from "react";
import { SiteContext } from "../../Contexts";
import Modal from "../../components/Modal";
import RuleList from "../../components/moderation/RuleList";
import constants from "../../constants";

export default function ConfigurationDashboard() {
  const { rules, refreshRules, setActiveModal, activeModal } =
    useContext(SiteContext);
  //const [modified, setModified] = useState(null);
  //const [sendChanges, setSendChanges] = useState(() => {});
  const [activeTab, setActiveTab] = useState(0);
  const [sort, setSort] = useState({ field: "time", order: "desc" });

  useEffect(() => refreshRules(), [refreshRules]);

  if (!rules) return <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <h1 className="softer-text">Dashboard</h1>
      </div>

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: constants.colors.primary["light-blue"],
            fontFamily: constants.fonts["sans-header"][0],
          },
        }}
      >
        <Tabs
          items={[
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faGavel} /> Basic Rules
                </span>
              ),
              key: 0,
            },
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faScaleBalanced} /> Custom
                  Rules
                </span>
              ),
              key: 2,
            },
          ]}
          onTabClick={(value) => {
            setActiveTab(value);
          }}
          className="w-full softer-text"
        />
      </ConfigProvider>
      <RuleList
        className="no-top"
        key={0}
        rules={rules}
        customSort={sort}
        setCustomSort={setSort}
        startLimit={10}
        customFilter={
          activeTab === 0 ? (rule) => rule.is_basic : (rule) => !rule.is_basic
        }
      />

      <Modal
        isActive={activeModal === "save"}
        onClose={() => setActiveModal(null)}
        className="flex flex-col gap-10 max-w-sm p-10"
      >
        <div className="flex flex-col gap-3">
          <h1>Are you sure you would like to make these changes?</h1>
        </div>
        <div className="flex flex-row-reverse gap-3">
          <Button
            type="primary"
            size="large"
            className="w-32 font-sans"
            // onClick={() => {
            //   setActiveModal(null);
            //   sendChanges();
            // }}
          >
            Confirm
          </Button>
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => setActiveModal(null)}
          >
            Cancel
          </Button>
        </div>
      </Modal>

      {/* {!(modified === true ? false : true) ? (
        <>
          <Card className="fixed flex flex-row justify-center w-fit left-0 right-0 bottom-0 mb-6 mx-auto">
            <div className="flex flex-row items-center gap-6">
              <div className="flex flex-col gap-1">
                <p>Save your changes</p>
              </div>
              <Button
                type="primary"
                size="large"
                className="w-32 font-sans"
                onClick={() => setActiveModal("save")}
                disabled={modified === true ? false : true}
              >
                Save
              </Button>
            </div>
          </Card>
          <div className="h-16"></div>
        </>
      ) : null} */}
    </>
  );
}
