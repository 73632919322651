import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import signInIcon from "../../images/landing/signin_icon.png";
import Logo from "../Logo";
import LandingNav from "./LandingNav";
import { useContext, useState } from "react";
import { AccountContext } from "../../Contexts";

export default function LandingHeader(props) {
  const { signedIn } = useContext(AccountContext);
  const { className } = props;
  const [navOpen, setNavOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-row items-center justify-between px-10 sm:px-12
        bg-[#303030] sm:bg-[#011546]/90 z-50 ${className}
        before:content-[''] before:absolute before:-z-10 before:inset-0 before:backdrop-blur`}
    >
      <Logo landingLink />
      <div className="flex flex-row items-center gap-3">
        {signedIn ? (
          <Button
            className="flex flex-row items-center gap-2 p-0 font-sans text-dark-black"
            type="text"
            onClick={() => {
              navigate(`/home`);
            }}
            icon={
              <img
                className="object-scale-down h-5"
                src={signInIcon}
                alt="User"
              />
            }
          ></Button>
        ) : null}
        <FontAwesomeIcon
          onClick={() => setNavOpen(true)}
          className="text-white hover:cursor-pointer"
          size="lg"
          icon={faBars}
        />
      </div>
      <LandingNav
        isActive={navOpen}
        onClose={() => setNavOpen(false)}
        className="bg-[#162020] flex flex-col gap-4 p-8 rounded-xl"
      >
        <Logo size="small" />
        <Link
          className="text-lg text-white font-light font-landing-header"
          to="/"
        >
          Home
        </Link>
        <Link
          className="text-lg text-white font-light font-landing-header"
          to="/login"
        >
          Register
        </Link>
        {/* <Link
          className="text-lg text-white font-light font-landing-header"
          to="/about"
        >
          About Us
        </Link> */}
        <Link
          className="text-lg text-white font-light font-landing-header"
          to="/subscribe"
        >
          Pricing
        </Link>
        <Link
          className="text-lg text-white font-light font-landing-header"
          to="/contact"
        >
          Contact
        </Link>
        {/* <Link
          className="text-lg text-white font-light font-landing-header"
          to="/cases"
        >
          Case Studies
        </Link> */}
        <div className="self-stretch h-px bg-secondary" />
        <Link
          className="text-lg text-white font-light font-landing-header"
          to="/login"
        >
          Login
        </Link>
      </LandingNav>
    </div>
  );
}
