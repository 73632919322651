import { Button, Select, Tooltip } from "antd";
import { Link } from "react-router-dom";
import DiscordIcon from "../../../images/discord-mark-blue.svg";
import Badge from "../../Badge";

export default function expandedColumns(
  expandedData,
  setExpandedData,
  feedbackTypes,
  sentiments,
  permissions,
  updateFeedbackMessageSentiment
) {
  return [
    {
      title: "User",
      key: "username",
      dataIndex: "username",
      className: "hidden sm:table-cell",
      width: 80,
      filters: expandedData
        .filter(
          ({ username }, index) =>
            expandedData.map(({ username }) => username).indexOf(username) ===
            index
        )
        .map(({ username }) => ({
          value: username,
          text: username,
        })),
      onFilter: (value, record) => record.username === value,
      render: (user) => (
        <Badge className="max-w-[6rem]" tooltip>
          {user}
        </Badge>
      ),
      sorter: (item1, item2) =>
        item1.username < item2.username
          ? -1
          : item1.username > item2.username
          ? 1
          : 0,
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (content, item) => (
        <div className="break-words max-w-md">
          <div className="inline font-semibold text-sm font-mono">
            {content.at(-1)}
          </div>
          <div className="inline pl-2">
            {item.url ? (
              <Link to={item.url} target="_blank">
                <Button
                  type="text"
                  icon={
                    <img
                      src={DiscordIcon}
                      width="16"
                      height="16"
                      alt="Discord Icon"
                      className="-mb-1"
                    />
                  }
                />
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
      sorter: (item1, item2) =>
        item1.content.at(-1) < item2.content.at(-1)
          ? -1
          : item1.content.at(-1) > item2.content.at(-1)
          ? 1
          : 0,
    },
    {
      title: "Time",
      key: "timestamp",
      dataIndex: "timestamp",
      className: "hidden sm:table-cell",
      render: (time) =>
        time ? (
          <Tooltip
            title={`${new Date(time).toLocaleTimeString("en-us", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}`}
          >
            {new Date(time).toLocaleTimeString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
              hour12: true,
            })}
          </Tooltip>
        ) : (
          ""
        ),
      sorter: (item1, item2) => item1.timestamp - item2.timestamp,
    },
    {
      title: "Sentiment",
      dataIndex: "sentiment",
      key: "sentiment",
      className: "hidden sm:table-cell",
      width: 120,
      filters: feedbackTypes.map(({ value, label }) => ({
        value,
        text: label,
      })),
      onFilter: (value, record) => record.sentiment === value,
      sorter: (item1, item2) =>
        item1.sentiment < item2.sentiment
          ? -1
          : item1.sentiment > item2.sentiment
          ? 1
          : 0,
      render: (_, item) => (
        <div className="flex flex-col gap-2">
          <Tooltip
            title={
              permissions?.["Action Spotlights"] ||
              permissions?.["Suggest Analytics Edits"]
                ? null
                : "You do not have permission."
            }
          >
            <div>
              <Select
                size="medium"
                dropdownMatchSelectWidth={false}
                onChange={(value) => {
                  updateFeedbackMessageSentiment(
                    item.messageid,
                    item.content,
                    value,
                    item.sentiment
                  );
                  setExpandedData(
                    expandedData.map((spotlight) =>
                      spotlight.messageid === item.messageid
                        ? { ...spotlight, sentiment: value }
                        : spotlight
                    )
                  );
                }}
                value={{ value: item.sentiment, label: item.sentiment }}
                options={sentiments}
                disabled={
                  !permissions?.["Edit Spotlights"] &&
                  !permissions?.["Suggest Analytics Edits"]
                }
              />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];
}
