import { Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import APIManager from "../../../scripts/APIManager";
import BarList from "../BarList";
import Card from "../../Card";

export default function ReportedUsers(props) {
  const { className, guildId } = props;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const requestData = useCallback(() => {
    APIManager.sendRequest(
      "get_analytics_reported_users",
      {
        guild_id: guildId,
      },
      true
    ).then((data) =>
      data.error ? setError(`error: ${data.error}`) : setData(data)
    );
  }, [guildId]);

  useEffect(() => requestData(), [requestData]);

  return (
    <Card className={className}>
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-3">
          <h1>Reported Users</h1>
        </div>
      </div>
      {data ? (
        <BarList className="m-2" items={data} itemName="User" valueName="Messages" limit={10} />
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="flex flex-row justify-center items-center">
          <Spin size="large" />
        </div>
      )}
    </Card>
  );
}
