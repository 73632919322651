import { Skeleton } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { SiteContext } from "../Contexts";
import ActionsByUser from "../components/admin/ActionsByUser";
import ActionsList from "../components/admin/ActionsList";
import ChangedTypes from "../components/admin/ChangedTypes";
import UsersByAction from "../components/admin/UsersByAction";
import APIManager from "../scripts/APIManager";
import NotFound from "./NotFound";

export default function AdminPage() {
  const { guildId } = useContext(SiteContext);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const requestData = useCallback(() => {
    APIManager.sendRequest(
      "get_actions",
      {
        guild_id: guildId,
      },
      true
    ).then((data) =>
      data.error
        ? setError(`error: ${data.error}`)
        : setData(data.actions.map((item, index) => ({ ...item, key: index })))
    );
  }, [guildId]);

  useEffect(() => requestData(), [requestData]);

  if (error) return <NotFound error={error} guildId={guildId} />;

  if (!data) return <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <h1 className="softer-text">Admin Dashboard</h1>
      </div>

      <div className="flex flex-col gap-3 items-stretch my-2">
        <div className="flex flex-row flex-wrap gap-3">
          <ActionsByUser data={data} className="grow shrink-0 basis-[512px]" />
          <UsersByAction data={data} className="grow shrink-0 basis-[512px]" />
        </div>
        <ChangedTypes data={data} className="grow shrink-0" />
        <ActionsList data={data} className="grow shrink-0" />
      </div>
    </>
  );
}
