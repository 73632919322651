import { faStar as faStarHollow } from "@fortawesome/free-regular-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Select, Tooltip } from "antd";
import Helper from "../../../scripts/Helper";
import Badge from "../../Badge";

export default function mainColumns(
  data,
  setData,
  activeTab,
  activeTypeTab,
  feedbackTypes,
  channels,
  setSpotlightsToRecover,
  setSpotlightsToClear,
  setSpotlightsToRemove,
  setSpotlightsToAction,
  setActionClassification,
  spotlightActions,
  updateFeedbackType,
  markFeedbackAsStarred,
  markFeedbackAsUnstarred,
  setActiveModal,
  permissions
) {
  return [
    {
      key: "Starred",
      className: "hidden sm:table-cell",
      //width: 16,
      filters: [
        {
          value: false,
          text: (
            <FontAwesomeIcon
              className="cursor-pointer text-gray-medium dark:text-dark-gray-medium"
              icon={faStarHollow}
            />
          ),
        },
        {
          value: true,
          text: (
            <FontAwesomeIcon
              className="cursor-pointer text-gold"
              icon={faStar}
            />
          ),
        },
      ],
      onFilter: () => true,
      render: (item) =>
        item.starred === true ? (
          <FontAwesomeIcon
            className="cursor-pointer text-gold"
            onClick={
              permissions?.["Edit Spotlights"] ||
              permissions?.["Suggest Analytics Edits"]
                ? (e) => {
                    e.stopPropagation();
                    markFeedbackAsUnstarred(item.conversationid, item.headline);
                    setData(
                      data.map((spotlight) =>
                        spotlight.conversationid === item.conversationid
                          ? { ...spotlight, starred: null }
                          : spotlight
                      )
                    );
                  }
                : () => {}
            }
            icon={faStar}
          />
        ) : (
          <FontAwesomeIcon
            className="cursor-pointer text-gray-medium dark:text-dark-gray-medium"
            onClick={
              permissions?.["Edit Spotlights"] ||
              permissions?.["Suggest Analytics Edits"]
                ? (e) => {
                    e.stopPropagation();
                    markFeedbackAsStarred(item.conversationid, item.headline);
                    setData(
                      data.map((spotlight) =>
                        spotlight.conversationid === item.conversationid
                          ? { ...spotlight, starred: true }
                          : spotlight
                      )
                    );
                  }
                : () => {}
            }
            icon={faStarHollow}
          />
        ),
    },
    {
      title: "Type",
      dataIndex: "feedbacktype",
      key: "feedbacktype",
      className: "hidden sm:table-cell",
      //width: 90,
      filters:
        activeTypeTab === "All"
          ? feedbackTypes.map(({ value, label }) => ({
              value,
              text: label,
            }))
          : null,
      onFilter: () => true,
      sorter: true,
      render: (_, item) => (
        <div className="flex flex-col gap-2">
          <Tooltip
            title={
              permissions?.["Edit Spotlights"] ||
              permissions?.["Suggest Analytics Edits"]
                ? null
                : "You do not have permission."
            }
          >
            <div>
              <Select
                className="ant-select-text-base w-full"
                size="medium"
                dropdownMatchSelectWidth={false}
                onChange={(value) => {
                  updateFeedbackType(
                    item.conversationid,
                    item.headline,
                    [value],
                    item.feedbacktype[0]
                  );
                  setData(
                    data.map((spotlight) =>
                      spotlight.conversationid === item.conversationid
                        ? { ...spotlight, feedbacktype: [value] }
                        : spotlight
                    )
                  );
                }}
                value={{
                  value: item.feedbacktype[0],
                  label: item.feedbacktype[0],
                }}
                options={feedbackTypes}
                disabled={
                  !permissions?.["Edit Spotlights"] &&
                  !permissions?.["Suggest Analytics Edits"]
                }
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </div>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Headline",
      dataIndex: "headline",
      key: "headline",
      //width: 120,
      render: (text, item) => (
        <div className="break-words sm:min-w-[70px] max-w-md">
          {item.actioned !== null ? (
            <div className="inline">
              <Badge
                color={
                  item.actioned === "Bug"
                    ? "yellow"
                    : item.actioned === "Feedback"
                    ? "base-gray"
                    : item.actioned === "Help Request"
                    ? "blue"
                    : "blue"
                }
              >
                {item.actioned}
              </Badge>{" "}
            </div>
          ) : null}
          <div className="inline font-medium text-base">{text}</div>
          <div className="inline">
            {" "}
            {item.url ? (
              <Badge link={item.url} target="discord_message">
                Discord
              </Badge>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Time",
      key: "startingtimestamp",
      dataIndex: "startingtimestamp",
      className: "hidden sm:table-cell",
      //width: 90,
      render: (time) =>
        time ? (
          <Tooltip
            title={`${new Date(time).toLocaleTimeString("en-us", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}`}
          >
            {Helper.timeAgo(time)}
          </Tooltip>
        ) : (
          ""
        ),
      sorter: true,
    },
  ];
}
