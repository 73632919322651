import * as am5 from "@amcharts/amcharts5";
import { ChordDirected } from "@amcharts/amcharts5/flow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { Spin } from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { SiteContext } from "../../../Contexts";
import APIManager from "../../../scripts/APIManager";
import Card from "../../Card";

export default function Participation(props) {
  const { darkMode } = useContext(SiteContext);
  const { className, chartClassName, guildId, startDate, endDate } = props;
  const chartDiv = useRef(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const requestData = useCallback(() => {
    setData(null);
    APIManager.sendRequest(
      "get_analytics_interacting_users",
      {
        guild_id: guildId,
        start_date: startDate,
        end_date: endDate,
      },
      true
    ).then((data) =>
      data.error ? setError(`error: ${data.error}`) : setData(data)
    );
  }, [guildId, startDate, endDate]);

  useEffect(() => {
    if (!data) return;

    const root = am5.Root.new(chartDiv?.current);

    let themes = [am5themes_Animated.new(root)];
    if (darkMode) themes.push(am5themes_Dark.new(root));
    root.setThemes(themes);

    const series = root.container.children.push(
      ChordDirected.new(root, {
        sourceIdField: "f",
        targetIdField: "t",
        valueField: "c",
      })
    );
    series.nodes.labels.template.setAll({
      textType: "adjusted",
    });
    series.links.template.setAll({
      tooltipText:
        "[bold]{value}[/] conversation(s) from [bold]{sourceId}[/] to [bold]{targetId}[/]",
    });
    series.data.setAll(data);

    return () => root.dispose();
  }, [data, darkMode]);

  useEffect(() => requestData(), [requestData]);

  return (
    <Card className={className} header={<h1>Participation</h1>}>
      {data ? (
        <div ref={chartDiv} className={`w-full h-96 m-2 ${chartClassName}`}></div>
      ) : error ? (
        <p className="m-2">{error}</p>
      ) : (
        <div
          className={`flex flex-row justify-center items-center w-full h-96 m-2 ${chartClassName}`}
        >
          <Spin size="large" />
        </div>
      )}
    </Card>
  );
}
