import { ConfigProvider, theme } from "antd";
import LandingFooter from "../../components/landing/LandingFooter";
import LandingHeader from "../../components/landing/LandingHeader";
import constants from "../../constants";
import APIManager from "../../scripts/APIManager";

export default function TermsOfService() {
  return (
    <div className="relative no-zoom">
      <div className="-z-10 absolute inset-0 bg-white" />
      <div className="-z-10 absolute w-full flex flex-row justify-center top-[50px]">
        <div className="landing-bg h-[400px] w-[400px]" />
      </div>

      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: constants.colors["secondary"],
          },
        }}
      >
        <div className="min-h-screen flex flex-col">
          <LandingHeader
            simple
            landing
            className="fixed box-border h-12 sm:h-16 w-full"
          />

          <div className="h-12 sm:h-16" />
          <div className="flex flex-col items-center gap-8 -mb-12 py-12 bg-[url('/src/images/landing/starbackground.png')] bg-cover">
            <p className="font-landing-header text-2xl text-black font-semibold">
              Terms of Service
            </p>
            <object
              className="h-[1000px]"
              data={APIManager.getEndpointURL("static/ML56_SaaS_Agreement.pdf")}
              type="application/pdf"
              width="100%"
              height="100%"
            >
              <p>
                <a
                  href={APIManager.getEndpointURL(
                    "static/ML56_SaaS_Agreement.pdf"
                  )}
                >
                  Link to Terms of Service
                </a>
              </p>
            </object>
          </div>

          <LandingFooter />
        </div>
      </ConfigProvider>
    </div>
  );
}
