import { faCircle as faCircleRegular } from "@fortawesome/free-regular-svg-icons";
import {
  faCircle as faCircleSolid,
  faSquareUpRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";

export default function Badge(props) {
  const { className, children, active, inactive, link, tooltip, target } =
    props;
  const color = props.color || "gray";
  let bodyColor, textColor, borderColor;

  switch (color) {
    default:
    case "gray":
      bodyColor = "bg-gray-light dark:bg-dark-gray-light";
      textColor = "text-gray-dark dark:text-dark-gray-dark";
      borderColor = "border-gray-dark/25 dark:border-dark-gray-dark/25";
      break;
    case "base-gray":
      bodyColor = "bg-black/5";
      textColor = "text-black/75 dark:text-white/75";
      borderColor = "border-black/50 dark:border-white/50";
      break;
    case "green":
      bodyColor = "bg-green-light dark:bg-dark-green-light";
      textColor = "text-green-dark dark:text-dark-green-dark";
      borderColor = "border-green-dark/25 dark:border-dark-green-dark/25";
      break;
    case "red":
      bodyColor = "bg-red-light dark:bg-dark-red-light";
      textColor = "text-red-dark dark:text-dark-red-dark";
      borderColor = "border-red-dark/25 dark:border-dark-red-dark/25";
      break;
    case "blue":
      bodyColor = "bg-blue-light dark:bg-dark-blue-mild";
      textColor = "text-blue-dark dark:text-blue";
      borderColor = "border-blue-dark/25 dark:border-blue";
      break;
    case "yellow":
      bodyColor = "bg-secondary-light/10";
      textColor = "text-secondary-dark dark:text-secondary";
      borderColor = "border-secondary dark:border-secondary-dark";
      break;
  }

  const content = (
    <Tooltip
      title={
        tooltip ? (typeof tooltip === "boolean" ? children : tooltip) : null
      }
    >
      <div
        className={`flex flex-row items-center gap-2 ${bodyColor} px-2 py-1 rounded-md border border-solid ${borderColor} ${className}`}
      >
        {active && (
          <FontAwesomeIcon
            className={`${textColor}`}
            icon={faCircleSolid}
            size="2xs"
          />
        )}
        {inactive && (
          <FontAwesomeIcon
            className={`${textColor}`}
            icon={faCircleRegular}
            size="2xs"
          />
        )}
        <p
          className={`font-medium text-xs overflow-hidden whitespace-nowrap text-ellipsis color-inherit ${textColor} m-0`}
        >
          {children}
        </p>
        {link && (
          <FontAwesomeIcon
            className={`${textColor}`}
            icon={faSquareUpRight}
            size="xs"
          />
        )}
      </div>
    </Tooltip>
  );

  return link ? (
    <Link
      className="inline-block"
      target={target ? target : "_blank"}
      to={link}
    >
      {content}
    </Link>
  ) : (
    <div className="inline-block">{content}</div>
  );
}
