import { Link } from "react-router-dom";

export default function Eye({
  width,
  height,
  className,
  color,
  strokeWidth,
  link,
}) {
  const image = (
    <svg
      className={`${className}`}
      width={width || "46"}
      height={height || "37"}
      viewBox="0 0 46 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.21497 21.8992C5.48526 24.2513 5.9954 25.2872 8.23843 27.7288C10.353 30.0302 11.4122 31.1829 13.1022 32.3083C14.4184 33.1845 17.0859 34.9174 20.8866 35.1549C24.4069 35.3769 27.055 34.2086 28.2855 33.6479C31.7747 32.0591 34.0683 29.5512 35.4741 28.0092C35.6338 27.8339 35.5131 27.9624 38.5739 24.485C40.0731 22.7832 40.5248 22.2692 41.1829 21.5955C42.2032 20.5441 43.13 19.699 43.8543 19.0721C44.1425 18.8229 44.1152 18.3634 43.7998 18.1531C42.0007 16.9537 40.7741 15.7465 39.9602 14.8197C38.862 13.5697 38.7919 13.1569 37.098 11.0229C35.9765 9.6132 35.0068 8.39822 33.5738 7.07811C32.46 6.05394 30.9881 4.71825 28.71 3.62399C27.4989 3.04377 25.4272 2.07412 22.5806 2.00403C19.734 1.93393 17.6194 2.79454 16.3071 3.34362C13.9901 4.31715 12.4753 5.53992 11.3732 6.44336C9.96354 7.59992 8.99 8.77985 7.42845 10.6023C6.18232 12.0587 6.04602 12.3547 4.88946 13.5969C3.7952 14.7729 2.78662 15.7153 2 16.4513M22.6545 11.6148C18.0049 11.607 15.1505 14.7067 15.1388 18.5931C15.1232 23.0792 18.9161 24.6446 19.3406 24.9289C21.2955 26.2334 24.843 26.7397 27.2379 26.5333C28.1024 26.4593 31.759 26.1555 36.1166 24.1384C38.3986 23.0831 40.4547 21.7045 42.2226 20.5752M20.5633 25.5714C24.839 25.5714 28.8383 22.7988 28.8227 18.7683C28.8033 13.8851 24.913 12.1288 24.5314 11.938C22.7479 11.054 19.6442 10.2557 16.1668 10.762C15.3062 10.8866 12.2025 11.2098 7.84496 13.227C5.56299 14.2823 3.7561 15.0261 2.42041 16.0658M28.5697 18.597C28.5697 22.223 25.6302 25.1625 22.0042 25.1625C18.3781 25.1625 15.4386 22.223 15.4386 18.597C15.4386 14.971 18.3781 12.0315 22.0042 12.0315C25.6302 12.0315 28.5697 14.971 28.5697 18.597Z"
        stroke={color || "#0C163F"}
        strokeWidth={strokeWidth || "3.5"}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );

  return link ? (
    <Link to={link === true ? "/home" : link} className="flex">{image}</Link>
  ) : (
    image
  );
}
