import {
  faCircleInfo,
  faFileArrowUp,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, Table, Tooltip, Upload } from "antd";
import { useState } from "react";
import Badge from "../Badge";
import Card from "../Card";

export default function ItemsCard(props) {
  const { setRule, className, type, items, title, disabled, description } =
    props;
  const [itemValue, setItemValue] = useState(null);
  const [itemSeverity, setItemSeverity] = useState(null);

  return (
    <Card
      className={className}
      header={
        <div
          className={`flex flex-row justify-between items-center w-full ${
            disabled ? "opacity-30" : ""
          }`}
        >
          <div className="flex flex-row items-center gap-3">
            <h1>{title}</h1>
            {description ? (
              <Tooltip title={description}>
                <FontAwesomeIcon icon={faCircleInfo} />
              </Tooltip>
            ) : null}
            <Badge>
              {items.length} Item
              {items.length !== 1 ? "s" : ""}
            </Badge>
          </div>
          <Tooltip
            title={
              <span>
                Items will be added to the existing list. Duplicates will not be
                added.
                <br />
                <br />
                Must be a .txt file with items separated by line breaks.
              </span>
            }
          >
            <Upload
              accept=".txt"
              showUploadList={false}
              beforeUpload={(file) => {
                const reader = new FileReader();

                reader.onload = (e) => {
                  const uploadedItems = e.target.result
                    .split("\n")
                    .map((item) => item.replace("\r", ""))
                    .filter((item) => item.length > 0);
                  const newItems = [...items, ...uploadedItems];
                  setRule((rule) => ({
                    ...rule,
                    [type]: newItems.filter(
                      (item, pos) => newItems.indexOf(item) === pos
                    ),
                  }));
                };
                reader.readAsText(file);

                return false;
              }}
            >
              <Button
                icon={<FontAwesomeIcon className="pr-2" icon={faFileArrowUp} />}
                className="font-sans -my-3"
              >
                Upload Items
              </Button>
            </Upload>
          </Tooltip>
        </div>
      }
    >
      <div className={`-m-2 mb-0 ${disabled ? "opacity-30" : ""}`}>
        <Table
          size="small"
          showHeader={false}
          dataSource={items.map((item, index) => ({
            test: index,
            item: item,
            key: index,
          }))}
          columns={[
            {
              render: (_, __, itemIndex) => (
                <Button
                  className="-my-0.5 mx-3"
                  disabled={disabled}
                  icon={
                    <FontAwesomeIcon icon={faMinus} size="lg" className="w-8" />
                  }
                  onClick={() =>
                    setRule((rule) => ({
                      ...rule,
                      [type]: [
                        ...items.slice(0, itemIndex),
                        ...items.slice(itemIndex + 1),
                      ],
                    }))
                  }
                />
              ),
              fixed: "left",
              width: 60,
            },
            {
              title: "item",
              key: "item",
              dataIndex: "item",
              render: (item) => <div className="text-base">{item}</div>,
            },
          ]}
          pagination={{ defaultPageSize: 500, hideOnSinglePage: true }}
          footer={() => (
            <div className="flex flex-row items-center">
              <Button
                disabled={
                  disabled ||
                  itemValue === null ||
                  itemValue === "" ||
                  (type === "words" && itemSeverity === null)
                }
                className="mx-3"
                icon={
                  <FontAwesomeIcon icon={faPlus} size="lg" className="w-8" />
                }
                onClick={() =>
                  setRule((rule) => {
                    let currentItemValue = itemValue,
                      currentItemSeverity = itemSeverity;
                    setItemValue(null);
                    setItemSeverity(null);
                    return {
                      ...rule,
                      [type]: [
                        ...rule[type],
                        type === "words"
                          ? {
                              value: currentItemValue,
                              severity: currentItemSeverity,
                            }
                          : currentItemValue,
                      ],
                    };
                  })
                }
              />
              <Input
                className="mr-3"
                disabled={disabled}
                size="large"
                placeholder="Your item"
                onChange={({ target }) => setItemValue(target.value)}
                value={itemValue}
              />
            </div>
          )}
          scroll={{ y: 300 }}
        />
      </div>
    </Card>
  );
}
