import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Spin } from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { SiteContext } from "../../../Contexts";
import APIManager from "../../../scripts/APIManager";
import Card from "../../Card";

export default function ReportsByRule(props) {
  const { darkMode } = useContext(SiteContext);
  const { className, chartClassName, guildId } = props;
  const chartDiv = useRef(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [COLORS] = useState([
    am5.color(0xff5733),
    am5.color(0x3498db),
    am5.color(0x2ecc71),
    am5.color(0xf1c40f),
    am5.color(0x8e44ad),
    am5.color(0xe74c3c),
    am5.color(0x1abc9c),
    am5.color(0xecf0f1),
    am5.color(0xd35400),
    am5.color(0x27ae60),
    am5.color(0x9b59b6),
    am5.color(0xe67e22),
    am5.color(0x3498db),
    am5.color(0x2c3e50),
    am5.color(0xf39c12),
    am5.color(0xc0392b),
  ]);

  const requestData = useCallback(() => {
    APIManager.sendRequest(
      "get_analytics_reports_by_rule",
      {
        guild_id: guildId,
      },
      true
    ).then((data) =>
      data.error ? setError(`error: ${data.error}`) : setData(data)
    );
  }, [guildId]);

  useEffect(() => {
    if (!data) return;

    const root = am5.Root.new(chartDiv.current);

    let themes = [am5themes_Animated.new(root)];
    if (darkMode) themes.push(am5themes_Dark.new(root));
    root.setThemes(themes);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    const cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, { behavior: "none" })
    );
    cursor.lineY.set("visible", false);

    // Axes
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        maxPrecision: 0,
        min: 0,
      })
    );
    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: "Reports",
        y: am5.p50,
        centerX: am5.p50,
      })
    );

    // Series
    const createSeries = (name, data, color) => {
      const settings = {
        name,
        valueXField: "ts",
        valueYField: "v",
        xAxis: xAxis,
        yAxis: yAxis,
        legendLabelText: "{name}[/][bold #888]{categoryX}[/]",
        legendRangeLabelText: "{name}[/]",
        legendValueText: "{valueY}",
        legendRangeValueText: "[bold #888]-[/]",
        minBulletDistance: 4,
      };

      let series = am5xy.SmoothedXLineSeries.new(root, settings);
      series.set("stroke", color);
      series.bullets.push(() =>
        am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 3,
            fill: color,
          }),
        })
      );
      series.data.setAll(data);

      chart.series.push(series);
    };

    // Populate
    data.forEach(({ rule_name, data }, index) =>
      createSeries(rule_name, data, COLORS[index % COLORS.length])
    );

    // Legend
    const legend = chart.children.unshift(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        marginTop: -20,
        marginBottom: 10,
      })
    );
    legend.data.setAll(chart.series.values);

    return () => root.dispose();
  }, [COLORS, darkMode, data]);

  useEffect(() => requestData(), [requestData]);

  return (
    <Card className={className}>
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row items-center gap-3">
          <h1>Reports by Rule</h1>
        </div>
      </div>
      {data ? (
        <div ref={chartDiv} className={`w-full h-96 ${chartClassName}`}></div>
      ) : error ? (
        { error }
      ) : (
        <div
          className={`flex flex-row justify-center items-center w-full h-96 ${chartClassName}`}
        >
          <Spin size="large" />
        </div>
      )}
    </Card>
  );
}
