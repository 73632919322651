import { InputNumber, Switch } from "antd";
import Card from "../Card";

export default function SpamCard(props) {
  const { className, rule, setRule } = props;

  // const occuranceOptions = [
  //   { label: "Never", value: 0 },
  //   { label: "2 occurances", value: 2 },
  //   { label: "3 occurances", value: 3 },
  //   { label: "4 occurances", value: 4 },
  //   { label: "5 occurances", value: 5 },
  //   { label: "10 occurances", value: 10 },
  // ];

  return (
    <Card className={className} header={<h1>Spam Rules</h1>}>
      <div className="flex flex-col gap-3 m-2">
        <div className="flex flex-col sm:flex-row gap-x-3 gap-y-1">
          <div className="flex flex-row items-center gap-3">
            <p className="whitespace-nowrap">Maximum of</p>
            <InputNumber
              size="middle"
              placeholder="#"
              onChange={(value) =>
                setRule((rule) => ({
                  ...rule,
                  spam_rule_setting: {
                    ...rule.spam_rule_setting,
                    messages: value,
                  },
                }))
              }
              value={rule.spam_rule_setting.messages}
            />
            <p className="hidden sm:inline whitespace-nowrap">
              messages within
            </p>
            <p className="inline sm:hidden">messages</p>
          </div>
          <div className="flex flex-row items-center gap-3">
            <p className="inline sm:hidden">within</p>
            <InputNumber
              size="middle"
              placeholder="#"
              onChange={(value) =>
                setRule((rule) => ({
                  ...rule,
                  spam_rule_setting: {
                    ...rule.spam_rule_setting,
                    seconds: value,
                  },
                }))
              }
              value={rule.spam_rule_setting.seconds}
            />
            <p className="whitespace-nowrap">seconds</p>
          </div>
        </div>

        {/* <div className="flex flex-row items-center gap-3">
        <p className="whitespace-nowrap">Duplicate messages pattern after</p>
        <Select
          key={3}
          size="large"
          placeholder="Occurances"
          onChange={(value) =>
            setRule((rule) => ({
              ...rule,
              spam_rule_setting: { ...rule.spam_rule_setting, duplicate: value },
            }))
          }
          value={rule.spam_rule_setting.duplicate}
          options={occuranceOptions}
        />
      </div> */}
        <div className="flex flex-row gap-3">
          <p className="whitespace-nowrap">Allow ALL CAPS messages</p>
          <Switch
            checked={rule.spam_rule_setting.all_caps}
            onChange={(value) =>
              setRule((rule) => ({
                ...rule,
                spam_rule_setting: {
                  ...rule.spam_rule_setting,
                  all_caps: value,
                },
              }))
            }
          />
        </div>
      </div>
    </Card>
  );
}
