import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Select, Switch, Tooltip } from "antd";
import { parse as bigParse } from "json-bigint";
import { useCallback, useContext, useEffect, useState } from "react";
import { AccountContext, SiteContext } from "../../Contexts";
import APIManager from "../../scripts/APIManager";
import AuthManager from "../../scripts/AuthManager";
import Badge from "../Badge";
import Card from "../Card";
import Modal from "../Modal";

export default function ChannelsCard(props) {
  const { activeModal, setActiveModal } = useContext(SiteContext);
  const { signedIn } = useContext(AccountContext);
  const { className, rule, setRule, guildId, disabled, isUniversal } = props;
  const [options, setOptions] = useState(null);
  const [error, setError] = useState(null);

  const requestChannels = useCallback(() => {
    APIManager.sendRequest(
      "get_moderation_channels",
      {
        token: AuthManager.accessToken,
        guild_id: guildId,
      },
      true
    ).then((data) =>
      data.error
        ? setError(`error: ${data.error}`)
        : setOptions(
            Object.entries(data.channels).map(([key, value]) => ({
              label: value,
              value: key,
            }))
          )
    );
  }, [guildId]);

  useEffect(() => {
    if (signedIn) requestChannels();
  }, [signedIn, requestChannels]);

  if (error) return <h1>{error}</h1>;

  if (!options) return <Card className={className} loading />;

  return (
    <Card
      className={className}
      header={
        <div className="flex flex-wrap justify-between items-center gap-3 w-full">
          <div
            className={`flex flex-row items-center gap-3 ${
              disabled ? "opacity-30" : ""
            }`}
          >
            <h1>Channels</h1>
            <Tooltip title="Configure what channels are being monitored.">
              <FontAwesomeIcon icon={faCircleInfo} />
            </Tooltip>
            {rule.content_type !== "username" ? (
              <>
                <Badge>
                  {rule.channel_setting.channels.length}{" "}
                  {rule.channel_setting.apply_to_all ? "Exempt" : null} Channel
                  {rule.channel_setting.channels.length !== 1 ? "s" : ""}
                </Badge>
              </>
            ) : null}
          </div>
          {!isUniversal ? (
            <div>
              <Checkbox
                checked={rule.channel_setting.is_universal}
                onChange={({ target }) =>
                  target.checked
                    ? setActiveModal("set_universal:channels:on")
                    : setRule((rule) => ({
                        ...rule,
                        channel_setting: {
                          ...rule.channel_setting,
                          is_universal: false,
                        },
                      }))
                }
              >
                Use Universal
              </Checkbox>
            </div>
          ) : null}
        </div>
      }
    >
      <div className="flex flex-col gap-3 m-2">
        {rule.content_type !== "username" ? (
          <>
            <div
              className={`flex flex-row gap-3 ${disabled ? "opacity-30" : ""}`}
            >
              <p className="whitespace-nowrap">Apply to all channels</p>
              <Switch
                disabled={disabled}
                checked={rule.channel_setting.apply_to_all}
                onChange={(value) =>
                  setRule({
                    ...rule,
                    channel_setting: {
                      ...rule.channel_setting,
                      apply_to_all: value,
                    },
                  })
                }
              />
            </div>
            <div
              className={`flex flex-col gap-3 ${disabled ? "opacity-30" : ""}`}
            >
              <p className="whitespace-nowrap">
                {rule.channel_setting.apply_to_all ? "Exemptions" : "Channels"}:
              </p>
              <Select
                disabled={disabled}
                key={0}
                mode="multiple"
                size="large"
                placeholder={
                  rule.channel_setting.apply_to_all
                    ? "Exempt channels"
                    : "Select channels"
                }
                onChange={(value) =>
                  setRule((rule) => ({
                    ...rule,
                    channel_setting: {
                      ...rule.channel_setting,
                      channels: value.map((channel) => bigParse(channel)),
                    },
                  }))
                }
                value={rule.channel_setting.channels.map((channel) =>
                  channel.toString()
                )}
                options={options}
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
              />
            </div>
          </>
        ) : null}
      </div>

      <Modal
        isActive={activeModal === "set_universal:channels:on"}
        onClose={() => setActiveModal(null)}
        className="flex flex-col gap-10 max-w-sm p-10"
      >
        <div className="flex flex-col gap-3">
          <h1>
            Using the Universal Rule will clear your existing configuration for
            channels
          </h1>
        </div>
        <div className="flex flex-row-reverse gap-3">
          <Button
            type="primary"
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              setRule((rule) => ({
                ...rule,
                channel_setting: {
                  ...rule.channel_setting,
                  is_universal: true,
                },
              }));
            }}
          >
            Confirm
          </Button>
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => setActiveModal(null)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Card>
  );
}
