import { ConfigProvider, theme } from "antd";
import LandingFooter from "../../components/landing/LandingFooter";
import LandingHeader from "../../components/landing/LandingHeader";
import constants from "../../constants";

export default function PrivacyPolicy() {
  return (
    <div className="relative no-zoom">
      <div className="-z-10 absolute inset-0 bg-white" />
      <div className="-z-10 absolute w-full flex flex-row justify-center top-[50px]">
        <div className="landing-bg h-[400px] w-[400px]" />
      </div>

      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: constants.colors["secondary"],
          },
        }}
      >
        <div className="min-h-screen flex flex-col">
          <LandingHeader
            simple
            landing
            className="fixed box-border h-12 sm:h-16 w-full"
          />

          <div className="h-12 sm:h-16" />
          <div className="flex flex-col items-center gap-8 -mb-12 py-12 bg-[url('/src/images/landing/starbackground.png')] bg-cover">
            <p className="font-landing-header text-2xl text-black font-semibold">
              Privacy Policy
            </p>
            <p className="font-landing text-black px-8">
              "Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
              est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
              velit, sed quia non is autem vel eum iure reprehenderit qui in ea
              voluptate velit esse quam nihil molestiae consequatur, vel illum
              qui dolorem eum fugiat quo voluptas nulla pariatur?
            </p>
          </div>

          <LandingFooter />
        </div>
      </ConfigProvider>
    </div>
  );
}
