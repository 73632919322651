import {
  faArrowRight,
  faBoltLightning,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker, Skeleton, Tooltip } from "antd";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { SiteContext } from "../../Contexts";
import Card from "../../components/Card";
import Eye from "../../components/Eye";
import ReportBreakdown from "../../components/analytics/summary_dashboard/ReportBreakdown";
import constants from "../../constants";
import APIManager from "../../scripts/APIManager";

export default function SummaryDashboard() {
  const { guildId } = useContext(SiteContext);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [insights, setInsights] = useState(null);

  const requestInsights = useCallback(() => {
    APIManager.sendRequest(
      "get_summary_insights",
      {
        guild_id: guildId,
        start_date: startDate ? startDate.toISOString() : null,
        end_date: endDate ? endDate.toISOString() : null,
      },
      true
    ).then((data) =>
      data.error ? setError(`error: ${data.error}`) : setInsights(data.insights)
    );
  }, [guildId, startDate, endDate]);

  useEffect(() => {
    let today = new Date();
    let oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    setStartDate(oneWeekAgo);
    setEndDate(today);
  }, []);

  useEffect(() => {
    if (startDate && endDate) requestInsights();
  }, [startDate, endDate, requestInsights]);

  if (error) return <h1>{error}</h1>;

  if (!insights) return <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <h1 className="softer-text">Summary Dashboard</h1>
        <div className="flex flex-row items-center gap-3">
          <p>
            {Math.ceil(Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24))}{" "}
            Day
            {Math.ceil(
              Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24)
            ) === 1
              ? ""
              : "s"}
          </p>
          <DatePicker.RangePicker
            size="large"
            onChange={(_, [start, end]) => {
              setStartDate(start ? new Date(start) : null);
              setEndDate(end ? new Date(end) : null);
            }}
            defaultValue={[dayjs(startDate), dayjs(endDate)]}
            separator={
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-dark-gray-base"
              />
            }
            allowClear={false}
          />
        </div>
      </div>

      <div className="flex flex-col gap-3 items-stretch m-2">
        <div className="flex flex-col sm:flex-row items-stretch justify-stretch gap-3">
          <div className="grow flex flex-col items-stretch justify-stretch gap-3">
            <Card className="h-fit p-4">
              <div className="flex flex-row items-center gap-6">
                <Eye
                  className="object-scale-down h-[35px]"
                  color={constants.colors.secondary}
                  strokeWidth="3"
                />
                <div className="flex flex-col">
                  <p className="font-semibold text-green-dark dark:text-green-medium text-5xl">
                    {insights.message_count === 0
                      ? "N/A"
                      : `${
                          Math.round(
                            (1 -
                              insights.report_count / insights.message_count) *
                              10000
                          ) / 100
                        }
                  %`}
                  </p>
                  <p>reduction in time spent reviewing messages</p>
                </div>
              </div>
            </Card>
            <div className="grow flex flex-row items-stretch justify-stretch gap-3">
              <div className="basis-1/2 grow flex flex-col items-stretch justify-stretch gap-3">
                <Card
                  className="basis-1/2 grow flex flex-col justify-between gap-0"
                  header="Avg. Message Read Time"
                >
                  <p className="text-3xl font-semibold whitespace-nowrap p-2">
                    0.1 min
                    <Tooltip title="This value is an estimate">
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        size="xs"
                        className="w-4 px-2"
                      />
                    </Tooltip>
                  </p>
                </Card>
                <Card
                  className="basis-1/2 grow flex flex-col justify-between gap-0"
                  header="Spotlights"
                >
                  <p className="text-3xl font-semibold whitespace-nowrap p-2">
                    {insights.report_count.toLocaleString()}
                  </p>
                </Card>
              </div>
              <div className="basis-1/2 grow flex flex-col items-stretch justify-stretch gap-3">
                <Card
                  className="basis-1/2 grow flex flex-col justify-between gap-0"
                  header="Total Messages"
                >
                  <p className="text-3xl font-semibold whitespace-nowrap p-2">
                    {insights.message_count.toLocaleString()}
                  </p>
                </Card>
                <Card
                  className="basis-1/2 grow flex flex-col justify-between gap-0"
                  header="Actions Taken"
                >
                  <p className="text-3xl font-semibold whitespace-nowrap p-2">
                    {insights.actioned_report_count.toLocaleString()}
                  </p>
                </Card>
              </div>
            </div>
          </div>
          <div className="grow flex flex-col items-stretch justify-stretch gap-3">
            <div className="grow flex flex-col sm:flex-row items-stretch justify-stretch gap-3">
              <Card className="basis-1/2" header={<h1>Reading Time</h1>}>
                <div className="flex flex-row justify-center p-2">
                  <Tooltip
                    title={`Reading time is down by ${
                      Math.round(
                        (1 - insights.report_count / insights.message_count) *
                          10000
                      ) / 100
                    }%`}
                  >
                    <div className="relative w-[160px] h-[160px]">
                      <svg className="absolute w-[160px] h-[160px] rotate-[270deg]">
                        <circle
                          className="stroke-[20px] stroke-gray-medium-light dark:stroke-dark-gray-medium-light fill-none"
                          cx="80"
                          cy="80"
                          r="70"
                        ></circle>
                      </svg>
                      <svg className="absolute w-[160px] h-[160px] rotate-[270deg]">
                        <circle
                          style={{
                            strokeDasharray: 450,
                            strokeDashoffset:
                              insights.message_count !== 0
                                ? (1 -
                                    (insights.report_count * 0.1) /
                                      (insights.message_count * 0.1)) *
                                  450
                                : 0,
                            strokeLinecap: "round",
                          }}
                          className="stroke-[20px] stroke-secondary fill-none"
                          cx="80"
                          cy="80"
                          r="70"
                        ></circle>
                      </svg>
                    </div>
                  </Tooltip>
                </div>
                <div className="flex flex-row flex-wrap justify-between gap-3 px-2 pb-2">
                  <div className="flex flex-col">
                    <p className="whitespace-nowrap">Before SpecifEye</p>
                    <h1 className="text-base whitespace-nowrap">
                      {Math.round(
                        insights.message_count * 0.1
                      ).toLocaleString()}{" "}
                      min (
                      {(
                        Math.round(
                          ((insights.message_count * 100) / 60) * 0.1
                        ) / 100
                      ).toLocaleString()}{" "}
                      hrs)
                    </h1>
                  </div>
                  <div className="flex flex-col">
                    <p className="whitespace-nowrap">After SpecifEye</p>
                    <h1 className="text-base whitespace-nowrap">
                      {Math.round(insights.report_count * 0.1).toLocaleString()}{" "}
                      min
                    </h1>
                  </div>
                </div>
              </Card>
              <Card
                className="basis-1/2 justify-between"
                header={<h1>Messages Read per Actionable Insight</h1>}
              >
                <div className="grow flex flex-row justify-between gap-3 h-52 p-2">
                  <div className="flex flex-col">
                    <Tooltip
                      placement="right"
                      title={
                        <span>
                          {insights.message_count.toLocaleString()} messages
                          <br />
                          {insights.actioned_report_count.toLocaleString()}{" "}
                          actioned
                        </span>
                      }
                    >
                      <div className="flex flex-col justify-end w-8 h-full p-3">
                        <div
                          className="bg-secondary rounded"
                          style={{
                            height: "100%",
                          }}
                        />
                      </div>
                    </Tooltip>
                    <p className="whitespace-nowrap">Before SpecifEye</p>
                    <h1 className="text-base whitespace-nowrap">
                      {insights.actioned_report_count === 0
                        ? "N/A"
                        : (
                            Math.round(
                              (insights.message_count * 100) /
                                insights.actioned_report_count
                            ) / 100
                          ).toLocaleString()}
                    </h1>
                  </div>
                  <div className="flex flex-col">
                    <Tooltip
                      placement="left"
                      title={
                        <span>
                          {insights.report_count.toLocaleString()} spotlights
                          <br />
                          {insights.actioned_report_count.toLocaleString()}{" "}
                          actioned
                        </span>
                      }
                    >
                      <div className="self-end flex flex-col justify-end w-8 h-full p-3">
                        <div
                          className="bg-secondary rounded"
                          style={{
                            height: `${
                              Math.round(
                                (insights.report_count /
                                  insights.message_count) *
                                  10000
                              ) / 100
                            }%`,
                          }}
                        />
                      </div>
                    </Tooltip>
                    <p className="whitespace-nowrap">After SpecifEye</p>
                    <h1 className="text-base whitespace-nowrap">
                      {insights.actioned_report_count === 0
                        ? "N/A"
                        : (
                            Math.round(
                              (insights.report_count * 100) /
                                insights.actioned_report_count
                            ) / 100
                          ).toLocaleString()}
                    </h1>
                  </div>
                </div>
              </Card>
            </div>
            <Card className="flex flex-col gap-0" header="Actions Speed">
              <div className="flex flex-row items-center justify-center gap-3 pt-2">
                <FontAwesomeIcon
                  icon={faBoltLightning}
                  className="text-green-dark dark:text-green-medium"
                  size="2x"
                />
                <p className="font-semibold text-green-dark dark:text-green-medium text-5xl">
                  {insights.report_count === 0
                    ? "N/A"
                    : `${Math.round(
                        insights.message_count / insights.report_count
                      )}x faster`}
                </p>
              </div>
            </Card>
          </div>
        </div>
        <ReportBreakdown startDate={startDate} endDate={endDate} />
      </div>
    </>
  );
}
