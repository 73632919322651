import {
  faCrown,
  faListCheck,
  faMinus,
  faPlus,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Dropdown,
  Input,
  Select,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { AccountContext, SiteContext, ThemeContext } from "../Contexts";
import defaultPermissions from "../defaultPermissions";
import APIManager from "../scripts/APIManager";
import Modal from "./Modal";

export default function AccessModal(props) {
  const { signedIn } = useContext(AccountContext);
  const { activeModal, setActiveModal, openNotification } =
    useContext(SiteContext);
  const { darkMode } = useContext(ThemeContext);
  const { title, guildId } = props;
  const [userWarning, setUserWarning] = useState(null);
  const [roleWarning, setRoleWarning] = useState(null);
  const [newUser, setNewUser] = useState(null);
  const [newRole, setNewRole] = useState(null);
  const [newUserLoading, setNewUserLoading] = useState(false);
  const [newRoleLoading, setNewRoleLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [roles, setRoles] = useState(null);
  const [invites, setInvites] = useState(null);
  const [owner, setOwner] = useState(null);
  const [error, setError] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);
  const [userRole, setUserRole] = useState(false);
  const [rolePermissions, setRolePermissions] = useState(null);
  const [newUserPermissions, setNewUserPermissions] =
    useState(defaultPermissions);
  const [newUserRole, setNewUserRole] = useState(false);
  const [newRolePermissions, setNewRolePermissions] =
    useState(defaultPermissions);
  const [userInitialPermissions, setUserInitialPermissions] = useState(null);
  const [userInitialRole, setUserInitialRole] = useState(false);
  const [roleInitialPermissions, setRoleInitialPermissions] = useState(null);

  const requestAccessRoles = useCallback(() => {
    setRoles(null);
    APIManager.sendRequest(
      "get_access_roles",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else if (data.success) {
        setRoles(data.roles);
      }
    });
  }, [guildId]);

  const addAccessRole = useCallback(() => {
    setNewRoleLoading(true);
    APIManager.sendRequest(
      "add_access_role",
      {
        guild_id: guildId,
        role: newRole,
        permissions: newRolePermissions,
      },
      true
    ).then((data) => {
      if (data.success) {
        requestAccessRoles();
        setNewRole(null);
        setNewRolePermissions(defaultPermissions);
      } else setRoleWarning(data.message);
      setNewRoleLoading(false);
    });
  }, [guildId, newRole, newRolePermissions, requestAccessRoles]);

  const removeAccessRole = useCallback(
    (roleName) => {
      APIManager.sendRequest(
        "remove_access_role",
        {
          guild_id: guildId,
          role_name: roleName,
        },
        true
      ).then((data) => {
        if (data.success) requestAccessRoles();
      });
    },
    [guildId, requestAccessRoles]
  );

  const requestRolePermissions = useCallback(
    (roleName) => {
      APIManager.sendRequest(
        "get_role_permissions",
        {
          guild_id: guildId,
          role_name: roleName,
        },
        true
      ).then((data) => {
        if (data.success) {
          setRoleInitialPermissions(data.permissions);
        }
      });
    },
    [guildId]
  );

  const modifyRolePermissions = useCallback(
    (roleName) => {
      let initialPermissions = roleInitialPermissions;
      setRoleInitialPermissions(null);
      APIManager.sendRequest(
        "set_role_permissions",
        {
          guild_id: guildId,
          role_name: roleName,
          permissions: rolePermissions,
        },
        true
      ).then((data) => {
        if (data.success) requestRolePermissions(roleName);
        else {
          openNotification(
            "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message || data.error
          );
          setRoleInitialPermissions(initialPermissions);
        }
      });
    },
    [
      guildId,
      rolePermissions,
      openNotification,
      roleInitialPermissions,
      requestRolePermissions,
    ]
  );

  const requestAccessUsers = useCallback(() => {
    setUsers(null);
    setInvites(null);
    setOwner(null);
    APIManager.sendRequest(
      "get_access_users",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else if (data.success) {
        setUsers(data.users);
        setInvites(data.invites);
        setOwner(data.owner);
      }
    });
  }, [guildId]);

  const addAccessUser = useCallback(() => {
    setNewUserLoading(true);
    APIManager.sendRequest(
      "add_access_user",
      {
        guild_id: guildId,
        user: newUser,
        permissions: newUserPermissions,
        role: newUserRole,
      },
      true
    ).then((data) => {
      if (data.success) {
        requestAccessUsers();
        setNewUser(null);
        setNewUserPermissions(defaultPermissions);
      } else setUserWarning(data.message);
      setNewUserLoading(false);
    });
  }, [guildId, newUser, newUserPermissions, newUserRole, requestAccessUsers]);

  const removeAccessUser = useCallback(
    (userId) => {
      APIManager.sendRequest(
        "remove_access_user",
        {
          guild_id: guildId,
          user_id: userId,
        },
        true
      ).then((data) => {
        if (data.success) requestAccessUsers();
      });
    },
    [guildId, requestAccessUsers]
  );

  const requestUserPermissions = useCallback(
    (userId) => {
      APIManager.sendRequest(
        "get_user_permissions",
        {
          guild_id: guildId,
          user_id: userId,
        },
        true
      ).then((data) => {
        if (data.success) {
          setUserInitialPermissions(data.permissions);
          setUserInitialRole(data.role || false);
        }
      });
    },
    [guildId]
  );

  const modifyUserPermissions = useCallback(
    (userId) => {
      let initialPermissions = userInitialPermissions;
      setUserInitialPermissions(null);
      APIManager.sendRequest(
        "set_user_permissions",
        {
          guild_id: guildId,
          user_id: userId,
          permissions: userPermissions,
          role: userRole || null,
        },
        true
      ).then((data) => {
        if (data.success) requestUserPermissions(userId);
        else {
          openNotification(
            "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message || data.error
          );
          setUserInitialPermissions(initialPermissions);
        }
      });
    },
    [
      guildId,
      userPermissions,
      userRole,
      openNotification,
      userInitialPermissions,
      requestUserPermissions,
    ]
  );

  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (userInitialPermissions === null) setUserPermissions(null);
    else
      setUserPermissions({
        ...defaultPermissions,
        ...userInitialPermissions,
      });
  }, [userInitialPermissions]);

  useEffect(() => {
    setUserRole(userInitialRole);
  }, [userInitialRole]);

  useEffect(() => {
    if (roleInitialPermissions === null) setRolePermissions(null);
    else
      setRolePermissions({
        ...defaultPermissions,
        ...roleInitialPermissions,
      });
  }, [roleInitialPermissions]);

  useEffect(() => {
    if (signedIn) {
      requestAccessUsers();
      requestAccessRoles();
    }
  }, [signedIn, requestAccessUsers, requestAccessRoles]);

  if (error)
    return (
      <Modal
        isActive={activeModal === "user_access"}
        onClose={() => {
          setActiveModal(null);
        }}
        className="flex flex-col gap-10 max-w-sm p-10"
      >
        <div className="flex flex-col gap-3">
          <h1>User Access for {title}</h1>
          <h1>{error}</h1>
        </div>
      </Modal>
    );

  return (
    <Modal
      isActive={activeModal === "user_access"}
      onClose={() => {
        setActiveModal(null);
      }}
      className="flex flex-col gap-2 w-96 p-10"
      header={`User Access for ${title}`}
      closeButton
    >
      <div className="flex flex-col gap-3">
        <div>
          <Collapse accordion defaultActiveKey={["2"]}>
            <Collapse.Panel key="1" className="font-sans" header="Roles">
              {roles ? (
                <div className="max-h-96 overflow-y-auto my-1">
                  <Table
                    size="small"
                    showHeader={false}
                    dataSource={roles.map((role, index) => ({
                      ...role,
                      key: index + 1,
                    }))}
                    columns={[
                      {
                        render: (item) => (
                          <Button
                            className="-my-0.5 mx-3"
                            disabled={false}
                            icon={
                              <FontAwesomeIcon
                                icon={faMinus}
                                size="lg"
                                className="w-8"
                              />
                            }
                            onClick={() => removeAccessRole(item.name)}
                          />
                        ),
                        fixed: "left",
                        width: 60,
                      },
                      {
                        render: (item) => (
                          <div className="text-base">{item.name}</div>
                        ),
                      },
                      {
                        render: (item) => (
                          <>
                            {!item.owner ? (
                              <Dropdown
                                trigger={["click"]}
                                onOpenChange={(open) => {
                                  if (open) requestRolePermissions(item.name);
                                  else setRoleInitialPermissions(null);
                                }}
                                dropdownRender={() => (
                                  <Card>
                                    <div className="flex flex-col items-start gap-0">
                                      <div className="flex flex-row justify-between items-center mb-1 w-full">
                                        <div className="flex flex-row gap-3">
                                          <p
                                            className={`${
                                              darkMode
                                                ? "text-dark-gray-base"
                                                : "text-gray-base"
                                            }`}
                                          >
                                            Permissions:
                                          </p>
                                          {roleInitialPermissions === null ? (
                                            <Spin size="small" />
                                          ) : null}
                                        </div>
                                        {roleInitialPermissions !== null &&
                                        rolePermissions !== null &&
                                        Object.keys(rolePermissions)
                                          .slice()
                                          .sort()
                                          .toString() !==
                                          Object.keys(roleInitialPermissions)
                                            .slice()
                                            .sort()
                                            .toString() ? (
                                          <Tooltip
                                            title={`This role's permission set is outdated.
                                  Press "SAVE" to update it to the newest set of permissions.`}
                                          >
                                            <FontAwesomeIcon
                                              icon={faTriangleExclamation}
                                              className="mb-1 text-red-dark"
                                            />
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                      {Object.entries(defaultPermissions).map(
                                        ([key], index) => (
                                          <div key={index}>
                                            <Checkbox
                                              onChange={({ target }) =>
                                                setRolePermissions({
                                                  ...rolePermissions,
                                                  [key]: target.checked,
                                                })
                                              }
                                              checked={
                                                rolePermissions?.[key] || false
                                              }
                                              disabled={
                                                roleInitialPermissions === null
                                              }
                                            >
                                              {key}
                                            </Checkbox>
                                          </div>
                                        )
                                      )}
                                      <div className="self-stretch flex flex-row items-center justify-between -m-2 mt-1">
                                        <Button
                                          className="font-sans"
                                          onClick={() =>
                                            setRolePermissions(
                                              defaultPermissions
                                            )
                                          }
                                          size="small"
                                          disabled={
                                            !rolePermissions ||
                                            deepEqual(
                                              rolePermissions,
                                              defaultPermissions
                                            )
                                          }
                                        >
                                          Default
                                        </Button>
                                        <Button
                                          className="font-sans"
                                          type="primary"
                                          onClick={() =>
                                            modifyRolePermissions(item.name)
                                          }
                                          size="small"
                                          disabled={deepEqual(
                                            rolePermissions,
                                            roleInitialPermissions
                                          )}
                                        >
                                          Save
                                        </Button>
                                      </div>
                                    </div>
                                  </Card>
                                )}
                                placement="bottomRight"
                              >
                                <Button
                                  className="-my-0.5 mx-3"
                                  disabled={false}
                                  icon={
                                    <FontAwesomeIcon
                                      icon={faListCheck}
                                      size="lg"
                                      className="w-8"
                                    />
                                  }
                                />
                              </Dropdown>
                            ) : null}
                          </>
                        ),
                        fixed: "right",
                        width: 60,
                      },
                    ]}
                    pagination={{
                      defaultPageSize: 500,
                      hideOnSinglePage: true,
                    }}
                    footer={() => (
                      <Space.Compact size="large" className="w-full px-2">
                        {newRoleLoading ? (
                          <div className="flex flex-row items-center justify-center w-[50px]">
                            <Spin />
                          </div>
                        ) : (
                          <Button
                            disabled={newRole === null || newRole === ""}
                            icon={
                              <FontAwesomeIcon
                                icon={faPlus}
                                size="lg"
                                className="w-8"
                              />
                            }
                            onClick={addAccessRole}
                          />
                        )}
                        <Tooltip
                          title={roleWarning}
                          color="red"
                          open={roleWarning !== null}
                        >
                          <Input
                            className="mr-3"
                            disabled={false}
                            size="large"
                            placeholder="Role Name"
                            onChange={({ target }) => setNewRole(target.value)}
                            value={newRole}
                            onFocus={() => setRoleWarning(null)}
                          />
                        </Tooltip>
                        <Dropdown
                          trigger={["click"]}
                          dropdownRender={() => (
                            <Card>
                              <div className="flex flex-col items-start gap-0">
                                <div className="flex flex-row justify-between items-center mb-1 w-full">
                                  <div className="flex flex-row gap-3">
                                    <p
                                      className={`${
                                        darkMode
                                          ? "text-dark-gray-base"
                                          : "text-gray-base"
                                      }`}
                                    >
                                      Permissions:
                                    </p>
                                  </div>
                                </div>
                                {Object.entries(defaultPermissions).map(
                                  ([key], index) => (
                                    <div key={index}>
                                      <Checkbox
                                        onChange={({ target }) =>
                                          setNewRolePermissions({
                                            ...newRolePermissions,
                                            [key]: target.checked,
                                          })
                                        }
                                        checked={
                                          newRolePermissions?.[key] || false
                                        }
                                      >
                                        {key}
                                      </Checkbox>
                                    </div>
                                  )
                                )}
                                <div className="self-stretch flex flex-row items-center justify-between -m-2 mt-1">
                                  <Button
                                    className="font-sans"
                                    onClick={() =>
                                      setNewRolePermissions(defaultPermissions)
                                    }
                                    size="small"
                                    disabled={deepEqual(
                                      newRolePermissions,
                                      defaultPermissions
                                    )}
                                  >
                                    Default
                                  </Button>
                                </div>
                              </div>
                            </Card>
                          )}
                          placement="bottomRight"
                        >
                          <Button
                            disabled={false}
                            icon={
                              <FontAwesomeIcon
                                icon={faListCheck}
                                size="lg"
                                className="w-8"
                              />
                            }
                          />
                        </Dropdown>
                      </Space.Compact>
                    )}
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center w-full h-20">
                  <Spin size="large" />
                </div>
              )}
            </Collapse.Panel>
            <Collapse.Panel key="2" className="font-sans" header="Users">
              {users && owner !== null && roles ? (
                <div className="max-h-96 overflow-y-auto my-1">
                  <Table
                    size="small"
                    showHeader={false}
                    dataSource={[
                      {
                        username: owner?.username || "Owner Not Registered",
                        email: owner?.email,
                        key: 0,
                        owner: true,
                        special: owner?.special,
                      },
                      ...users.map((user, index) => ({
                        ...user,
                        key: index + 1,
                      })),
                    ]}
                    columns={[
                      {
                        render: (item) => (
                          <>
                            {!item.owner ? (
                              <Button
                                className="-my-0.5 mx-3"
                                disabled={false}
                                icon={
                                  <FontAwesomeIcon
                                    icon={faMinus}
                                    size="lg"
                                    className="w-8"
                                  />
                                }
                                onClick={() => removeAccessUser(item.id)}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faCrown}
                                size="lg"
                                className="w-8 mx-3"
                              />
                            )}
                          </>
                        ),
                        fixed: "left",
                        width: 60,
                      },
                      {
                        render: (item) => (
                          <div
                            className={`text-base ${
                              item.special ? "italic" : ""
                            }`}
                          >
                            {item.username}
                            <span className="ml-3 text-sm font-normal text-gray-dark dark:text-dark-gray-dark">
                              {item.email}
                            </span>
                          </div>
                        ),
                      },
                      {
                        render: (item) => (
                          <>
                            {!item.owner ? (
                              <Dropdown
                                trigger={["click"]}
                                onOpenChange={(open) => {
                                  if (open) requestUserPermissions(item.id);
                                  else {
                                    setUserInitialPermissions(null);
                                    setUserInitialRole(null);
                                  }
                                }}
                                dropdownRender={() => (
                                  <Card>
                                    <div className="flex flex-col items-start gap-0">
                                      <div className="flex flex-row justify-between items-center w-full">
                                        <div className="flex flex-row gap-3">
                                          <p
                                            className={`${
                                              darkMode
                                                ? "text-dark-gray-base"
                                                : "text-gray-base"
                                            }`}
                                          >
                                            Permissions:
                                          </p>
                                          {userInitialPermissions === null ? (
                                            <Spin size="small" />
                                          ) : null}
                                        </div>

                                        {userInitialPermissions !== null &&
                                        userPermissions !== null &&
                                        Object.keys(userPermissions)
                                          .slice()
                                          .sort()
                                          .toString() !==
                                          Object.keys(userInitialPermissions)
                                            .slice()
                                            .sort()
                                            .toString() ? (
                                          <Tooltip
                                            title={`This user's permission set is outdated.
                                  Press "SAVE" to update it to the newest set of permissions.`}
                                          >
                                            <FontAwesomeIcon
                                              icon={faTriangleExclamation}
                                              className="mb-1 text-red-dark"
                                            />
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                      <Select
                                        className="w-full my-2"
                                        value={userRole}
                                        onChange={(value, { permissions }) => {
                                          setUserRole(value);
                                          if (permissions)
                                            setUserPermissions(permissions);
                                          else
                                            setUserPermissions(
                                              userInitialPermissions
                                            );
                                        }}
                                        options={[
                                          {
                                            value: false,
                                            label: <i>Custom Permission</i>,
                                          },
                                          ...roles.map((role) => ({
                                            ...role,
                                            value: role.name,
                                            label: role.name,
                                          })),
                                        ]}
                                        disabled={userInitialRole == null}
                                      />
                                      {Object.entries(defaultPermissions).map(
                                        ([key], index) => (
                                          <div key={index}>
                                            <Checkbox
                                              onChange={({ target }) =>
                                                setUserPermissions({
                                                  ...userPermissions,
                                                  [key]: target.checked,
                                                })
                                              }
                                              checked={
                                                userPermissions?.[key] || false
                                              }
                                              disabled={
                                                userInitialPermissions ===
                                                  null || userRole
                                              }
                                            >
                                              {key}
                                            </Checkbox>
                                          </div>
                                        )
                                      )}
                                      <div className="self-stretch flex flex-row items-center justify-between -m-2 mt-1">
                                        <Button
                                          className="font-sans"
                                          onClick={() =>
                                            setUserPermissions(
                                              defaultPermissions
                                            )
                                          }
                                          size="small"
                                          disabled={
                                            !userPermissions ||
                                            deepEqual(
                                              userPermissions,
                                              defaultPermissions
                                            ) ||
                                            userRole
                                          }
                                        >
                                          Default
                                        </Button>
                                        <Button
                                          className="font-sans"
                                          type="primary"
                                          onClick={() =>
                                            modifyUserPermissions(item.id)
                                          }
                                          size="small"
                                          disabled={
                                            deepEqual(
                                              userPermissions,
                                              userInitialPermissions
                                            ) && userRole === userInitialRole
                                          }
                                        >
                                          Save
                                        </Button>
                                      </div>
                                    </div>
                                  </Card>
                                )}
                                placement="bottomRight"
                              >
                                <Button
                                  className="-my-0.5 mx-3"
                                  disabled={false}
                                  icon={
                                    <FontAwesomeIcon
                                      icon={faListCheck}
                                      size="lg"
                                      className="w-8"
                                    />
                                  }
                                />
                              </Dropdown>
                            ) : null}
                          </>
                        ),
                        fixed: "right",
                        width: 60,
                      },
                    ]}
                    pagination={{
                      defaultPageSize: 500,
                      hideOnSinglePage: true,
                    }}
                    footer={() => (
                      <Space.Compact size="large" className="w-full px-2">
                        {newUserLoading ? (
                          <div className="flex flex-row items-center justify-center w-[50px]">
                            <Spin />
                          </div>
                        ) : (
                          <Button
                            disabled={newUser === null || newUser === ""}
                            icon={
                              <FontAwesomeIcon
                                icon={faPlus}
                                size="lg"
                                className="w-8"
                              />
                            }
                            onClick={addAccessUser}
                          />
                        )}
                        <Tooltip
                          title={userWarning}
                          color="red"
                          open={userWarning !== null}
                        >
                          <Input
                            className="mr-3"
                            disabled={false}
                            size="large"
                            placeholder="Email or Username"
                            onChange={({ target }) => setNewUser(target.value)}
                            value={newUser}
                            onFocus={() => setUserWarning(null)}
                          />
                        </Tooltip>
                        <Dropdown
                          trigger={["click"]}
                          dropdownRender={() => (
                            <Card>
                              <div className="flex flex-col items-start gap-0">
                                <div className="flex flex-row justify-between items-center mb-1 w-full">
                                  <div className="flex flex-row gap-3">
                                    <p
                                      className={`${
                                        darkMode
                                          ? "text-dark-gray-base"
                                          : "text-gray-base"
                                      }`}
                                    >
                                      Permissions:
                                    </p>
                                  </div>
                                </div>{" "}
                                <Select
                                  className="w-full my-2"
                                  value={newUserRole}
                                  onChange={(value, { permissions }) => {
                                    setNewUserRole(value);
                                    if (permissions)
                                      setNewUserPermissions(permissions);
                                    else
                                      setNewUserPermissions(defaultPermissions);
                                  }}
                                  options={[
                                    {
                                      value: false,
                                      label: <i>Custom Permission</i>,
                                    },
                                    ...roles.map((role) => ({
                                      ...role,
                                      value: role.name,
                                      label: role.name,
                                    })),
                                  ]}
                                />
                                {Object.entries(defaultPermissions).map(
                                  ([key], index) => (
                                    <div key={index}>
                                      <Checkbox
                                        onChange={({ target }) =>
                                          setNewUserPermissions({
                                            ...newUserPermissions,
                                            [key]: target.checked,
                                          })
                                        }
                                        checked={
                                          newUserPermissions?.[key] || false
                                        }
                                        disabled={newUserRole}
                                      >
                                        {key}
                                      </Checkbox>
                                    </div>
                                  )
                                )}
                                <div className="self-stretch flex flex-row items-center justify-between -m-2 mt-1">
                                  <Button
                                    className="font-sans"
                                    onClick={() =>
                                      setNewUserPermissions(defaultPermissions)
                                    }
                                    size="small"
                                    disabled={
                                      deepEqual(
                                        newUserPermissions,
                                        defaultPermissions
                                      ) || newUserRole
                                    }
                                  >
                                    Default
                                  </Button>
                                </div>
                              </div>
                            </Card>
                          )}
                          placement="bottomRight"
                        >
                          <Button
                            disabled={false}
                            icon={
                              <FontAwesomeIcon
                                icon={faListCheck}
                                size="lg"
                                className="w-8"
                              />
                            }
                          />
                        </Dropdown>
                      </Space.Compact>
                    )}
                  />
                  {invites && invites.length > 0 ? (
                    <>
                      <p className="px-6 py-3">Invited:</p>
                      <Table
                        size="small"
                        showHeader={false}
                        dataSource={invites.map((user, index) => ({
                          user,
                          key: index,
                        }))}
                        columns={[
                          {
                            render: (item) => (
                              <div className="text-base mx-4">
                                <span className="text-sm font-normal text-gray-dark dark:text-dark-gray-dark">
                                  {item.user}
                                </span>
                              </div>
                            ),
                          },
                        ]}
                        pagination={{
                          defaultPageSize: 500,
                          hideOnSinglePage: true,
                        }}
                      />
                    </>
                  ) : null}
                </div>
              ) : (
                <div className="flex items-center justify-center w-full h-20">
                  <Spin size="large" />
                </div>
              )}
            </Collapse.Panel>
          </Collapse>
        </div>
      </div>
    </Modal>
  );
}
