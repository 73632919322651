import { useCallback, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { AccountContext } from "../Contexts";
import AuthManager from "../scripts/AuthManager";
import APIManager from "../scripts/APIManager";

export default function AccountContainer() {
  const [signedIn, setSignedIn] = useState(null);
  const location = useLocation();
  const [redirectURI] = useState(
    window.location.protocol +
      "//" +
      window.location.host +
      (location.pathname === "/"
        ? window.location.pathname.slice(0, -1)
        : window.location.pathname.replace(location.pathname, "")) +
      "/home"
  );

  const signOut = useCallback(() => {
    if (AuthManager.user) AuthManager.user.signOut();
    AuthManager.refreshTokenValue = null;
    AuthManager.username = null;
    AuthManager.accessToken = null;
    AuthManager.accessTokenExp = null;
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("username");
    setSignedIn(false);
  }, []);

  const goToCheckout = useCallback(
    (product, product_tier_index) => {
      APIManager.sendRequest(
        "create_checkout_session",
        {
          success_address: redirectURI,
          cancel_address: window.location.href.split("?")[0],
          product,
          product_tier_index,
        },
        true
      ).then((data) => {
        if (data.success) window.location.href = data.url;
      });
    },
    [redirectURI]
  );

  useEffect(() => {
    let refreshToken = localStorage.getItem("refreshToken") || null;
    let username = localStorage.getItem("username") || null;
    if (refreshToken && username) {
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("username", username);
      AuthManager.refreshTokenValue = refreshToken;
      AuthManager.username = username;
      AuthManager.signOut = signOut;
      AuthManager.setSignedIn = setSignedIn;
      AuthManager.refreshToken();
    } else {
      setSignedIn(false);
    }
  }, [signOut]);

  useEffect(() => {
    AuthManager.setRefreshToken = (refreshToken) =>
      localStorage.setItem("refreshToken", refreshToken);
    AuthManager.setUsername = (username) =>
      localStorage.setItem("username", username);
  }, []);

  useEffect(() => {
    if (!signedIn) return;

    const urlParams = new URLSearchParams(location.search);

    let planParam = urlParams.get("plan");
    if (planParam) {
      const [plan, index] = planParam.split("_");
      goToCheckout(plan, parseInt(index));
    }
  }, [location, signedIn, goToCheckout]);

  return (
    <AccountContext.Provider
      value={{
        signOut,
        signedIn,
        setSignedIn,
        goToCheckout,
        redirectURI,
      }}
    >
      <Outlet />
    </AccountContext.Provider>
  );
}
