import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Input, Space, Spin, Tooltip, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useContext, useEffect, useState } from "react";
import Card from "../../components/Card";
import { SiteContext } from "../../Contexts";
import APIManager from "../../scripts/APIManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";

export default function Questions() {
  const { guildId, questionAnswerResponse } = useContext(SiteContext);
  const [question, setQuestion] = useState("");
  const [users, setUsers] = useState(null);
  const [awaitingQuestion, setAwaitingQuestion] = useState(null);
  const [answer, setAnswer] = useState(<i>Your answer will appear here</i>);
  const [error, setError] = useState(null);

  const sendQuestion = useCallback(() => {
    APIManager.sendRequest(
      "send_question",
      {
        guild_id: guildId,
        question,
        users,
      },
      true
    ).then((data) => {
      if (data.success) setAwaitingQuestion(question);
      else setError("An error has occured");
    });
  }, [guildId, question, users]);

  useEffect(() => {
    if (questionAnswerResponse) {
      let { question: responseQuestion, answer: responseAnswer } =
        questionAnswerResponse;
      if (awaitingQuestion === responseQuestion) {
        setAnswer(responseAnswer);
        setAwaitingQuestion(null);
      }
    }
  }, [questionAnswerResponse, awaitingQuestion]);

  return (
    <>
      <div className="flex flex-row justify-between items-center mx-6 my-2">
        <h1 className="softer-text">Questions and Answers</h1>
      </div>

      <div className="flex flex-row flex-wrap gap-6">
        <Card className="grow shrink-0 basis-64">
          <h4>Question:</h4>
          <TextArea
            placeholder="Enter your question here"
            autoSize={{ minRows: 4, maxRows: 4 }}
            onChange={({ target }) => setQuestion(target.value)}
          />
          <Space.Compact size="large">
            <Input
              size="large"
              placeholder="Users"
              allowClear={{
                clearIcon: (
                  <CloseCircleOutlined
                    className={users !== null ? "cursor-pointer" : "opacity-0"}
                    onClick={users !== null ? () => setUsers(null) : null}
                  />
                ),
              }}
              value={users?.join(",")}
              onChange={({ target }) =>
                target.value === ""
                  ? setUsers(null)
                  : setUsers(target.value.split(","))
              }
            />
            <Tooltip title="Use a .txt file of usernames.">
              <Upload
                accept=".txt"
                showUploadList={false}
                beforeUpload={(file) => {
                  const reader = new FileReader();

                  reader.onload = (e) => {
                    const uploadedItems = e.target.result
                      .split("\n")
                      .map((item) => item.replace("\r", ""))
                      .filter((item) => item.length > 0);
                    setUsers(uploadedItems);
                  };
                  reader.readAsText(file);

                  return false;
                }}
              >
                <Button
                  icon={
                    <FontAwesomeIcon className="px-2" icon={faFileArrowUp} />
                  }
                  className="font-sans"
                ></Button>
              </Upload>
            </Tooltip>
          </Space.Compact>
          <Button
            size="large"
            type="primary"
            onClick={sendQuestion}
            disabled={!question}
          >
            Submit
          </Button>
        </Card>
        <Card className="grow shrink-0 basis-64">
          <h4>Answer:</h4>
          {error ? (
            <p className="text-red-dark">{error}</p>
          ) : awaitingQuestion ? (
            <Spin size="large" />
          ) : (
            <p>{answer}</p>
          )}
        </Card>
      </div>
    </>
  );
}
