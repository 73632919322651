import { Button, InputNumber } from "antd";
import { useCallback, useContext, useState } from "react";
import { SiteContext } from "../../../Contexts";
import APIManager from "../../../scripts/APIManager";
import Modal from "../../Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

export default function BatchUpdateModal(props) {
  const { activeModal, setActiveModal } = useContext(SiteContext);
  const { openNotification } = useContext(SiteContext);
  const { guildId, processInterval } = props;
  const [hours, setHours] = useState(processInterval[0]);
  const [minutes, setMinutes] = useState(processInterval[1]);

  const sendUpdate = useCallback(
    async () =>
      await APIManager.sendRequest(
        "update_batch",
        {
          guild_id: guildId,
          hours,
          minutes,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.success)
          openNotification(
            "success",
            "Processing",
            "The batch process frequency has been updated."
          );
        else
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message
          );
        return data.success;
      }),
    [openNotification, guildId, hours, minutes]
  );

  return (
    <Modal
      isActive={activeModal === "update_batch"}
      onClose={() => {
        setActiveModal(null);
        setHours(processInterval[0]);
        setMinutes(processInterval[1]);
      }}
      className="flex flex-col gap-2 max-w-sm p-10"
      header="Update the Automatic Batch Processing Frequency"
    >
      <div className="flex flex-col gap-3 mx-2">
        <p>Interval:</p>
        <div className="flex flex-row gap-3">
          <InputNumber
            size="large"
            addonBefore="Hours"
            min={minutes === 0 ? 1 : 0}
            onChange={(value) => setHours(value)}
            value={hours}
          />
          <InputNumber
            size="large"
            addonBefore="Minutes"
            min={hours === 0 ? 1 : 0}
            max={59}
            onChange={(value) => setMinutes(value)}
            value={minutes}
          />
        </div>
        <p className="text-sm font-normal softer-text">
          {hours || minutes ? "Message batches will be processed every " : null}
          {hours ? (
            <span>
              {hours} hour
              {hours === 1 ? "" : "s"}
            </span>
          ) : null}
          {hours && minutes ? " and " : null}
          {minutes ? (
            <span>
              {minutes} minute
              {minutes === 1 ? "" : "s"}
            </span>
          ) : null}
          {hours || minutes ? "." : null}
        </p>
        {hours < 1 ? (
          <div className="flex flex-row items-center gap-1">
            <FontAwesomeIcon
              className="text-secondary"
              icon={faCircleExclamation}
              size="sm"
            />
            <p className="text-sm max-w-xs text-secondary">
              We do not recommend intervals of less than 60 minutes as batches
              may lose context.
            </p>
          </div>
        ) : null}
      </div>
      <div className="flex flex-row-reverse gap-3 m-2">
        <Button
          disabled={
            typeof hours !== "number" ||
            typeof minutes !== "number" ||
            (hours === 0 && minutes === 0)
          }
          type="primary"
          size="large"
          className="w-32 font-sans"
          onClick={() => {
            setActiveModal(null);
            sendUpdate().then(() => {
              setHours(processInterval[0]);
              setMinutes(processInterval[1]);
            });
          }}
        >
          Apply
        </Button>
        <Button
          size="large"
          className="w-32 font-sans"
          onClick={() => {
            setActiveModal(null);
            setHours(processInterval[0]);
            setMinutes(processInterval[1]);
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
