import { DownOutlined } from "@ant-design/icons";
import { faStar as faStarHollow } from "@fortawesome/free-regular-svg-icons";
import { faList, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Select, Table, Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import Helper from "../../../scripts/Helper";
import Badge from "../../Badge";
import Modal from "../../Modal";

export default function ContextModal({
  isActive,
  onClose,
  expandedRecord: expandedRecordInput,
  expandedData,
  messageColumns,
  permissions,
  feedbackTypes,
  updateFeedbackType,
  markFeedbackAsStarred,
  markFeedbackAsUnstarred,
  data,
  setData,
  setSpotlightsToAction,
  setSpotlightsToClear,
  setSpotlightsToRecover,
  setSpotlightsToRemove,
  setActiveModal,
  setActionClassification,
  spotlightActions,
}) {
  const [expanded, setExpanded] = useState(false);
  const [typeOpen, setTypeOpen] = useState(false);
  const [expandedRecord, setExpandedRecord] = useState(expandedRecordInput);

  const expandedEndRef = useRef(null);

  useEffect(
    () => setExpandedRecord(expandedRecordInput),
    [expandedRecordInput]
  );

  useEffect(() => {
    if (!expanded) {
      setTypeOpen(false);
    }
  }, [expanded]);

  return (
    <Modal
      className="max-w-5xl gap-0 mx-6"
      isActive={isActive}
      onClose={onClose}
      onOpen={() =>
        expandedEndRef.current?.scrollIntoView({ behavior: "smooth" })
      }
      header={
        <div className="flex flex-row items-center gap-3">
          <p>
            <span className="font-bold">Spotlight:</span>{" "}
            {expandedRecord?.headline}
          </p>
          {!expandedRecord ? null : (
            <>
              {expandedRecord.trashed === true ? (
                <div className="flex flex-row gap-1">
                  <Tooltip
                    title={
                      permissions?.["Action Spotlights"] ||
                      permissions?.["Suggest Analytics Edits"]
                        ? null
                        : "You do not have permission."
                    }
                  >
                    <div>
                      <Button
                        className="font-sans -my-3"
                        onClick={() => {
                          setSpotlightsToRecover([
                            {
                              id: expandedRecord.conversationid,
                              name: expandedRecord.headline,
                            },
                          ]);
                          setActiveModal("recover");
                        }}
                        disabled={
                          !permissions?.["Action Spotlights"] &&
                          !permissions?.["Suggest Analytics Edits"]
                        }
                      >
                        Recover
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={
                      permissions?.["Action Spotlights"] ||
                      permissions?.["Suggest Analytics Edits"]
                        ? null
                        : "You do not have permission."
                    }
                  >
                    <div>
                      <Button
                        className="font-sans -my-3"
                        onClick={() => {
                          setSpotlightsToClear([
                            {
                              id: expandedRecord.conversationid,
                              name: expandedRecord.headline,
                            },
                          ]);
                          setActiveModal("clear");
                        }}
                        disabled={
                          !permissions?.["Action Spotlights"] &&
                          !permissions?.["Suggest Analytics Edits"]
                        }
                      >
                        Delete
                      </Button>
                    </div>
                  </Tooltip>
                </div>
              ) : expandedRecord.actioned === null ? (
                <Tooltip
                  title={
                    permissions?.["Action Spotlights"] ||
                    permissions?.["Suggest Analytics Edits"]
                      ? null
                      : "You do not have permission."
                  }
                >
                  <div>
                    <Dropdown
                      trigger={["click"]}
                      menu={{
                        items: spotlightActions,
                        onClick: ({ key }) => {
                          if (key === "Trash") {
                            setSpotlightsToRemove([
                              {
                                id: expandedRecord.conversationid,
                                name: expandedRecord.headline,
                              },
                            ]);
                            setActiveModal("remove");
                          } else {
                            setSpotlightsToAction([
                              {
                                id: expandedRecord.conversationid,
                                name: expandedRecord.headline,
                              },
                            ]);
                            setActionClassification(key);
                            setActiveModal("action");
                          }
                        },
                      }}
                      disabled={
                        !permissions?.["Action Spotlights"] &&
                        !permissions?.["Suggest Analytics Edits"]
                      }
                    >
                      <Button
                        className="font-sans -my-3"
                        disabled={
                          !permissions?.["Action Spotlights"] &&
                          !permissions?.["Suggest Analytics Edits"]
                        }
                      >
                        Action <DownOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                </Tooltip>
              ) : (
                <Badge
                  color={
                    expandedRecord.actioned === "Bug"
                      ? "yellow"
                      : expandedRecord.actioned === "Feedback"
                      ? "base-gray"
                      : expandedRecord.actioned === "Help Request"
                      ? "blue"
                      : "blue"
                  }
                >
                  {expandedRecord.actioned}
                </Badge>
              )}
            </>
          )}
        </div>
      }
      closeButton
    >
      {expandedRecord ? (
        <div className="relative max-h-full box-border -m-2 min-h-[310px] overflow-y-hidden">
          <div className="flex flex-col max-h-full box-border pb-4 px-2 mr-12 overflow-y-auto">
            <div className="flex flex-row p-2">
              <p>Context</p>
            </div>
            <div className="overflow-y-auto h-full">
              <Table
                className="grow filled-header"
                sticky={true}
                tableLayout="auto"
                loading={!expandedData}
                dataSource={expandedData}
                columns={messageColumns}
                size="small"
                pagination={false}
              />
              <div ref={expandedEndRef} />
            </div>
          </div>
          <div
            className={`absolute right-0 top-0 bottom-0
            flex flex-col box-border
            ${expanded ? "w-80" : "w-12"}
            h-full transition-all overflow-hidden
            bg-white dark:bg-dark-white z-50
            border-0 border-l border-solid border-black/10 dark:border-[#303030] shadow-lg`}
            onMouseEnter={() => {
              setExpanded(true);
            }}
            onMouseLeave={() => {
              setExpanded(false);
            }}
          >
            <div className="flex flex-row items-center gap-4 p-2 mx-2 border-0 border-b border-solid border-black/10 dark:border-[#303030]">
              <FontAwesomeIcon icon={faList} />
              <p>Details</p>
            </div>
            <div
              className={`relative grow p-2
            after:absolute after:content-[''] after:inset-0 after:transition-all
            after:bg-gradient-to-r after:from-transparent after:to-white after:dark:to-dark-white
            ${expanded ? "after:left-full" : "after:left-0"}`}
            >
              <div className="flex flex-row items-center px-2 whitespace-nowrap">
                <p className="shrink-0 softer-text text-sm w-24 py-2">
                  Channel
                </p>
                <Badge className="max-w-[180px]" tooltip>
                  {expandedRecord.channelname}
                </Badge>
              </div>
              <div className="flex flex-row items-center w-full box-border px-2 whitespace-nowrap">
                <p className="shrink-0 softer-text text-sm w-24 py-2">Thread</p>
                {expandedRecord.threadname ? (
                  <Badge
                    className="max-w-[180px]"
                    color="blue"
                    tooltip={
                      <span>
                        {expandedRecord.threadname} <i>(Thread)</i>
                      </span>
                    }
                  >
                    {expandedRecord.threadname}
                  </Badge>
                ) : (
                  <p>None</p>
                )}
              </div>
              <div className="flex flex-row items-center px-2 whitespace-nowrap">
                <p className="shrink-0 softer-text text-sm w-24 py-2">Time</p>
                {expandedRecord.startingtimestamp ? (
                  <Tooltip
                    title={`${new Date(
                      expandedRecord.startingtimestamp
                    ).toLocaleTimeString("en-us", {
                      weekday: "short",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}`}
                  >
                    {Helper.timeAgo(expandedRecord.startingtimestamp)}
                  </Tooltip>
                ) : (
                  <p>N/A</p>
                )}
              </div>
              <div className="flex flex-row items-center px-2 whitespace-nowrap">
                <p className="shrink-0 softer-text text-sm w-24 py-2">Type</p>
                <Tooltip
                  title={
                    permissions?.["Edit Spotlights"] ||
                    permissions?.["Suggest Analytics Edits"]
                      ? null
                      : "You do not have permission."
                  }
                >
                  <div>
                    <Select
                      className="ant-select-text-base w-full"
                      size="small"
                      dropdownMatchSelectWidth={false}
                      onChange={(value) => {
                        updateFeedbackType(
                          expandedRecord.conversationid,
                          expandedRecord.headline,
                          [value],
                          expandedRecord.feedbacktype[0]
                        );
                        setData(
                          data.map((spotlight) =>
                            spotlight.conversationid ===
                            expandedRecord.conversationid
                              ? { ...spotlight, feedbacktype: [value] }
                              : spotlight
                          )
                        );
                        setExpandedRecord({
                          ...expandedRecord,
                          feedbacktype: [value],
                        });
                      }}
                      value={{
                        value: expandedRecord.feedbacktype[0],
                        label: expandedRecord.feedbacktype[0],
                      }}
                      options={feedbackTypes}
                      disabled={
                        !permissions?.["Edit Spotlights"] &&
                        !permissions?.["Suggest Analytics Edits"]
                      }
                      onDropdownVisibleChange={(open) => setTypeOpen(open)}
                      open={typeOpen}
                    />
                  </div>
                </Tooltip>
              </div>
              <div className="flex flex-row items-center px-2 whitespace-nowrap">
                <p className="shrink-0 softer-text text-sm w-24 py-2">
                  Starred
                </p>
                {expandedRecord.starred === true ? (
                  <FontAwesomeIcon
                    className="cursor-pointer text-gold"
                    onClick={
                      permissions?.["Edit Spotlights"] ||
                      permissions?.["Suggest Analytics Edits"]
                        ? (e) => {
                            e.stopPropagation();
                            markFeedbackAsUnstarred(
                              expandedRecord.conversationid,
                              expandedRecord.headline
                            );
                            setData(
                              data.map((spotlight) =>
                                spotlight.conversationid ===
                                expandedRecord.conversationid
                                  ? { ...spotlight, starred: null }
                                  : spotlight
                              )
                            );
                            setExpandedRecord({
                              ...expandedRecord,
                              starred: null,
                            });
                          }
                        : () => {}
                    }
                    icon={faStar}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="cursor-pointer text-gray-medium dark:text-dark-gray-medium"
                    onClick={
                      permissions?.["Edit Spotlights"] ||
                      permissions?.["Suggest Analytics Edits"]
                        ? (e) => {
                            e.stopPropagation();
                            markFeedbackAsStarred(
                              expandedRecord.conversationid,
                              expandedRecord.headline
                            );
                            setData(
                              data.map((spotlight) =>
                                spotlight.conversationid ===
                                expandedRecord.conversationid
                                  ? { ...spotlight, starred: true }
                                  : spotlight
                              )
                            );
                            setExpandedRecord({
                              ...expandedRecord,
                              starred: true,
                            });
                          }
                        : () => {}
                    }
                    icon={faStarHollow}
                  />
                )}
              </div>
              <div className="flex flex-row items-center px-2 whitespace-nowrap">
                <p className="shrink-0 softer-text text-sm w-24 py-2">Users</p>
                {expandedRecord.numofusers}
              </div>
              <div className="flex flex-row items-center px-2 whitespace-nowrap">
                <p className="shrink-0 softer-text text-sm w-24 py-2">
                  Messages
                </p>
                {expandedRecord.numofmessages}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Modal>
  );
}
