import { useEffect, useState } from "react";

export default function LandingNav(props) {
  const { children, isActive, onOpen, onClose, className, clickOff } = props;
  const [active, setActive] = useState(isActive);
  const [display, setDisplay] = useState(false);
  const [ranOnOpen, setRanOnOpen] = useState(false);
  const [backgroundClasses, setBackgroundClasses] = useState("opacity-0");
  const [modalClasses, setModalClasses] = useState("mt-96 opacity-0");

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  useEffect(() => {
    if (active) {
      setDisplay(true);
      setTimeout(() => {
        setBackgroundClasses("opacity-100");
        setModalClasses("mr-1 opacity-100");
      }, 10);
    } else {
      setBackgroundClasses("opacity-0");
      setModalClasses("-mr-16 opacity-0");
      setTimeout(() => setDisplay(false), 210);
    }
  }, [active]);

  useEffect(() => {
    if (display && onOpen && ranOnOpen === false) {
      onOpen();
      setRanOnOpen(true);
    }
  }, [display, onOpen, ranOnOpen]);

  useEffect(() => {
    if (active) setRanOnOpen(false);
  }, [active]);

  if (!display) return null;

  return (
    <div className="fixed flex flex-col justify-center items-center inset-0 overflow-hidden p-4 z-50 max-h-screen p-12">
      <div
        className={`transition-all duration-200 absolute inset-0 bg-black/[.12] dark:bg-black/50 ${backgroundClasses}`}
        onClick={
          clickOff === undefined || clickOff
            ? () => {
                setActive(false);
                if (onClose) onClose();
              }
            : null
        }
      ></div>
      <div
        className={`absolute top-10 sm:top-14 right-0 top-0 transition-all
            duration-200 z-50 overflow-auto ${className} ${modalClasses}`}
      >
        {children}
      </div>
    </div>
  );
}
