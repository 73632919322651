import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Select, Spin } from "antd";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { SiteContext } from "../../Contexts";
import Card from "../Card";

export default function ChangedTypes(props) {
  const { darkMode } = useContext(SiteContext);
  const { className, chartClassName, data: inputData } = props;
  const [chartType, setChartType] = useState("pie");
  const [parentData, setParentData] = useState(null);
  const [childData, setChildData] = useState(null);
  const [groupBy, setGroupBy] = useState("to");
  const [selectedUser, setSelectedUser] = useState(false);
  const [users, setUsers] = useState(null);
  const chartDiv = useRef(null);

  const colors = useMemo(
    () => ({
      "Gameplay Mechanics": "#d84f40",
      "UI/UX": "#ce3464",
      "Feature Request": "#8c30ab",
      Performance: "#623cb1",
      Technical: "#4951af",
      Administrative: "#5fa7ee",
      Other: "#65bad1",
    }),
    []
  );

  useEffect(() => {
    if (!parentData || !childData) return;

    const root = am5.Root.new(chartDiv?.current);

    let themes = [am5themes_Animated.new(root)];
    if (darkMode) themes.push(am5themes_Dark.new(root));
    root.setThemes(themes);

    if (chartType === "pie") {
      const chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          radius: am5.percent(90),
          innerRadius: am5.percent(50),
        })
      );

      // Create series
      var series = chart.series.push(
        am5percent.PieSeries.new(root, {
          name: "Series",
          valueField: "count",
          categoryField: "type",
          legendLabelText: "{category}",
          legendValueText: "{value}",
        })
      );

      // Configuring slices
      series.slices.template.setAll({
        stroke: am5.color(0xffffff),
        strokeWidth: 2,
        templateField: "sliceSettings",
      });

      series.slices.template.set("tooltipText", "{category}: {value}");

      series.data.setAll(parentData);

      // Disabling labels and ticks
      series.labels.template.set("visible", false);
      series.ticks.template.set("visible", false);

      // Create series
      var series2 = chart.series.push(
        am5percent.PieSeries.new(root, {
          name: "Series",
          valueField: "count",
          categoryField: "type",
          legendLabelText: "{category}",
          legendValueText: "{value}",
        })
      );

      // Configuring slices
      series2.slices.template.setAll({
        stroke: am5.color(0xffffff),
        strokeWidth: 2,
        templateField: "sliceSettings",
      });

      series2.slices.template.set("tooltipText", "{category}: {value}");

      series2.data.setAll(childData);

      // Disabling labels and ticks
      series2.labels.template.set("visible", false);
      series2.ticks.template.set("visible", false);
    } else {
      // Create chart
      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          layout: root.verticalLayout,
        })
      );

      // Create axes
      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "original",
          renderer: am5xy.AxisRendererX.new(root, {
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
          }),
          tooltip: am5.Tooltip.new(root, {}),
        })
      );

      xAxis.data.setAll(parentData);

      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          renderer: am5xy.AxisRendererY.new(root, {}),
        })
      );

      // Add series
      let makeSeries = (name) => {
        var series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            stacked: true,
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: name,
            categoryXField: "original",
            fill: colors[name],
          })
        );

        series.columns.template.setAll({
          tooltipText:
            groupBy === "to"
              ? "{name} -> {categoryX}: {valueY}"
              : "{categoryX} -> {name}: {valueY}",
          width: am5.percent(90),
          tooltipY: am5.percent(10),
        });
        series.data.setAll(parentData);

        // Make stuff animate on load
        series.appear();
      };

      Object.keys(colors).forEach((type) => makeSeries(type));

      // Make stuff animate on load
      chart.appear(1000, 100);
    }

    return () => root.dispose();
  }, [darkMode, parentData, childData, groupBy, colors, chartType]);

  useEffect(() => {
    if (chartType === "pie") {
      setParentData(
        Object.entries(
          inputData.reduce((categories, item) => {
            if (
              item.action !== "UpdateFeedbackType" ||
              (item.action === "UpdateFeedbackType" &&
                !item.data.original_category)
            )
              return categories;
            if (selectedUser && item.user !== selectedUser) return categories;

            let key =
              groupBy === "to"
                ? `To ${item.data.category}`
                : `From ${item.data.original_category}`;
            if (key in categories) categories[key]++;
            else categories[key] = 1;

            return categories;
          }, {})
        )
          .map(([type, count]) => {
            let typeKey = type.split(groupBy === "to" ? "To " : "From ")[1];
            return {
              type,
              count,
              sliceSettings: {
                fill: am5.color(colors[typeKey]),
              },
            };
          })
          .sort((item1, item2) => {
            let item1Type = item1.type.split(
              groupBy === "to" ? "To " : "From "
            )[1];
            let item2Type = item2.type.split(
              groupBy === "to" ? "To " : "From "
            )[1];
            return item1Type < item2Type ? -1 : item1Type > item2Type ? 1 : 0;
          })
      );
      setChildData(
        Object.entries(
          inputData.reduce((categories, item) => {
            if (
              item.action !== "UpdateFeedbackType" ||
              (item.action === "UpdateFeedbackType" &&
                !item.data.original_category)
            )
              return categories;
            if (selectedUser && item.user !== selectedUser) return categories;

            if (
              `${item.data.original_category} -> ${item.data.category}` in
              categories
            )
              categories[
                `${item.data.original_category} -> ${item.data.category}`
              ]++;
            else
              categories[
                `${item.data.original_category} -> ${item.data.category}`
              ] = 1;

            return categories;
          }, {})
        )
          .map(([type, count]) => {
            let typeKey = type.split(" -> ")[groupBy === "to" ? 1 : 0];
            return {
              type,
              count,
              sliceSettings: {
                fill: am5.color(colors[typeKey]),
              },
            };
          })
          .sort((item1, item2) => {
            let item1Type = item1.type.split(" -> ")[groupBy === "to" ? 1 : 0];
            let item2Type = item2.type.split(" -> ")[groupBy === "to" ? 1 : 0];
            return item1Type < item2Type ? -1 : item1Type > item2Type ? 1 : 0;
          })
      );
    } else {
      setParentData(
        Object.entries(
          inputData.reduce((categories, item) => {
            if (
              item.action !== "UpdateFeedbackType" ||
              (item.action === "UpdateFeedbackType" &&
                !item.data.original_category)
            )
              return categories;
            if (selectedUser && item.user !== selectedUser) return categories;

            if (groupBy === "to") {
              if (item.data.category in categories) {
                if (
                  item.data.original_category in categories[item.data.category]
                )
                  categories[item.data.category][item.data.original_category]++;
                else
                  categories[item.data.category][
                    item.data.original_category
                  ] = 1;
              } else {
                categories[item.data.category] = {};
                categories[item.data.category][item.data.original_category] = 1;
              }
            } else {
              if (item.data.original_category in categories) {
                if (
                  item.data.category in categories[item.data.original_category]
                )
                  categories[item.data.original_category][item.data.category]++;
                else
                  categories[item.data.original_category][
                    item.data.category
                  ] = 1;
              } else {
                categories[item.data.original_category] = {};
                categories[item.data.original_category][item.data.category] = 1;
              }
            }

            return categories;
          }, {})
        ).map(([original, children]) => ({
          original,
          ...children,
        }))
      );
    }
  }, [inputData, groupBy, selectedUser, colors, chartType]);

  useEffect(
    () =>
      setUsers(
        inputData.reduce((users, item) => {
          if (!users.map(({ label }) => label).includes(item.user))
            users.push({ value: item.user, label: item.user });

          return users;
        }, [])
      ),
    [inputData]
  );

  return (
    <Card
      className={className}
      header={
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row items-center gap-3">
            <h1>Type Changes</h1>
            <Select
              size="medium"
              dropdownMatchSelectWidth={false}
              onChange={setChartType}
              value={chartType}
              options={[
                { value: "pie", label: "Pie Chart" },
                { value: "bar", label: "Bar Graph" },
              ]}
            />
          </div>
          <div className="flex flex-row items-center gap-3">
            <div className="flex flex-row items-center gap-2">
              <p>Group By:</p>
              <Select
                size="medium"
                dropdownMatchSelectWidth={false}
                onChange={setGroupBy}
                value={groupBy}
                options={[
                  { value: "to", label: "Modified Type" },
                  { value: "from", label: "Original Type" },
                ]}
              />
            </div>
            {users ? (
              <div className="flex flex-row items-center gap-2">
                <p>Filter User:</p>
                <Select
                  size="medium"
                  dropdownMatchSelectWidth={false}
                  onChange={setSelectedUser}
                  value={selectedUser}
                  options={[{ value: false, label: "All Users" }, ...users]}
                />
              </div>
            ) : (
              <Spin size="large" />
            )}
          </div>
        </div>
      }
    >
      {parentData && childData ? (
        <div className="m-2">
          <div ref={chartDiv} className={`w-full h-96 ${chartClassName}`}></div>
          <div className="w-full flex flex-wrap items-center justify-center gap-5">
            {Object.entries(colors).map(([type, color], index) => (
              <div key={index} className="flex flex-row items-center gap-1.5">
                <div
                  className="w-4 h-4 rounded"
                  style={{ backgroundColor: color }}
                />
                {type}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div
          className={`flex flex-row justify-center items-center w-full h-96 m-2 ${chartClassName}`}
        >
          <Spin size="large" />
        </div>
      )}
    </Card>
  );
}
