import { Button } from "antd";
import Card from "../components/Card";
import { useNavigate } from "react-router-dom";

export default function NotFound(props) {
  const { error, guildId, screenHeight } = props;
  const navigate = useNavigate();

  return (
    <div
      className={`static ${
        screenHeight ? "min-h-screen" : "min-h-[100%]"
      } flex flex-col items-center justify-center bg-white dark:bg-dark-white`}
    >
      <Card className="flex flex-col gap-10 p-10">
        <div className="flex flex-col gap-3">
          <h1>Cannot access this page.</h1>
          <p>Reason: {error}</p>
        </div>
        <div className="flex flex-row-reverse gap-3">
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              if (guildId) navigate(`/${guildId}`);
              else navigate("/home");
            }}
          >
            Go Back
          </Button>
        </div>
      </Card>
    </div>
  );
}
