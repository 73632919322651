import { Button } from "antd";
import { useCallback, useContext } from "react";
import { SiteContext } from "../../../Contexts";
import APIManager from "../../../scripts/APIManager";
import Modal from "../../Modal";

export default function DeleteModal(props) {
  const { deletingReports, setDeletingReports, refreshList } = props;
  const { guildId, openNotification, activeModal, setActiveModal } =
    useContext(SiteContext);

  const removeReport = useCallback(
    async () =>
      await APIManager.sendRequest(
        "remove_report",
        {
          guild_id: guildId,
          ids: deletingReports.map(({ id }) => id),
          messages: deletingReports.map(({ message }) => message),
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.suggested)
          openNotification(
            "success",
            "Suggested",
            "Your change has been suggested."
          );
        else if (data.success) {
          openNotification(
            "success",
            "Submitted",
            "The incident has been deleted."
          );
        } else if (!data.success || data.message) {
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message
          );
        }
      }),
    [deletingReports, openNotification, guildId]
  );

  return (
    <Modal
      isActive={activeModal === "delete"}
      onClose={() => {
        setDeletingReports([]);
        setActiveModal(null);
      }}
      className="flex flex-col gap-10 max-w-sm p-10"
      header="Delete Report(s)"
    >
      <div className="flex flex-col gap-3 mx-2">
        {deletingReports ? (
          <p className="text-sm font-normal softer-text">
            You are deleting on {deletingReports.length} report
            {deletingReports.length === 1 ? "" : "s"}.
          </p>
        ) : null}
        <p className="text-sm softer-text">This action cannot be undone.</p>
      </div>
      <div className="flex flex-row-reverse gap-2 m-2">
        <Button
          type="primary"
          danger
          size="large"
          className="w-32 font-sans"
          onClick={() => {
            setActiveModal(null);
            removeReport().then(() => {
              setDeletingReports([]);
              refreshList(false);
            });
          }}
        >
          Confirm
        </Button>
        <Button
          size="large"
          className="w-32 font-sans"
          onClick={() => {
            setDeletingReports([]);
            setActiveModal(null);
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
