import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePicker, Skeleton } from "antd";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { SiteContext } from "../../Contexts";
import ActiveDayHour from "../../components/analytics/messages/ActiveDayHour";
import MessageStats from "../../components/analytics/messages/MessageStats";
import MessagesByAuthor from "../../components/analytics/messages/MessagesByAuthor";
import MessagesByChannel from "../../components/analytics/messages/MessagesByChannel";
import MessagesSent from "../../components/analytics/messages/MessagesSent";

export default function Messages() {
  const { guildId } = useContext(SiteContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    let today = new Date();
    let oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    setStartDate(oneWeekAgo);
    setEndDate(today);
  }, []);

  if (!startDate || !endDate)
    return <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <h1 className="softer-text">Messages</h1>
        <div className="flex flex-row items-center gap-3">
          <p>
            {Math.ceil(Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24))}{" "}
            Day
            {Math.ceil(
              Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24)
            ) === 1
              ? ""
              : "s"}
          </p>
          <DatePicker.RangePicker
            size="large"
            onChange={(_, [start, end]) => {
              setStartDate(start ? new Date(start) : null);
              setEndDate(end ? new Date(end) : null);
            }}
            defaultValue={[dayjs(startDate), dayjs(endDate)]}
            separator={
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-dark-gray-base"
              />
            }
            allowClear={false}
          />
        </div>
      </div>

      <div className="flex flex-col gap-3 items-stretch m-2">
        <div className="flex flex-row flex-wrap gap-3">
          <MessagesSent
            className="grow shrink-0 sm:basis-96"
            guildId={guildId}
            startDate={startDate}
            endDate={endDate}
          />
          <ActiveDayHour
            className="grow shrink-0 sm:basis-96"
            guildId={guildId}
            startDate={startDate}
            endDate={endDate}
          />
        </div>

        <div className="flex flex-row flex-wrap gap-3">
          <MessageStats
            className="grow shrink-0 basis-64"
            guildId={guildId}
            startDate={startDate}
            endDate={endDate}
          />
          <MessagesByAuthor
            className="grow shrink-0 basis-64"
            guildId={guildId}
            startDate={startDate}
            endDate={endDate}
          />
          <MessagesByChannel
            className="grow shrink-0 basis-64"
            guildId={guildId}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
    </>
  );
}
