import { Skeleton, Spin, Table, Tooltip } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { SiteContext } from "../../Contexts";
import Badge from "../../components/Badge";
import APIManager from "../../scripts/APIManager";

export default function ActionTracking() {
  const { guildId } = useContext(SiteContext);
  const [data, setData] = useState(null);
  const [dataCount, setDataCount] = useState(null);
  const [error, setError] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const requestData = useCallback(() => {
    APIManager.sendRequest(
      "get_action_tracking",
      {
        guild_id: guildId,
        page: pageNumber,
        page_size: pageSize,
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else {
        setData(data.actions.map((item, index) => ({ ...item, key: index })));
        setDataCount(data.count);
      }
    });
  }, [guildId, pageNumber, pageSize]);

  const columns = data
    ? [
        {
          title: "Offender",
          dataIndex: "offender",
          key: "offender",
          className: "hidden sm:table-cell",
          sorter: (item1, item2) =>
            item1.offender < item2.offender
              ? -1
              : item1.offender > item2.offender
              ? 1
              : 0,
          filters: data
            .filter(
              ({ offender }, index) =>
                data.map(({ offender }) => offender).indexOf(offender) === index
            )
            .map(({ offender }) => ({
              value: offender,
              text: offender,
            })),
          onFilter: (value, record) => record.offender === value,
          render: (offender) => (
            <div className="min-w-[80px]">
              <Badge className="max-w-[6rem]" tooltip>
                {offender}
              </Badge>
            </div>
          ),
        },
        {
          title: "Enforcer",
          dataIndex: "enforcer",
          key: "enforcer",
          className: "hidden sm:table-cell",
          sorter: (item1, item2) =>
            item1.user < item2.user ? -1 : item1.user > item2.user ? 1 : 0,
          filters: data
            .filter(
              ({ enforcer }, index) =>
                data.map(({ enforcer }) => enforcer).indexOf(enforcer) === index
            )
            .map(({ enforcer }) => ({
              value: enforcer,
              text: enforcer,
            })),
          onFilter: (value, record) => record.enforcer === value,
          render: (user) => (
            <div className="min-w-[80px]">
              <Badge className="max-w-[6rem]" tooltip>
                {user}
              </Badge>
            </div>
          ),
        },
        {
          title: "Message",
          dataIndex: "message",
          key: "message",
          render: (message) => (
            <Tooltip title={message}>
              <div className="max-w-sm max-w-sm font-mono sm:whitespace-nowrap text-ellipsis overflow-hidden sm:min-w-[50px]">
                {message}
              </div>
            </Tooltip>
          ),
        },
        {
          title: "Reason",
          dataIndex: "reasoning",
          key: "reasoning",
          className: "hidden sm:table-cell",
          render: (reason) => <div className="min-w-[50px]">{reason}</div>,
        },
        {
          title: "Actions",
          dataIndex: "action",
          key: "action",
          render: (action, report) =>
            report.false_positive ? (
              <Badge color="yellow">False Positive</Badge>
            ) : (
              <div className="flex flex-row flex-nowrap gap-1 min-w-[50px]">
                {action ? (
                  <Badge>
                    {action.charAt(0).toUpperCase() +
                      action.substring(1).toLowerCase()}
                  </Badge>
                ) : null}
                {report.delete ? <Badge>Delete</Badge> : null}
              </div>
            ),
          filters: [
            {
              text: "Ban",
              value: "ban",
            },
            {
              text: "Kick",
              value: "kick",
            },
            {
              text: "Delete",
              value: "delete",
            },
            {
              text: "Timeout",
              value: "timeout",
            },
            {
              text: "Warn",
              value: "warn",
            },
          ],
          onFilter: (value, record) =>
            record.action === value || (value === "delete" && record.delete),
        },
        {
          title: "Time",
          key: "time",
          dataIndex: "time",
          className: "hidden sm:table-cell",
          render: (time) =>
            time
              ? `${new Date(time * 1000).toLocaleTimeString("en-us", {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}`
              : "",
          sorter: (item1, item2) => item1.time - item2.time,
        },
      ]
    : [];

  useEffect(() => requestData(), [requestData]);

  if (error) return <h1>{error}</h1>;

  if (!data) return <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <h1 className="softer-text">Enforcement Actions Taken</h1>
      </div>

      {data ? (
        <Table
          className="w-full"
          tableLayout="auto"
          dataSource={data}
          columns={columns}
          pagination={{
            current: pageNumber,
            defaultPageSize: 10,
            pageSize,
            hideOnSinglePage: false,
            showSizeChanger: true,
            total: dataCount,
            onChange: (page, pageSize) => {
              setPageNumber(page);
              setPageSize(pageSize);
            },
          }}
          size="small"
        />
      ) : (
        <div className="flex flex-row justify-center items-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
}
