import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { Spin, Table } from "antd";
import Card from "../../components/Card";

export default function ActionsList(props) {
  const { className, data } = props;

  const columns = data
    ? [
        {
          title: "User",
          dataIndex: "user",
          key: "user",
          sorter: (item1, item2) =>
            item1.user < item2.user ? -1 : item1.user > item2.user ? 1 : 0,
          filters: data
            .filter(
              ({ user }, index) =>
                data.map(({ user }) => user).indexOf(user) === index
            )
            .map(({ user }) => ({
              value: user,
              text: user,
            })),
          onFilter: (value, record) => record.user === value,
        },
        {
          title: "Action",
          dataIndex: "action",
          key: "action",
          sorter: (item1, item2) =>
            item1.action < item2.action
              ? -1
              : item1.action > item2.action
              ? 1
              : 0,
          filters: data
            .filter(
              ({ action }, index) =>
                data.map(({ action }) => action).indexOf(action) === index
            )
            .map(({ action }) => ({
              value: action,
              text: action,
            })),
          onFilter: (value, record) => record.action === value,
        },
        {
          title: "Time",
          key: "time",
          dataIndex: "time",
          render: (time) =>
            time
              ? `${new Date(time * 1000).toLocaleTimeString("en-us", {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}`
              : "",
          sorter: (item1, item2) => item1.time - item2.time,
        },
      ]
    : [];

  return (
    <Card className={className} header={<h1>All Actions</h1>}>
      {data ? (
        <Table
          className="no-top"
          tableLayout="auto"
          dataSource={data}
          columns={columns}
          pagination={{ defaultPageSize: 25, hideOnSinglePage: true }}
          size="small"
          expandable={{
            expandIcon: ({ expandable, expanded, onExpand, record }) =>
              expandable && expanded ? (
                <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
              ) : expandable ? (
                <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
              ) : null,
            expandedRowRender: ({ data }) =>
              Object.entries(data).map(([key, value]) => (
                <p key={key}>
                  {key}: {value.toString()}
                </p>
              )),
          }}
        />
      ) : (
        <div className="flex flex-row justify-center items-center m-2">
          <Spin size="large" />
        </div>
      )}
    </Card>
  );
}
