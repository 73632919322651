import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { Spin } from "antd";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SiteContext } from "../../../Contexts";
import APIManager from "../../../scripts/APIManager";
import Card from "../../Card";
import Badge from "../../Badge";

export default function ReportBreakdown(props) {
  const { darkMode, guildId } = useContext(SiteContext);
  const { className, chartClassName, startDate, endDate, setError } = props;
  const [data, setData] = useState(null);
  const chartDiv = useRef(null);

  const colors = useMemo(
    () => ({
      Feedback: "#d84f40",
      Bug: "#8c30ab",
      Technical: "#4951af",
      "Help Request": "#65bad1",
    }),
    []
  );

  const requestBreakdown = useCallback(() => {
    APIManager.sendRequest(
      "get_summary_actions_breakdown",
      {
        guild_id: guildId,
        start_date: startDate ? startDate.toISOString() : null,
        end_date: endDate ? endDate.toISOString() : null,
      },
      true
    ).then((data) =>
      data.error
        ? setError(`error: ${data.error}`)
        : setData(
            data.breakdown.map(({ action, count }) => {
              return {
                action,
                count,
                sliceSettings: {
                  fill: am5.color(colors[action]),
                },
              };
            })
          )
    );
  }, [guildId, colors, setError, startDate, endDate]);

  useEffect(() => {
    if (!data) return;

    const root = am5.Root.new(chartDiv?.current);

    let themes = [am5themes_Animated.new(root)];
    if (darkMode) themes.push(am5themes_Dark.new(root));
    root.setThemes(themes);

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(90),
        innerRadius: am5.percent(60),
      })
    );

    // Create series
    var series = chart.series.push(
      am5percent.PieSeries.new(root, {
        name: "Series",
        valueField: "count",
        categoryField: "action",
        legendLabelText: "{action}",
        legendValueText: "{count}",
      })
    );

    // Configuring slices
    series.slices.template.setAll({
      stroke: am5.color(0xffffff),
      strokeWidth: 2,
      templateField: "sliceSettings",
    });

    series.slices.template.set("tooltipText", "{action}: {count}");

    series.data.setAll(data);

    // Disabling labels and ticks
    series.labels.template.set("visible", false);
    series.ticks.template.set("visible", false);

    return () => root.dispose();
  }, [darkMode, data, colors]);

  useEffect(() => {
    requestBreakdown();
  }, [requestBreakdown]);

  return (
    <Card
      className={className}
      header={
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row items-center gap-3">
            <h1>Action Categories</h1>{" "}
            {data ? (
              <Badge>
                {data
                  .map(({ count }) => count)
                  .reduce((sum, item) => sum + item, 0)}{" "}
                Action
                {data
                  .map(({ count }) => count)
                  .reduce((sum, item) => sum + item, 0) === 1
                  ? ""
                  : "s"}
              </Badge>
            ) : null}
          </div>
        </div>
      }
    >
      <div className="m-2">
        {data ? (
          <>
            <div
              ref={chartDiv}
              className={`w-full h-96 ${chartClassName}`}
            ></div>
            <div className="w-full flex flex-wrap items-center justify-center gap-5">
              {Object.entries(colors).map(([type, color], index) => (
                <div key={index} className="flex flex-row items-center gap-1.5">
                  <div
                    className="w-4 h-4 rounded"
                    style={{ backgroundColor: color }}
                  />
                  {type}
                </div>
              ))}
            </div>
          </>
        ) : (
          <div
            className={`flex flex-row justify-center items-center w-full h-96 ${chartClassName}`}
          >
            <Spin size="large" />
          </div>
        )}
      </div>
    </Card>
  );
}
