import { ConfigProvider, theme } from "antd";
import LandingFooter from "../../components/landing/LandingFooter";
import LandingHeader from "../../components/landing/LandingHeader";
import constants from "../../constants";
import aidan from "../../images/landing/profiles/aidan.png";
import jack from "../../images/landing/profiles/jack.png";
import navid from "../../images/landing/profiles/navid.png";

export default function AboutUs() {
  return (
    <div className="relative no-zoom">
      <div className="-z-10 absolute inset-0 bg-white" />
      <div className="sm:hidden -z-10 absolute w-full flex flex-row justify-center top-[50px]">
        <div className="landing-bg h-[400px] w-[400px]" />
      </div>
      <div className="sm:hidden -z-10 absolute w-full flex flex-row justify-center top-[1050px]">
        <div className="landing-bg h-[300px] w-[300px]" />
      </div>
      <div className="sm:hidden -z-10 absolute w-full flex flex-row justify-end top-[1550px]">
        <div className="landing-bg h-[300px] w-[300px]" />
      </div>

      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: constants.colors["secondary"],
          },
        }}
      >
        <div className="min-h-screen flex flex-col">
          <LandingHeader
            simple
            landing
            className="fixed box-border h-12 sm:h-16 w-full"
          />

          <div className="h-12 sm:h-0" />
          <div className="flex flex-col items-center gap-8 -mb-12 py-12 sm:pb-32 sm:pt-28 bg-[url('/src/images/landing/starbackground.png')] bg-cover sm:bg-gradient-to-tr from-[#00164F] via-[#000743] to-[#0024A1]">
            <div className="flex flex-col items-center gap-2">
              <p className="font-landing-header text-2xl text-black sm:text-white font-semibold sm:font-bold">
                About Us
              </p>
              <svg
                className="hidden sm:block"
                width="216"
                height="8"
                viewBox="0 0 216 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="4" cy="4" r="4" fill="#FFF3D1" />
                <circle cx="17" cy="4" r="4" fill="#FFF0C8" />
                <circle cx="30" cy="4" r="4" fill="#FFECB9" />
                <circle cx="43" cy="4" r="4" fill="#FFE7A4" />
                <circle cx="56" cy="4" r="4" fill="#FFE499" />
                <circle cx="69" cy="4" r="4" fill="#FFE396" />
                <circle cx="82" cy="4" r="4" fill="#FFE18F" />
                <circle cx="95" cy="4" r="4" fill="#FFDD7D" />
                <circle cx="108" cy="4" r="4" fill="#FFDB78" />
                <circle cx="121" cy="4" r="4" fill="#FFD766" />
                <circle cx="134" cy="4" r="4" fill="#FFD45C" />
                <circle cx="147" cy="4" r="4" fill="#FFD45C" />
                <circle cx="160" cy="4" r="4" fill="#FECF4C" />
                <circle cx="173" cy="4" r="4" fill="#FFCD42" />
                <circle cx="186" cy="4" r="4" fill="#FFC524" />
                <circle cx="199" cy="4" r="4" fill="#FFC21A" />
                <circle cx="212" cy="4" r="4" fill="#FFBC00" />
              </svg>
            </div>

            <p className="font-landing text-black sm:text-white text-center px-8 sm:px-32 sm:leading-7">
              We recognized the growing complexity of harnessing online
              communities and the need for scalable, efficient solutions. We
              came together as a team of engineers to develop an AI-powered
              platform to address those challenges. Our system analyzes large
              amounts of user data to deliver actionable insights and automate
              time-consuming moderation tasks. Our goal was to create a tool
              that streamlines community management, that both frees up time for
              managers to focus on relationship building and strengthens the
              bond between organizations and their communities. We leverage
              state-of-the-art machine learning techniques to identify trends,
              anticipate potential challenges, and provide data-driven
              recommendations to optimize engagement strategies. Our tool helps
              empower organizations to make informed product decisions and
              achieve greater customer engagement and satisfaction
            </p>
          </div>

          <div className="h-24" />

          <div
            className="flex flex-col items-center gap-8 sm:-mt-12 sm:pt-12 sm:-mb-16 sm:pb-16
          sm:bg-[url('/src/images/landing/animatedbackground2.gif')] sm:bg-cover"
          >
            <div className="flex flex-col items-center gap-12">
              <div className="flex flex-col items-center">
                <p className="font-landing-header text-3xl text-black font-semibold">
                  Meet Our Team
                </p>
                <svg
                  width="216"
                  height="8"
                  viewBox="0 0 216 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="4" cy="4" r="4" fill="#FFF3D1" />
                  <circle cx="17" cy="4" r="4" fill="#FFF0C8" />
                  <circle cx="30" cy="4" r="4" fill="#FFECB9" />
                  <circle cx="43" cy="4" r="4" fill="#FFE7A4" />
                  <circle cx="56" cy="4" r="4" fill="#FFE499" />
                  <circle cx="69" cy="4" r="4" fill="#FFE396" />
                  <circle cx="82" cy="4" r="4" fill="#FFE18F" />
                  <circle cx="95" cy="4" r="4" fill="#FFDD7D" />
                  <circle cx="108" cy="4" r="4" fill="#FFDB78" />
                  <circle cx="121" cy="4" r="4" fill="#FFD766" />
                  <circle cx="134" cy="4" r="4" fill="#FFD45C" />
                  <circle cx="147" cy="4" r="4" fill="#FFD45C" />
                  <circle cx="160" cy="4" r="4" fill="#FECF4C" />
                  <circle cx="173" cy="4" r="4" fill="#FFCD42" />
                  <circle cx="186" cy="4" r="4" fill="#FFC524" />
                  <circle cx="199" cy="4" r="4" fill="#FFC21A" />
                  <circle cx="212" cy="4" r="4" fill="#FFBC00" />
                </svg>
              </div>

              <div className="self-stretch flex flex-col sm:flex-row gap-8 px-12 sm:pr-0 pb-16 sm:pb-0 mt-[50px] sm:mt-0 bg-[#FFD86D]/50 sm:bg-transparent">
                <div className="w-72 shrink-0 flex flex-row sm:flex-col items-center justify-between sm:justify-start gap-8 sm:gap-0 -mt-[50px] sm:mt-0">
                  <img
                    src={aidan}
                    className="rounded-full scale-object-down h-[100px] sm:h-[150px] shadow"
                    alt="Aidan Kehoe"
                  />
                  <div className="flex flex-col justify-center h-[100px] gap-4 sm:gap-2 sm:w-[150px]">
                    <div className="basis-1/3 flex flex-col justify-end border-transparent">
                      <p className="font-landing-header text-black sm:text-primary-dark font-semibold sm:font-bold text-xl sm:text-2xl -mb-2 sm:-mb-1">
                        Aidan Kehoe
                      </p>
                    </div>
                    <div className="grow-0 shrink-0 hidden sm:block bg-[#00E0FF] h-[3px]" />
                    <div className="basis-1/3">
                      <p className="font-landing h-10 text-black">
                        CEO, Machine Learning Engineer
                      </p>
                    </div>
                  </div>
                </div>
                <p className="self-start font-landing text-black sm:bg-[#FFCE45] sm:p-16 sm:rounded-l-3xl">
                  "Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem accusantium doloremque laudantium, totam rem
                  aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                  architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                  voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                  sed quia consequuntur magni dolores eos qui ratione voluptatem
                  sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
                  quia dolor sit amet, consectetur, adipisci velit, sed quia non
                  numquam eius modi tempora
                </p>
              </div>

              <div className="self-stretch flex flex-col sm:flex-row-reverse gap-8 px-12 sm:pl-0 pb-16 sm:pb-0 mt-[50px] sm:mt-0 bg-[#FFD86D]/50 sm:bg-transparent">
                <div className="w-72 shrink-0 flex flex-row-reverse sm:flex-col items-center justify-between sm:justify-start gap-8 sm:gap-2 -mt-[50px] sm:mt-0">
                  <img
                    src={navid}
                    className="rounded-full scale-object-down h-[100px] sm:h-[150px] shadow"
                    alt="Navid Korhani"
                  />
                  <div className="flex flex-col justify-center h-[100px] gap-4 sm:gap-2 sm:w-[160px]">
                    <div className="basis-1/3 flex flex-col justify-end">
                      <p className="font-landing-header text-black sm:text-primary-dark font-semibold sm:font-bold text-xl sm:text-2xl -mb-2 sm:-mb-1">
                        Navid Korhani
                      </p>
                    </div>
                    <div className="grow-0 shrink-0 hidden sm:block bg-[#00E0FF] h-[3px]" />
                    <div className="basis-1/3">
                      <p className="font-landing h-10 text-black">
                        Machine Learning Engineer
                      </p>
                    </div>
                  </div>
                </div>
                <p className="self-start font-landing text-black sm:bg-[#FFCE45] sm:p-16 sm:rounded-r-3xl">
                  "Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem accusantium doloremque laudantium, totam rem
                  aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                  architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                  voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                  sed quia consequuntur magni dolores eos qui ratione voluptatem
                  sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
                  quia dolor sit amet, consectetur, adipisci velit, sed quia non
                  numquam eius modi tempora
                </p>
              </div>

              <div className="self-stretch flex flex-col sm:flex-row gap-8 px-12 sm:pr-0 pb-16 sm:pb-0 mt-[50px] sm:mt-0 bg-[#FFD86D]/50 sm:bg-transparent">
                <div className="w-72 shrink-0 flex flex-row sm:flex-col items-center justify-between sm:justify-start gap-8 sm:gap-2 -mt-[50px] sm:mt-0">
                  <img
                    src={jack}
                    className="rounded-full scale-object-down h-[100px] sm:h-[150px] shadow"
                    alt="Jack Hillier"
                  />
                  <div className="flex flex-col justify-center h-[100px] gap-4 sm:gap-2 sm:w-[150px]">
                    <div className="basis-1/3 flex flex-col justify-end">
                      <p className="font-landing-header text-black sm:text-primary-dark font-semibold sm:font-bold text-xl sm:text-2xl -mb-2 sm:-mb-1">
                        Jack Hillier
                      </p>
                    </div>
                    <div className="grow-0 shrink-0 hidden sm:block bg-[#00E0FF] h-[3px]" />
                    <div className="basis-1/3">
                      <p className="font-landing h-10 text-black">
                        Software Developer
                      </p>
                    </div>
                  </div>
                </div>
                <p className="self-start font-landing text-black sm:bg-[#FFCE45] sm:p-16 sm:rounded-l-3xl">
                  "Sed ut perspiciatis unde omnis iste natus error sit
                  voluptatem accusantium doloremque laudantium, totam rem
                  aperiam, eaque ipsa quae ab illo inventore veritatis et quasi
                  architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam
                  voluptatem quia voluptas sit aspernatur aut odit aut fugit,
                  sed quia consequuntur magni dolores eos qui ratione voluptatem
                  sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum
                  quia dolor sit amet, consectetur, adipisci velit, sed quia non
                  numquam eius modi tempora
                </p>
              </div>
            </div>

            {/* <div className="flex flex-col items-center gap-8 mt-12">
              <p className="font-landing-header text-2xl text-black font-semibold">
                Why we do what we do.
              </p>
              <p className="font-landing text-black text-center px-8 sm:px-32 sm:leading-7">
                Recognizing the increasing complexity of community management
                and the growing demand for efficient, scalable solutions, we
                united to develop an AI-powered platform. Frustrated by the
                time-consuming manual tasks and the potential for human error in
                traditional community management, they envisioned a system that
                could automate routine processes, analyze vast amounts of data,
                and provide actionable insights. Their goal was to create a tool
                that would not only streamline community management but also
                foster deeper connections between community members and
                organizations, ultimately enhancing overall community engagement
                and satisfaction.
              </p>
            </div> */}
          </div>

          <LandingFooter />
        </div>
      </ConfigProvider>
    </div>
  );
}
