import { useContext } from "react";
import { SiteContext } from "../../Contexts";
import ReportedUsers from "../../components/analytics/moderation/ReportedUsers";
import ReportsByRule from "../../components/analytics/moderation/ReportsByRule";

export default function Moderation() {
  const { guildId } = useContext(SiteContext);


  // Not updated with new margins and spacing
  
  return (
    <>
      <div className="flex flex-row justify-between items-center mx-6 my-2">
        <h1 className="softer-text">Moderation</h1>
      </div>
      <div className="flex flex-row flex-wrap gap-6">
        <ReportsByRule className="grow shrink-0 basis-96" guildId={guildId} />
        <ReportedUsers className="grow shrink-0 basis-32" guildId={guildId} />
      </div>
    </>
  );
}
