import { Button, Form, Input, Select } from "antd";
import Modal from "../../Modal";
import { useCallback, useContext } from "react";
import APIManager from "../../../scripts/APIManager";
import { SiteContext } from "../../../Contexts";

export default function SpotlightModals(props) {
  const {
    data,
    spotlightsToRecover,
    setSpotlightsToRecover,
    spotlightsToClear,
    setSpotlightsToClear,
    spotlightsToRemove,
    setSpotlightsToRemove,
    spotlightsToAction,
    setSpotlightsToAction,
    actionClassification,
    setActionClassification,
    idsToEmail,
    setIdsToEmail,
    disableEmailSend,
    setDisableEmailSend,
    emailReport,
    reportColumns,
    requestData,
    guildId,
  } = props;
  const { activeModal, setActiveModal, openNotification } =
    useContext(SiteContext);

  const sendReportEmail = useCallback(
    async (recipients, summary, items, report) =>
      (() => {
        const csvRows = report.map((row, rowIndex) =>
          row
            .map((item, columnIndex) =>
              ["startingtimestamp", "endingtimestamp", "timestamp"].includes(
                Object.values(reportColumns)[columnIndex]
              ) && rowIndex > 0
                ? `"${new Date(item).toLocaleTimeString("en-us", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}"`
                : Array.isArray(item)
                ? `"${item.join(", ")}"`
                : item
            )
            .join(",")
        );
        const csvString = csvRows.join("\n");
        return APIManager.sendRequest(
          "send_feedback_report_email",
          {
            guild_id: guildId,
            recipients,
            summary,
            items,
            report: csvString,
          },
          true
        );
      })().then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.success)
          openNotification(
            "success",
            "Email Sent",
            "The spotlight report has been emailed."
          );
        else
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message
          );
        return data.success;
      }),
    [guildId, openNotification, reportColumns]
  );

  const removeFeedbackSpotlight = useCallback(
    async (convs) =>
      APIManager.sendRequest(
        "remove_feedback_report",
        {
          guild_id: guildId,
          convs,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.suggested)
          openNotification("success", "Your change has been suggested");
        else if (data.success)
          openNotification(
            "success",
            "Trashed",
            "The spotlight(s) have been moved to trash."
          );
        else
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message
          );
        return data.success;
      }),
    [guildId, openNotification]
  );

  const recoverFeedbackSpotlight = useCallback(
    async (convs) =>
      APIManager.sendRequest(
        "recover_feedback_report",
        {
          guild_id: guildId,
          convs,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.suggested)
          openNotification("success", "Your change has been suggested");
        else if (data.success)
          openNotification(
            "success",
            "Recovered",
            "The spotlight(s) have been recovered."
          );
        else
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message
          );
        return data.success;
      }),
    [guildId, openNotification]
  );

  const clearFeedbackSpotlight = useCallback(
    async (convs) =>
      APIManager.sendRequest(
        "delete_feedback_report",
        {
          guild_id: guildId,
          convs,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.suggested)
          openNotification("success", "Your change has been suggested");
        else if (data.success)
          openNotification(
            "success",
            "Deleted",
            "The spotlight(s) have been deleted."
          );
        else
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message
          );
        return data.success;
      }),
    [guildId, openNotification]
  );

  const markFeedbackAsActioned = useCallback(
    async (convs, action) =>
      APIManager.sendRequest(
        "mark_feedback_as_actioned",
        {
          guild_id: guildId,
          convs,
          action,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.suggested)
          openNotification(
            "success",
            "Suggested",
            "Your change has been suggested."
          );
        else if (data.success)
          openNotification(
            "success",
            "Actioned",
            "The spotlight(s) have been marked as actioned."
          );
        else
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message
          );
        return data.success;
      }),
    [guildId, openNotification]
  );

  return (
    <>
      <Modal
        isActive={activeModal === "remove"}
        onClose={() => {
          setActiveModal(null);
          setSpotlightsToRemove(null);
        }}
        className="flex flex-col gap-2 max-w-sm p-10"
        header={
          spotlightsToRemove && spotlightsToRemove.length !== 1
            ? "Are you sure you would like to move these spotlights to trash?"
            : "Are you sure you would like to move this spotlight to trash?"
        }
      >
        <div className="flex flex-col gap-3 mx-2">
          {spotlightsToRemove ? (
            <p className="text-sm font-normal softer-text">
              You are trashing {spotlightsToRemove.length} spotlight
              {spotlightsToRemove.length === 1 ? "" : "s"}.
            </p>
          ) : null}
          <p className="text-sm font-normal softer-text">
            Spotlights can be recovered once they in the trash.
          </p>
        </div>
        <div className="flex flex-row-reverse gap-3 m-2">
          <Button
            type="primary"
            size="large"
            className="w-32 font-sans"
            danger
            onClick={() => {
              setActiveModal(null);
              removeFeedbackSpotlight(spotlightsToRemove).then((result) => {
                if (result) requestData();
                setSpotlightsToRemove(null);
              });
            }}
          >
            Confirm
          </Button>
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              setSpotlightsToRemove(null);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal
        isActive={activeModal === "recover"}
        onClose={() => {
          setActiveModal(null);
          setSpotlightsToRecover(null);
        }}
        className="flex flex-col gap-2 max-w-sm p-10"
        header={
          spotlightsToRecover && spotlightsToRecover.length !== 1
            ? "Are you sure you would like to recover these spotlights?"
            : "Are you sure you would like to recover this spotlight?"
        }
      >
        <div className="flex flex-col gap-3 mx-2">
          {spotlightsToRecover ? (
            <p className="text-sm font-normal softer-text">
              You are recovering {spotlightsToRecover.length} spotlight
              {spotlightsToRecover.length === 1 ? "" : "s"}.
            </p>
          ) : null}
        </div>
        <div className="flex flex-row-reverse gap-3 m-2">
          <Button
            type="primary"
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              recoverFeedbackSpotlight(spotlightsToRecover).then((result) => {
                if (result) requestData();
                setSpotlightsToRecover(null);
              });
            }}
          >
            Confirm
          </Button>
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              setSpotlightsToRecover(null);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal
        isActive={activeModal === "clear"}
        onClose={() => {
          setActiveModal(null);
          setSpotlightsToClear(null);
        }}
        className="flex flex-col gap-2 max-w-sm p-10"
        header={
          data && spotlightsToClear && spotlightsToClear.length === data.length
            ? "This will permanently delete all spotlights from the trash bin."
            : spotlightsToClear && spotlightsToClear.length !== 1
            ? "Are you sure you would like to delete these spotlights?"
            : "Are you sure you would like to delete this spotlight?"
        }
      >
        <div className="flex flex-col gap-3 mx-2">
          {spotlightsToClear ? (
            <p className="text-sm font-normal softer-text">
              You are deleting {spotlightsToClear.length} spotlight
              {spotlightsToClear.length === 1 ? "" : "s"}.
            </p>
          ) : null}
          {data &&
          spotlightsToClear &&
          spotlightsToClear.length === data.length ? null : (
            <p className="text-sm font-normal softer-text">
              This action cannot be undone.
            </p>
          )}
        </div>
        <div className="flex flex-row-reverse gap-3 m-2">
          <Button
            type="primary"
            size="large"
            className="w-32 font-sans"
            danger
            onClick={() => {
              setActiveModal(null);
              clearFeedbackSpotlight(spotlightsToClear).then((result) => {
                if (result) requestData();
                setSpotlightsToClear(null);
              });
            }}
          >
            Confirm
          </Button>
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              setSpotlightsToClear(null);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal
        isActive={activeModal === "action"}
        onClose={() => {
          setActiveModal(null);
          setSpotlightsToAction(null);
          setActionClassification(null);
        }}
        className="flex flex-col gap-2 max-w-sm p-10"
        header={`Are you sure you would like to mark 
            ${
              spotlightsToAction && spotlightsToAction.length !== 1
                ? "these spotlights"
                : "this spotlight"
            } 
            as actioned (${
              actionClassification ? actionClassification.toLowerCase() : " "
            })?`}
      >
        <div className="flex flex-col gap-3 mx-2">
          {spotlightsToAction ? (
            <p className="text-sm font-normal softer-text">
              You are actioning {spotlightsToAction.length} spotlight
              {spotlightsToAction.length === 1 ? "" : "s"}.
            </p>
          ) : null}
          <p className="text-sm font-normal softer-text">
            You can recover spotlights once they are deleted.
          </p>
        </div>
        <div className="flex flex-row-reverse gap-3 m-2">
          <Button
            disabled={actionClassification ? false : true}
            type="primary"
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              markFeedbackAsActioned(
                spotlightsToAction,
                actionClassification
              ).then((result) => {
                if (result) requestData();
                setSpotlightsToAction(null);
                setActionClassification(null);
              });
            }}
          >
            Confirm
          </Button>
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              setSpotlightsToAction(null);
              setActionClassification(null);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal
        isActive={activeModal === "email"}
        onClose={() => {
          setActiveModal(null);
          setIdsToEmail(null);
        }}
        className="flex flex-col gap-2 max-w-xl w-full p-10 m-5"
        header="Create spotlight report"
      >
        <Form
          className="flex flex-col items-stretch font-sans mx-2 mb-2"
          name="basic"
          initialValues={{ remember: true }}
          onFinish={({ recipients, summary }) => {
            setDisableEmailSend(true);
            sendReportEmail(recipients, summary, idsToEmail, emailReport).then(
              (success) => {
                if (success) {
                  setActiveModal(null);
                  setIdsToEmail(null);
                }
                setDisableEmailSend(false);
              }
            );
          }}
          autoComplete="off"
        >
          <div className="flex flex-col gap-3">
            <p className="text-sm font-normal softer-text">
              You are creating a report for {idsToEmail ? idsToEmail.length : 0}{" "}
              items.
            </p>
            <div className="flex flex-col gap-1 pb-2">
              <p>Recipients:</p>
              <Form.Item
                name="recipients"
                rules={[
                  { required: true, message: "Please choose recipients" },
                ]}
                className="max-w-none"
              >
                <Select
                  name="recipients"
                  mode="tags"
                  size="large"
                  placeholder="Recipients"
                />
              </Form.Item>
              <p>Summary:</p>
              <Form.Item
                name="summary"
                rules={[{ required: true, message: "Please enter a summary" }]}
              >
                <Input size="large" placeholder="Summary" />
              </Form.Item>
            </div>
          </div>
          <div className="flex flex-row-reverse gap-3">
            <Form.Item className="m-0">
              <Button
                disabled={disableEmailSend}
                type="primary"
                htmlType="submit"
                size="large"
                className="w-32 font-sans"
              >
                Send
              </Button>
            </Form.Item>
            <Button
              size="large"
              className="w-32 font-sans"
              onClick={() => {
                setActiveModal(null);
                setIdsToEmail(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}
