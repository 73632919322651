import { Button, Checkbox } from "antd";
import { useCallback, useState } from "react";
import APIManager from "../scripts/APIManager";
import AuthManager from "../scripts/AuthManager";
import Modal from "./Modal";

export default function AgreementModal(props) {
  const { tosActive, setTosActive, username, password, email, setResult } =
    props;
  const [agreed, setAgreed] = useState(false);

  const resetAgreement = useCallback(() => setAgreed(false), []);

  return (
    <Modal
      clickOff={false}
      isActive={tosActive}
      className="w-[1000px] h-full box-border"
      onOpen={resetAgreement}
    >
      <object
        data={APIManager.getEndpointURL("static/ML56_SaaS_Agreement.pdf")}
        type="application/pdf"
        width="100%"
        height="100%"
      >
        <p>
          <a href={APIManager.getEndpointURL("static/ML56_SaaS_Agreement.pdf")}>
            Link to Terms of Service
          </a>
        </p>
      </object>
      <Checkbox
        onChange={({ target }) => setAgreed(target.checked)}
        checked={agreed}
      >
        By accessing the software, you hereby agree to the terms and conditions
        provided herein.
      </Checkbox>
      <div className="flex flex-row-reverse gap-3">
        <Button
          type="primary"
          size="large"
          className="w-32 font-sans"
          disabled={!agreed}
          onClick={() => {
            if (agreed) {
              AuthManager.signUp(username, password, email, setResult);
              setTosActive(false);
            }
          }}
        >
          Continue
        </Button>
        <Button
          size="large"
          className="w-32 font-sans"
          onClick={() => {
            setTosActive(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
