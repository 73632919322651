import { DownOutlined } from "@ant-design/icons";
import {
  faBell,
  faClipboard,
  faTrashAlt,
} from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRight,
  faFileArrowDown,
  faGear,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Dropdown,
  Select,
  Space,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import constants from "../../../constants";
import { SiteContext } from "../../../Contexts";
import APIManager from "../../../scripts/APIManager";
import Badge from "../../Badge";
import BatchUpdateModal from "./BatchUpdateModal";

export default function SpotlightsCard(props) {
  const {
    children,
    activeTab,
    setActiveTab,
    activeTypeTab,
    setActiveTypeTab,
    feedbackTypes,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    dataCount,
    retrievingData,
    selectedItems,
    setSpotlightsToClear,
    setSpotlightsToRecover,
    setActionClassification,
    setSpotlightsToAction,
    setSpotlightsToRemove,
    spotlightActions,
    setIdsToEmail,
    data,
    guildId,
  } = props;
  const { setActiveModal, openNotification, permissions } =
    useContext(SiteContext);
  const [pageReport] = useState(null);
  const [lastProcessed, setLastProcessed] = useState(null);
  const [processInterval, setProcessInterval] = useState(null);

  const requestBatchInfo = useCallback(() => {
    APIManager.sendRequest(
      "get_batch_info",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      if (!data.error) {
        if (data.last_processed) {
          const year = parseInt(data.last_processed.substring(0, 4), 10);
          const month = parseInt(data.last_processed.substring(4, 6), 10) - 1;
          const day = parseInt(data.last_processed.substring(6, 8), 10);
          const hours = parseInt(data.last_processed.substring(9, 11), 10);
          const minutes = parseInt(data.last_processed.substring(12, 14), 10);
          const seconds = parseInt(data.last_processed.substring(15, 17), 10);

          const dateObject = new Date(
            Date.UTC(year, month, day, hours, minutes, seconds)
          );

          setLastProcessed(dateObject);
        }
        setProcessInterval(data.time_interval);
      }
    });
  }, [guildId]);

  const sendProcessBatchRequest = useCallback(
    () =>
      APIManager.sendRequest(
        "process_batch",
        {
          guild_id: guildId,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.success)
          openNotification(
            "success",
            "Processing",
            "The next batch is processing."
          );
        else
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message
          );
        return data.success;
      }),
    [guildId, openNotification]
  );

  // Get the last processed time and interval of batching
  useEffect(() => requestBatchInfo(), [requestBatchInfo]);

  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="flex flex-row justify-between items-center">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: constants.colors.primary["light-blue"],
                fontFamily: constants.fonts["sans-header"][0],
              },
            }}
          >
            <Tabs
              tabBarExtraContent={
                <div className="flex flex-row items-center gap-2 mr-2">
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: constants.colors.secondary,
                        fontFamily: constants.fonts["sans-header"],
                      },
                    }}
                  >
                    <Select
                      className="w-48"
                      size="large"
                      value={activeTypeTab}
                      onChange={(value) => setActiveTypeTab(value)}
                      options={[
                        { value: "All", label: "All" },
                        ...feedbackTypes,
                      ]}
                    />
                    <Space.Compact size="large" className="hidden sm:flex">
                      <Tooltip
                        title={
                          lastProcessed
                            ? `Last processed ${lastProcessed.toLocaleTimeString(
                                "en-us",
                                {
                                  weekday: "short",
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  timeZoneName: "short",
                                }
                              )}`
                            : null
                        }
                      >
                        <Button
                          className="font-sans flex flex-row items-center"
                          icon={<FontAwesomeIcon icon={faRotate} />}
                          onClick={sendProcessBatchRequest}
                          disabled={
                            !permissions?.["Edit Spotlight Processing"] &&
                            !permissions?.["Suggest Analytics Edits"]
                          }
                        >
                          <span className="font-sans ml-2">Process</span>
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          processInterval ? (
                            <span>
                              {processInterval[0] || processInterval[1]
                                ? "Message batches will be processed every "
                                : null}
                              {processInterval[0] ? (
                                <span>
                                  {processInterval[0]} hour
                                  {processInterval[0] === 1 ? "" : "s"}
                                </span>
                              ) : null}
                              {processInterval[0] && processInterval[1]
                                ? " and "
                                : null}
                              {processInterval[1] ? (
                                <span>
                                  {processInterval[1]} minute
                                  {processInterval[1] === 1 ? "" : "s"}
                                </span>
                              ) : null}
                              {processInterval[0] || processInterval[1]
                                ? "."
                                : null}
                            </span>
                          ) : null
                        }
                      >
                        <Button
                          icon={<FontAwesomeIcon icon={faGear} />}
                          onClick={() => setActiveModal("update_batch")}
                          disabled={
                            !permissions?.["Edit Spotlight Processing"] &&
                            !permissions?.["Suggest Analytics Edits"]
                          }
                        ></Button>
                      </Tooltip>
                    </Space.Compact>
                  </ConfigProvider>
                </div>
              }
              items={[
                {
                  label: (
                    <span className="flex flex-row items-center gap-3 p-2">
                      <FontAwesomeIcon size="lg" icon={faBell} /> Outstanding
                    </span>
                  ),
                  key: "Outstanding",
                },
                {
                  label: (
                    <span className="flex flex-row items-center gap-3 p-2">
                      <FontAwesomeIcon size="lg" icon={faClipboard} /> Actioned
                    </span>
                  ),
                  key: "Actioned",
                },
                {
                  label: (
                    <span className="flex flex-row items-center gap-3 p-2">
                      <FontAwesomeIcon size="lg" icon={faTrashAlt} /> Trash
                    </span>
                  ),
                  key: "Trash",
                },
              ]}
              onTabClick={(value) => {
                setActiveTab(value);
              }}
              className="w-full softer-text"
            />
          </ConfigProvider>
          {
            false && data && pageReport ? (
              <CSVLink
                data={pageReport ? pageReport : []}
                // data={[
                //   //columnKeys.filter((key) => key !== "Remove"),
                //   ...data
                //     .filter(filter)
                //     .map((item) =>
                //       columnKeys
                //         .filter((key) => key !== "Remove")
                //         .map((key) => item[key])
                //     ),
                //   // ...data.map(async ({ conversationid }) =>
                //   //   getFeedbackMessages(conversationid).then((results) => results)
                //   // ),
                // ]}
                filename="feedback_report.csv"
                target="_blank"
              >
                <Button
                  size="large"
                  type="link"
                  icon={
                    <FontAwesomeIcon className="px-2" icon={faFileArrowDown} />
                  }
                  className="font-sans -my-3"
                >
                  Export to CSV
                </Button>
              </CSVLink>
            ) : null // <Spin />
          }
        </div>
        <div className="flex flex-col sm:flex-row justify-between sm:items-center gap-3 mx-2">
          <div className="flex flex-row items-center gap-6">
            <div className="flex flex-row items-center gap-2 softer-text">
              <p>
                {selectedItems.length} Item
                {selectedItems.length === 1 ? "" : "s"} Selected:
              </p>
              {activeTab !== "Trash" ? (
                <>
                  <Tooltip
                    title={
                      permissions?.["Action Spotlights"] ||
                      permissions?.["Suggest Analytics Edits"]
                        ? null
                        : "You do not have permission."
                    }
                  >
                    <div>
                      <Dropdown
                        trigger={["click"]}
                        disabled={
                          selectedItems.length === 0 ||
                          (!permissions?.["Action Spotlights"] &&
                            !permissions?.["Suggest Analytics Edits"])
                        }
                        menu={{
                          items: spotlightActions,
                          onClick: ({ key }) => {
                            if (key === "Trash") {
                              setSpotlightsToRemove(
                                selectedItems.map(
                                  ({ conversationid, headline }) => ({
                                    id: conversationid,
                                    name: headline,
                                  })
                                )
                              );
                              setActiveModal("remove");
                            } else {
                              setSpotlightsToAction(
                                selectedItems.map(
                                  ({ conversationid, headline }) => ({
                                    id: conversationid,
                                    name: headline,
                                  })
                                )
                              );
                              setActionClassification(key);
                              setActiveModal("action");
                            }
                          },
                        }}
                      >
                        <Button
                          className="font-sans"
                          disabled={
                            !permissions?.["Action Spotlights"] &&
                            !permissions?.["Suggest Analytics Edits"]
                          }
                        >
                          Action <DownOutlined />
                        </Button>
                      </Dropdown>
                    </div>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip
                    title={
                      permissions?.["Action Spotlights"] ||
                      permissions?.["Suggest Analytics Edits"]
                        ? null
                        : "You do not have permission."
                    }
                  >
                    <div>
                      <Button
                        disabled={
                          selectedItems.length === 0 ||
                          (!permissions?.["Action Spotlights"] &&
                            !permissions?.["Suggest Analytics Edits"])
                        }
                        onClick={() => {
                          setSpotlightsToRecover(
                            selectedItems.map(
                              ({ conversationid, headline }) => ({
                                id: conversationid,
                                name: headline,
                              })
                            )
                          );
                          setActiveModal("recover");
                        }}
                      >
                        Recover
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={
                      permissions?.["Action Spotlights"] ||
                      permissions?.["Suggest Analytics Edits"]
                        ? null
                        : "You do not have permission."
                    }
                  >
                    <div>
                      <Button
                        disabled={
                          selectedItems.length === 0 ||
                          (!permissions?.["Action Spotlights"] &&
                            !permissions?.["Suggest Analytics Edits"])
                        }
                        onClick={() => {
                          setSpotlightsToClear(
                            selectedItems.map(
                              ({ conversationid, headline }) => ({
                                id: conversationid,
                                name: headline,
                              })
                            )
                          );
                          setActiveModal("clear");
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={
                      permissions?.["Action Spotlights"] ||
                      permissions?.["Suggest Analytics Edits"]
                        ? null
                        : "You do not have permission."
                    }
                  >
                    <div>
                      <Button
                        disabled={
                          !data ||
                          data.length === 0 ||
                          (!permissions?.["Action Spotlights"] &&
                            !permissions?.["Suggest Analytics Edits"])
                        }
                        onClick={() => {
                          setSpotlightsToClear(
                            data.map(({ conversationid, headline }) => ({
                              id: conversationid,
                              name: headline,
                            }))
                          );
                          setActiveModal("clear");
                        }}
                      >
                        Empty Trash
                      </Button>
                    </div>
                  </Tooltip>
                </>
              )}
              <Button
                disabled={selectedItems.length === 0}
                className="font-sans"
                onClick={() => {
                  setIdsToEmail(
                    selectedItems.map(({ conversationid }) => conversationid)
                  );
                  setActiveModal("email");
                }}
              >
                Create Report
              </Button>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row sm:items-center gap-2">
            {!retrievingData ? (
              dataCount !== null ? (
                <div className="flex flex-row items-center gap-1">
                  <Badge color="yellow">
                    {dataCount} spotlight
                    {dataCount === 1 ? "" : "s"}
                  </Badge>{" "}
                  <p className="text-sm softer-text">
                    in {activeTab} - {activeTypeTab}
                  </p>
                </div>
              ) : null
            ) : (
              <Spin />
            )}

            {startDate !== null && endDate !== null ? (
              <DatePicker.RangePicker
                size="large"
                className="sm:-my-3"
                onChange={(_, [start, end]) => {
                  setStartDate(start ? dayjs(start) : undefined);
                  setEndDate(end ? dayjs(end) : undefined);
                }}
                value={[startDate ? startDate : null, endDate ? endDate : null]}
                separator={
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="text-dark-gray-base"
                  />
                }
              />
            ) : (
              <Spin />
            )}
          </div>
        </div>
        {children}
      </div>

      <BatchUpdateModal
        guildId={guildId}
        processInterval={processInterval || [3, 0]}
      />
    </>
  );
}
