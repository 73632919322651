import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Skeleton, Tooltip } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { AccountContext, SiteContext } from "../../Contexts";
import Card from "../../components/Card";
import Modal from "../../components/Modal";
import ChannelsCard from "../../components/moderation/ChannelsCard";
import EnforcementCard from "../../components/moderation/EnforcementCard";
import LogCard from "../../components/moderation/LogCard";
import RolesCard from "../../components/moderation/RolesCard";
import APIManager from "../../scripts/APIManager";

export default function UniversalRulePage() {
  const { signedIn } = useContext(AccountContext);
  const {
    guildId,
    activeModal,
    setActiveModal,
    refreshRolesImmediately,
    setRefreshRolesImmediately,
    permissions,
  } = useContext(SiteContext);
  const { openNotification, closeNotification } = useContext(SiteContext);
  const [modified, setModified] = useState(null);
  const [rule, setRule] = useState(null);
  const [universalId, setUniversalId] = useState(null);
  const [conflict, setConflict] = useState(false);
  const [changes, setChanges] = useState(false);
  const [originalRule, setOriginalRule] = useState(null);
  const [error, setError] = useState(null);

  const requestRule = useCallback(() => {
    APIManager.sendRequest(
      "get_universal_rule",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else {
        setOriginalRule(data.rule);
        setUniversalId(data.universal_id);
      }
    });
    setModified(null);
    closeNotification("key");
  }, [guildId, closeNotification]);

  const checkRule = useCallback(() => {
    APIManager.sendRequest(
      "check_rule_changes",
      {
        guild_id: guildId,
        id: universalId,
        rule: rule,
        original_rule: originalRule,
      },
      true
    ).then((data) => {
      if (data.error) {
        openNotification(
          data.warning ? "warning" : "error",
          data.warning ? "Warning" : "An Error has Occured",
          data.error
        );
      } else if (!data.success || data.message) {
        openNotification(
          data.warning ? "warning" : "error",
          data.warning ? "Warning" : "An Error has Occured",
          data.message
        );
      } else {
        if (data.compatible === false) {
          setConflict(true);
        } else {
          setConflict(false);
        }
        if (data.changes) {
          if (!changes)
            openNotification(
              "warning",
              "Outdated Config",
              "You must reload the page to see new changes.",
              0,
              "key"
            );
          setChanges(true);
        }
      }
    });
  }, [changes, guildId, openNotification, originalRule, rule, universalId]);

  const sendRule = useCallback(() => {
    APIManager.sendRequest(
      "set_rule",
      {
        guild_id: guildId,
        id: universalId,
        rule: rule,
        original_rule: originalRule,
        suggested: false,
        universal: true,
      },
      true
    ).then((data) => {
      if (data.error)
        openNotification(
          data.warning ? "warning" : "error",
          data.warning ? "Warning" : "An Error has Occured",
          data.error
        );
      else if (data.suggested)
        openNotification(
          "success",
          "Suggested",
          "Your change has been suggested."
        );
      else if (data.success) {
        setModified(false);
        openNotification(
          "success",
          "Saved",
          "Your configuration has been saved."
        );
        requestRule();
      } else if (!data.success || data.message)
        openNotification(
          data.warning ? "warning" : "error",
          data.warning ? "Warning" : "An Error has Occured",
          data.message
        );
    });
  }, [rule, openNotification, guildId, originalRule, universalId, requestRule]);

  useEffect(() => {
    if (rule !== null) {
      setModified((modified) => (modified === null ? false : true));
    }
  }, [rule]);

  useEffect(() => {
    if (modified) {
      checkRule();
    }
  }, [modified, rule, checkRule]);

  useEffect(() => {
    setRule(originalRule);
  }, [originalRule, setRule]);

  useEffect(() => {
    if (signedIn) requestRule();
  }, [signedIn, requestRule]);

  if (error) return <h1>{error}</h1>;

  if (!rule)
    return <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <h1 className="softer-text">Universal Rule Configuration</h1>
      </div>

      <div className="flex flex-col gap-3 items-stretch m-2">
        {rule.channel_setting ? (
          <ChannelsCard
            className="shrink-0 max-w-full box-border"
            rule={rule}
            setRule={setRule}
            guildId={guildId}
            isUniversal={true}
          />
        ) : null}

        <div className="flex flex-row flex-wrap gap-3 w-full">
          <LogCard
            className="grow shrink-0 basis-64 max-w-full box-border"
            rule={rule}
            setRule={setRule}
            guildId={guildId}
            isUniversal={true}
          />
          {rule.role_setting ? (
            <RolesCard
              disabled={false}
              className="grow shrink-0 basis-64 max-w-full box-border"
              rule={rule}
              setRule={setRule}
              guildId={guildId}
              refreshDict={{
                refreshRolesImmediately,
                setRefreshRolesImmediately,
                activeModal,
              }}
              isUniversal={true}
            />
          ) : null}
        </div>

        {rule.enforcement_setting ? (
          <EnforcementCard
            className="shrink-0 max-w-full box-border"
            rule={rule}
            guildId={guildId}
            refreshDict={{
              refreshRolesImmediately,
              setRefreshRolesImmediately,
              activeModal,
            }}
            setRule={setRule}
            isUniversal={true}
          />
        ) : null}
      </div>

      <Modal
        isActive={activeModal === "save"}
        onClose={() => setActiveModal(null)}
        className="flex flex-col gap-10 max-w-sm p-10"
      >
        <div className="flex flex-col gap-3">
          <h1>Are you sure you would like to make these changes?</h1>
        </div>
        <div className="flex flex-row-reverse gap-3">
          <Button
            type="primary"
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              sendRule();
            }}
          >
            Confirm
          </Button>
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => setActiveModal(null)}
          >
            Cancel
          </Button>
        </div>
      </Modal>

      {!(modified === true ? false : true) &&
      JSON.stringify(rule) !== JSON.stringify(originalRule) ? (
        <>
          <Card className="fixed flex flex-row justify-center w-fit left-0 right-0 bottom-0 mb-6 mx-auto">
            <div className="flex flex-row items-center gap-6">
              <div className="flex flex-col gap-1">
                <p>Save your changes</p>
                {conflict ? (
                  <div className="flex flex-row items-center gap-1">
                    <FontAwesomeIcon
                      className="text-red-dark"
                      icon={faCircleExclamation}
                      size="sm"
                    />
                    <p className="text-xs max-w-xs text-red-dark">
                      Conflicting changes (refresh to fix)
                    </p>
                  </div>
                ) : null}
              </div>
              <Tooltip
                title={
                  permissions?.["Edit Universal Rule"] ||
                  permissions?.["Suggest Moderation Edits"]
                    ? null
                    : "You do not have permission."
                }
                placement="top"
              >
                <div>
                  <Button
                    type="primary"
                    size="large"
                    className="w-32 font-sans"
                    onClick={() => setActiveModal("save")}
                    disabled={
                      (modified === true ? false : true) ||
                      (!permissions?.["Edit Universal Rule"] &&
                        !permissions?.["Suggest Moderation Edits"])
                    }
                  >
                    Save
                  </Button>
                </div>
              </Tooltip>
            </div>
          </Card>
          <div className="h-16"></div>
        </>
      ) : null}
    </>
  );
}
