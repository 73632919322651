import { useContext } from "react";
import { SiteContext } from "../../Contexts";
import BugReports from "../../components/analytics/bug_reports/ReportList";

export default function DisplayReports() {
  const { guildId } = useContext(SiteContext);

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <h1 className="softer-text">Feedback Spotlights</h1>
      </div>
      <BugReports guildId={guildId} />
    </>
  );
}
