import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import APIManager from "./APIManager";

/**
 * A manager for all authentication-related actions
 */
export default class AuthManager {
  static user = null;
  static username = null;
  static setUsername = () => {};
  static refreshTokenValue = null;
  static setRefreshToken = () => {};
  static accessToken = null;
  static accessTokenExp = null;
  static signOut = () => {};
  static setSignedIn = () => {};
  static userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
  });

  static signUp = (username, password, email, setResult) => {
    var attributeList = [];

    var dataEmail = {
      Name: "email",
      Value: email,
    };
    var attributeEmail = new CognitoUserAttribute(dataEmail);

    attributeList.push(attributeEmail);

    this.userPool.signUp(username, password, attributeList, null, (err) => {
      if (err) {
        setResult(err.message || JSON.stringify(err));
        return;
      }
      setResult("verify");
    });
  };

  static signIn = (
    username,
    password,
    setResult,
    register = async () => {}
  ) => {
    var userData = {
      Username: username,
      Pool: this.userPool,
    };
    let localSetUsername = (username) => {
      this.setUsername(username);
      this.username = username;
    };
    let localSetRefreshToken = (refreshToken) => {
      this.setRefreshToken(refreshToken);
      this.refreshToken = refreshToken;
    };
    let localSetAccessToken = (accessToken) => {
      this.accessToken = accessToken.getJwtToken();
      this.accessTokenExp = accessToken.getExpiration();
    };
    var cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(
      new AuthenticationDetails({
        Username: username,
        Password: password,
      }),
      {
        onSuccess: function (result) {
          localSetAccessToken(result.getAccessToken());
          localSetUsername(result.getAccessToken().payload.username);
          localSetRefreshToken(result.getRefreshToken().getToken());
          register(
            this.accessToken,
            username,
            cognitoUser.getSignInUserSession().getIdToken().payload.email
          ).then(() => setResult("success"));
        },

        onFailure: (error) => {
          if (error.code === "UserNotConfirmedException") setResult("verify");
          else if (error.code === "NotAuthorizedException")
            setResult("Incorrect username or password");
          else setResult(error.message || JSON.stringify(error));
        },
      }
    );
  };

  static verify = (username, password, code, setResult) => {
    var userData = {
      Username: username,
      Pool: this.userPool,
    };

    var cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }

      this.signIn(
        username,
        password,
        setResult,
        async (accessToken, username, email) =>
          APIManager.sendRequest(
            "register_account",
            {
              email,
              username,
            },
            true
          )
      );
    });
  };

  static refreshToken = (setNull = false) => {
    var userData = {
      Username: this.username,
      Pool: this.userPool,
    };
    var cognitoUser = new CognitoUser(userData);
    var token = new CognitoRefreshToken({
      RefreshToken: this.refreshTokenValue,
    });

    if (setNull) this.accessToken = null;

    cognitoUser.refreshSession(token, (err, session) => {
      if (err) {
        if (
          ["UserNotFoundException", "NotAuthorizedException"].includes(err.code)
        )
          this.signOut();
      } else {
        this.user = cognitoUser;
        this.accessToken = cognitoUser
          .getSignInUserSession()
          .getAccessToken()
          .getJwtToken();
        this.accessTokenExp = cognitoUser
          .getSignInUserSession()
          .getAccessToken()
          .getExpiration();
        this.setSignedIn(true);
      }
    });
  };

  static resetPassword = (username, setResult) => {
    var userData = {
      Username: username,
      Pool: this.userPool,
    };
    var cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: () => setResult("verify"),
      onFailure: (err) => setResult(err.message),
    });
  };

  static confirmPassword = (
    username,
    verificationCode,
    newPassword,
    setResult
  ) => {
    var userData = {
      Username: username,
      Pool: this.userPool,
    };
    var cognitoUser = new CognitoUser(userData);

    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onFailure: (err) => setResult(err.message),
        onSuccess: () => setResult("success"),
      });
    });
  };
}
