import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Skeleton, Table } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SiteContext } from "../../../Contexts";
import APIManager from "../../../scripts/APIManager";
import Badge from "../../Badge";

export default function IssueList(props) {
  const { openNotification } = useContext(SiteContext);
  const { guildId } = props;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  let navigate = useNavigate();

  const requestData = useCallback(() => {
    APIManager.sendRequest(
      "get_top_issues",
      {
        guild_id: guildId,
      },
      true
    ).then((data) =>
      data.error
        ? setError(`error: ${data.error}`)
        : data.top_issues
        ? setData(
            data.top_issues.cluster.data.map((item, index) => ({
              ...item,
              key: index,
              spotlights: item.spotlights.map((item, index) => ({
                ...item,
                key: index,
              })),
            }))
          )
        : setData([])
    );
  }, [guildId]);

  const sendProcessClustersRequest = useCallback(
    () =>
      APIManager.sendRequest(
        "process_clusters",
        {
          guild_id: guildId,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.success)
          openNotification(
            "success",
            "Processing",
            "The clusters are being processed."
          );
        else
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message
          );
        return data.success;
      }),
    [guildId, openNotification]
  );

  useEffect(() => requestData(), [requestData]);

  const columns = data
    ? [
        Table.EXPAND_COLUMN,
        {
          title: "Name",
          dataIndex: "text",
          key: "text",
          render: (text) => (
            <div className="break-words min-w-[70px] max-w-md">
              <div className="inline font-medium text-base">{text}</div>
            </div>
          ),
          sorter: (item1, item2) =>
            item1.text < item2.text ? -1 : item1.text > item2.text ? 1 : 0,
        },
        {
          title: "Spotlights",
          dataIndex: "spotlights",
          key: "spotlights",
          width: 120,
          render: (text) => <div className="text-base">{text.length}</div>,
          sorter: (item1, item2) =>
            item2.spotlights.length - item1.spotlights.length,
        },
      ]
    : [];

  const messageColumns = [
    {
      title: "Headline",
      dataIndex: "headline",
      key: "headline",
      render: (text) => (
        <div className="break-words min-w-[70px] max-w-md">
          <div className="inline font-medium text-base">{text}</div>
        </div>
      ),
      sorter: (item1, item2) =>
        item1.text < item2.text ? -1 : item1.text > item2.text ? 1 : 0,
    },
    {
      title: "Time",
      key: "timestamp",
      dataIndex: "timestamp",
      render: (time) =>
        time
          ? `${new Date(time).toLocaleTimeString("en-us", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}`
          : "",
      sorter: (item1, item2) => item1.Timestamp - item2.Timestamp,
    },
  ];

  return (
    <>
      <div className="flex flex-row justify-between items-center m-2 mt-0">
        <div className="flex flex-row items-center gap-3">
          {data ? (
            <Badge>
              {data.length} issue
              {data.length === 1 ? "" : "s"}
            </Badge>
          ) : null}
        </div>
        <Button
          className="font-sans flex flex-row items-center gap-2"
          icon={<FontAwesomeIcon icon={faRotate} />}
          size="large"
          onClick={sendProcessClustersRequest}
        >
          Process
        </Button>
      </div>
      {data ? (
        <Table
          tableLayout="auto"
          dataSource={data}
          columns={columns}
          pagination={{
            defaultPageSize: 10,
            hideOnSinglePage: false,
            showSizeChanger: true,
          }}
          size="small"
          expandable={{
            expandedRowKeys: expandedRows,
            expandIcon: ({ expandable, expanded, onExpand, record }) =>
              expandable && expanded ? (
                <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
              ) : expandable ? (
                <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
              ) : null,
            expandedRowRender: (record) => (
              <div className="flex flex-row items-center gap-3">
                <div className="w-px self-stretch bg-blue-dark"></div>
                <div className="grow flex flex-col gap-3">
                  <p className="text-lg">Relevant Spotlights</p>
                  <Table
                    rowClassName="hover:cursor-pointer"
                    tableLayout="auto"
                    dataSource={record.spotlights}
                    columns={messageColumns}
                    size="small"
                    onRow={({ conv_id }) => ({
                      onClick: () => {
                        navigate(
                          `/${guildId}/analytics/bug_reports?highlight=${conv_id}`
                        );
                      },
                    })}
                  />
                </div>
              </div>
            ),
            onExpand: (expanded, record) =>
              setExpandedRows(expanded ? [record.key] : []),
          }}
        />
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div className="flex flex-row justify-center items-center">
          <Skeleton active className="mt-2" paragraph={{ rows: 6 }} />
        </div>
      )}
    </>
  );
}
