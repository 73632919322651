import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Input, Select, Switch, Tooltip } from "antd";
import { parse as bigParse } from "json-bigint";
import { useCallback, useContext, useEffect, useState } from "react";
import { AccountContext, SiteContext } from "../../Contexts";
import APIManager from "../../scripts/APIManager";
import Card from "../Card";
import Modal from "../Modal";

export default function EnforcementCard(props) {
  const { activeModal, setActiveModal } = useContext(SiteContext);
  const { signedIn } = useContext(AccountContext);
  const {
    className,
    title,
    rule,
    setRule,
    guildId,
    disabled,
    refreshDict,
    isUniversal,
  } = props;
  const { refreshRolesImmediately, setRefreshRolesImmediately } = refreshDict;
  const [options, setOptions] = useState(null);
  const [error, setError] = useState(null);

  const occuranceOptions = [
    { label: "Never", value: 0 },
    { label: "Every occurance", value: 1 },
    { label: "2 occurances", value: 2 },
    { label: "3 occurances", value: 3 },
    { label: "4 occurances", value: 4 },
    { label: "5 occurances", value: 5 },
    { label: "10 occurances", value: 10 },
  ];

  const timeoutOptions = [
    { label: "60 seconds", value: 1 },
    { label: "5 minutes", value: 5 },
    { label: "10 minutes", value: 10 },
    { label: "1 hour", value: 60 },
    { label: "1 day", value: 1440 },
    { label: "1 week", value: 10080 },
  ];

  const requestRoles = useCallback(() => {
    APIManager.sendRequest(
      "get_roles",
      {
        guild_id: guildId,
      },
      true
    ).then((data) =>
      data.error
        ? setError(`error: ${data.error}`)
        : setOptions(
            Object.entries(data.roles).map(([key, value]) => ({
              label: value,
              value: key,
            }))
          )
    );
  }, [guildId]);

  useEffect(() => {
    if (signedIn) requestRoles();
  }, [signedIn, requestRoles]);

  useEffect(() => {
    if (refreshRolesImmediately && activeModal === null) {
      requestRoles();
      setRefreshRolesImmediately(false);
    }
  }, [
    refreshRolesImmediately,
    activeModal,
    setRefreshRolesImmediately,
    requestRoles,
  ]);

  if (error) return <h1>{error}</h1>;

  if (!options) return <Card className={className} loading />;

  return (
    <Card
      className={className}
      header={
        <div className="flex flex-wrap justify-between items-center gap-3 w-full">
          <div
            className={`flex flex-row items-center gap-3 ${
              disabled ? "opacity-30" : ""
            }`}
          >
            <h1>Enforcement{title ? ` (${title})` : null}</h1>
            <Tooltip title="Configure what happens when an incident is reported.">
              <FontAwesomeIcon icon={faCircleInfo} />
            </Tooltip>
          </div>
          {!isUniversal ? (
            <div>
              <Checkbox
                checked={rule.enforcement_setting.is_universal}
                onChange={({ target }) =>
                  target.checked
                    ? setActiveModal("set_universal:enforcement:on")
                    : setRule((rule) => ({
                        ...rule,
                        enforcement_setting: {
                          ...rule.enforcement_setting,
                          is_universal: false,
                        },
                      }))
                }
              >
                Use Universal
              </Checkbox>
            </div>
          ) : null}
        </div>
      }
    >
      <div className="flex flex-col gap-3 m-2">
        <div className="flex flex-row gap-6">
          <div
            className={`flex flex-row gap-3 ${disabled ? "opacity-30" : ""}`}
          >
            <p className="whitespace-nowrap">Delete</p>
            <Switch
              disabled={disabled}
              checked={rule.enforcement_setting.delete}
              onChange={(value) =>
                setRule((rule) => ({
                  ...rule,
                  enforcement_setting: {
                    ...rule.enforcement_setting,
                    delete: value,
                  },
                }))
              }
            />
          </div>
          <div
            className={`flex flex-row gap-3 ${disabled ? "opacity-30" : ""}`}
          >
            <p className="whitespace-nowrap">Auto Enforcement</p>
            <Switch
              disabled={disabled}
              checked={rule.enforcement_setting.auto_enforce}
              onChange={(value) =>
                setRule((rule) => ({
                  ...rule,
                  enforcement_setting: {
                    ...rule.enforcement_setting,
                    auto_enforce: value,
                  },
                }))
              }
            />
          </div>
        </div>
        <div
          className={`basis-1/2 flex flex-row items-center gap-3 ${
            disabled || !rule.enforcement_setting.auto_enforce
              ? "opacity-30"
              : ""
          }`}
        >
          <div className="flex flex-row items-center gap-3 w-full">
            <p className="w-20 whitespace-nowrap">Warn after</p>
            <div className="basis-full sm:basis-1/2">
              <Select
                disabled={disabled || !rule.enforcement_setting.auto_enforce}
                key={0}
                size="large"
                placeholder="Occurances"
                onChange={(value) =>
                  setRule((rule) => ({
                    ...rule,
                    enforcement_setting: {
                      ...rule.enforcement_setting,
                      warn: value === 0 ? null : value,
                    },
                  }))
                }
                value={rule.enforcement_setting.warn || 0}
                options={occuranceOptions}
                className="w-full"
              />
            </div>
          </div>
        </div>
        <div
          className={`flex flex-col sm:flex-row gap-x-3 gap-y-1 ${
            disabled || !rule.enforcement_setting.auto_enforce
              ? "opacity-30"
              : ""
          }`}
        >
          <div className="flex flex-row items-center gap-3">
            <p className="w-28 whitespace-nowrap">Timeout after</p>
            <div className="basis-full sm:basis-1/2">
              <Select
                disabled={disabled || !rule.enforcement_setting.auto_enforce}
                key={1}
                size="large"
                placeholder="Occurances"
                onChange={(value) =>
                  setRule((rule) => ({
                    ...rule,
                    enforcement_setting: {
                      ...rule.enforcement_setting,
                      timeout: value === 0 ? null : value,
                    },
                  }))
                }
                value={rule.enforcement_setting.timeout || 0}
                options={occuranceOptions}
                className="w-full"
              />
            </div>
          </div>
          <div className="flex flex-row items-center gap-3">
            <p className="w-12 whitespace-nowrap sm:text-center">for</p>
            <div className="basis-full sm:basis-1/2">
              <Select
                disabled={disabled || !rule.enforcement_setting.auto_enforce}
                key={2}
                size="large"
                placeholder="Time"
                onChange={(value) =>
                  setRule((rule) => ({
                    ...rule,
                    enforcement_setting: {
                      ...rule.enforcement_setting,
                      timeout_length: value,
                    },
                  }))
                }
                value={rule.enforcement_setting.timeout_length}
                options={timeoutOptions}
                className="w-full"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row justify-between gap-5">
          <div
            className={`basis-1/2 flex flex-row items-center gap-3 ${
              disabled || !rule.enforcement_setting.auto_enforce
                ? "opacity-30"
                : ""
            }`}
          >
            <p className="w-20 whitespace-nowrap">Ban after</p>
            <Select
              disabled={disabled || !rule.enforcement_setting.auto_enforce}
              key={3}
              size="large"
              placeholder="Occurances"
              onChange={(value) =>
                setRule((rule) => ({
                  ...rule,
                  enforcement_setting: {
                    ...rule.enforcement_setting,
                    ban: value === 0 ? null : value,
                  },
                }))
              }
              value={rule.enforcement_setting.ban || 0}
              options={occuranceOptions}
              className="w-full"
            />
          </div>
          <div
            className={`basis-1/2 flex flex-row items-center gap-3 ${
              disabled || !rule.enforcement_setting.auto_enforce
                ? "opacity-30"
                : ""
            }`}
          >
            <p className="w-20 whitespace-nowrap">Kick after</p>
            <Select
              disabled={disabled || !rule.enforcement_setting.auto_enforce}
              key={3}
              size="large"
              placeholder="Occurances"
              onChange={(value) =>
                setRule((rule) => ({
                  ...rule,
                  enforcement_setting: {
                    ...rule.enforcement_setting,
                    kick: value === 0 ? null : value,
                  },
                }))
              }
              value={rule.enforcement_setting.kick || 0}
              options={occuranceOptions}
              className="w-full"
            />
          </div>
        </div>
        <div
          className={`flex flex-row items-center gap-3 ${
            disabled || !rule.enforcement_setting.auto_enforce
              ? "opacity-30"
              : ""
          }`}
        >
          <label className="flex flex-col sm:flex-row sm:items-center gap-2 w-full">
            <p>Message</p>
            <Input
              disabled={disabled || !rule.enforcement_setting.auto_enforce}
              size="large"
              placeholder="Message to user"
              onChange={({ target }) =>
                setRule((rule) => ({
                  ...rule,
                  enforcement_setting: {
                    ...rule.enforcement_setting,
                    warn_message: target.value,
                  },
                }))
              }
              value={rule.enforcement_setting.warn_message}
              className="grow"
            />
          </label>
        </div>
        <div className="flex flex-row items-center gap-3">
          <p className="w-20 whitespace-nowrap">
            Roles{" "}
            <Tooltip title="These are the roles required to take action on an incident.">
              <FontAwesomeIcon icon={faCircleInfo} size="xs" className="w-8" />
            </Tooltip>
          </p>
          <Select
            className="grow"
            mode="multiple"
            size="large"
            placeholder="Allowed roles"
            onChange={(value) =>
              setRule((rule) => ({
                ...rule,
                enforcement_setting: {
                  ...rule.enforcement_setting,
                  enforcer_roles: value.map((role) => bigParse(role)),
                },
              }))
            }
            value={rule.enforcement_setting.enforcer_roles.map((role) =>
              role.toString()
            )}
            options={options}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </div>
      </div>

      <Modal
        isActive={activeModal === "set_universal:enforcement:on"}
        onClose={() => setActiveModal(null)}
        className="flex flex-col gap-10 max-w-sm p-10"
      >
        <div className="flex flex-col gap-3">
          <h1>
            Using the Universal Rule will clear your existing configuration for
            enforcement
          </h1>
        </div>
        <div className="flex flex-row-reverse gap-3">
          <Button
            type="primary"
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              setRule((rule) => ({
                ...rule,
                enforcement_setting: {
                  ...rule.enforcement_setting,
                  is_universal: true,
                },
              }));
            }}
          >
            Confirm
          </Button>
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => setActiveModal(null)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Card>
  );
}
