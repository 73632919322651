import { Spin } from "antd";
import { Fragment, useCallback, useEffect, useState } from "react";
import APIManager from "../../../scripts/APIManager";
import Card from "../../Card";

export default function MessageStats(props) {
  const { className, guildId, startDate, endDate } = props;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const requestData = useCallback(() => {
    setData(null);
    APIManager.sendRequest(
      "get_analytics_message_stats",
      {
        guild_id: guildId,
        start_date: startDate,
        end_date: endDate,
      },
      true
    ).then((data) =>
      data.error ? setError(`error: ${data.error}`) : setData(data)
    );
  }, [guildId, startDate, endDate]);

  useEffect(() => requestData(), [requestData]);

  const renderList = (stats) =>
    stats.map((stat, index) => (
      <Fragment key={index}>
        <div className="flex flex-row justify-between">
          <p>{stat.name}</p>
          <p>{stat.value}</p>
        </div>

        <hr />

        {stat.children ? (
          <div className="flex flex-col gap-1.5 pl-3">
            {renderList(stat.children)}
          </div>
        ) : null}
      </Fragment>
    ));

  return (
    <Card className={className} header={<h1>Message Stats</h1>}>
      <div className="flex flex-col gap-1.5 m-2">
        {data ? (
          renderList(data)
        ) : error ? (
          <p className="m-2">{error}</p>
        ) : (
          <div className="flex flex-row justify-center items-center">
            <Spin size="large" />
          </div>
        )}
      </div>
    </Card>
  );
}
