export default class Helper {
  static timeAgo(date) {
    const now = new Date();
    const seconds = Math.floor((now - new Date(date)) / 1000);

    let interval = Math.floor(seconds / 31536000); // Seconds in a year
    if (interval >= 1)
      return interval === 1 ? "1 year ago" : `${interval} years ago`;

    interval = Math.floor(seconds / 2592000); // Seconds in a month
    if (interval >= 1)
      return interval === 1 ? "1 month ago" : `${interval} months ago`;

    interval = Math.floor(seconds / 604800); // Seconds in a week
    if (interval >= 1)
      return interval === 1 ? "1 week ago" : `${interval} weeks ago`;

    interval = Math.floor(seconds / 86400); // Seconds in a day
    if (interval >= 1)
      return interval === 1 ? "1 day ago" : `${interval} days ago`;

    interval = Math.floor(seconds / 3600); // Seconds in an hour
    if (interval >= 1)
      return interval === 1 ? "1 hour ago" : `${interval} hours ago`;

    interval = Math.floor(seconds / 60); // Seconds in a minute
    if (interval >= 1)
      return interval === 1 ? "1 minute ago" : `${interval} minutes ago`;

    return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
  }
}
