import { Table } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SiteContext } from "../../Contexts";
import Badge from "../Badge";

export default function RuleList(props) {
  const { guildId } = useContext(SiteContext);
  const { rules, customFilter, className } = props;
  const filters = [
    {
      text: "Active",
      value: true,
    },
    {
      text: "Off",
      value: false,
    },
  ];
  const fields = [
    {
      title: "Rule",
      key: "rule",
      dataIndex: "name",
      sorter: (item1, item2) =>
        item1.name < item2.name ? -1 : item1.name > item2.name ? 1 : 0,
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) =>
        status ? (
          <Badge color="green" active>
            Active
          </Badge>
        ) : (
          <Badge color="gray" inactive>
            Off
          </Badge>
        ),
      sorter: (item1, item2) => item1.status - item2.status,
      filters,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Delete Messages",
      key: "delete",
      dataIndex: ["enforcement_setting", "delete"],
      className: "hidden sm:table-cell",
      render: (deleteOn) =>
        deleteOn && deleteOn === true ? (
          <Badge color="green" active>
            Active
          </Badge>
        ) : (
          <Badge color="gray" inactive>
            Off
          </Badge>
        ),
      sorter: (item1, item2) =>
        !item1.enforcement_setting.delete && item2.enforcement_setting.delete
          ? -1
          : item1.enforcement_setting.delete &&
            !item2.enforcement_setting.delete
          ? 1
          : 0,
      filters,
      onFilter: (value, record) =>
        !!record.enforcement_setting.delete === value,
    },
    {
      title: "Warn After",
      key: "warn",
      dataIndex: ["enforcement_setting", "warn"],
      className: "hidden sm:table-cell",
      render: (warn) =>
        !warn || warn === 0 ? (
          <Badge color="gray">Never</Badge>
        ) : warn === 1 ? (
          <Badge color="green">Every Occurance</Badge>
        ) : warn > 1 ? (
          <Badge color="green">{warn} Occurances</Badge>
        ) : null,
      sorter: (item1, item2) =>
        !item1.enforcement_setting.warn && item2.enforcement_setting.warn
          ? -1
          : item1.enforcement_setting.warn && !item2.enforcement_setting.warn
          ? 1
          : 0,
      filters,
      onFilter: (value, record) => !!record.enforcement_setting.warn === value,
    },
    {
      title: "Timeout After",
      key: "timeout",
      dataIndex: ["enforcement_setting", "timeout"],
      className: "hidden sm:table-cell",
      render: (timeout) =>
        !timeout || timeout === 0 ? (
          <Badge color="gray">Never</Badge>
        ) : timeout === 1 ? (
          <Badge color="green">Every Occurance</Badge>
        ) : timeout > 1 ? (
          <Badge color="green">{timeout} Occurances</Badge>
        ) : null,
      sorter: (item1, item2) =>
        !item1.enforcement_setting.timeout && item2.enforcement_setting.timeout
          ? -1
          : item1.enforcement_setting.timeout &&
            !item2.enforcement_setting.timeout
          ? 1
          : 0,
      filters,
      onFilter: (value, record) =>
        !!record.enforcement_setting.timeout === value,
    },
    {
      title: "Ban After",
      key: "ban",
      dataIndex: ["enforcement_setting", "ban"],
      className: "hidden sm:table-cell",
      render: (ban) =>
        !ban || ban === 0 ? (
          <Badge color="gray">Never</Badge>
        ) : ban === 1 ? (
          <Badge color="green">Every Occurance</Badge>
        ) : ban > 1 ? (
          <Badge color="green">{ban} Occurances</Badge>
        ) : null,
      sorter: (item1, item2) =>
        !item1.enforcement_setting.ban && item2.enforcement_setting.ban
          ? -1
          : item1.enforcement_setting.ban && !item2.enforcement_setting.ban
          ? 1
          : 0,
      filters,
      onFilter: (value, record) => !!record.enforcement_setting.ban === value,
    },
  ];
  let navigate = useNavigate();

  const flattenedRules = rules
    ? Object.entries(rules).flatMap(([, rule]) =>
        rule.children
          ? rule.children.map((child) => ({
              ...child,
              rule: `${rule.name} (${child.name})`,
              id: rule.id,
              status: rule.status,
            }))
          : rule
      )
    : [];

  const filteredRules = flattenedRules
    ? flattenedRules.filter((rule) =>
        customFilter ? customFilter(rule) : true
      )
    : [];

  return (
    <Table
      className={`${className}`}
      rowClassName="hover:cursor-pointer"
      dataSource={filteredRules.map((item) => ({
        ...item,
        key: item.id,
      }))}
      columns={fields}
      pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
      onRow={(rule) => ({
        onClick: () => {
          navigate(
            `/${guildId}/moderation/rule/${rule.id
              .toString()
              .replaceAll("/", "%2F")}`
          );
        },
      })}
    />
  );
}
