import { ConfigProvider, theme } from "antd";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { ThemeContext } from "../Contexts";
import constants from "../constants";

export default function ThemeContainer() {
  const [darkMode, setDarkMode] = useState(
    window.localStorage.getItem("dark_mode") !== null
      ? window.localStorage.getItem("dark_mode") === "true"
      : window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  useEffect(
    () => window.localStorage.setItem("dark_mode", darkMode),
    [darkMode]
  );

  return (
    <ThemeContext.Provider
      value={{
        darkMode,
        setDarkMode,
      }}
    >
      <div className={`${darkMode ? "dark" : ""}`}>
        <ConfigProvider
          theme={{
            algorithm: darkMode ? theme.darkAlgorithm : undefined,
            token: {
              colorPrimary: constants.colors["secondary"],
              fontFamily: constants.fonts.sans[0],
            },
          }}
        >
          <div className="theme-body">
            <Outlet />
          </div>
        </ConfigProvider>
      </div>
    </ThemeContext.Provider>
  );
}
