import { Dropdown, Switch } from "antd";
import constants from "../constants";
import Eye from "./Eye";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useCallback, useContext, useState } from "react";
import { ThemeContext } from "../Contexts";
import AuthManager from "../scripts/AuthManager";
import APIManager from "../scripts/APIManager";

export default function SimpleHeader(props) {
  const { className, subscribed, discordLinked, redirectURI } = props;
  const { darkMode, setDarkMode } = useContext(ThemeContext);
  const [userOpen, setUserOpen] = useState(false);

  const goToBilling = useCallback(() => {
    APIManager.sendRequest(
      "create_customer_portal_session",
      {
        return_address: window.location.href,
      },
      true
    ).then((data) => {
      if (data.success) window.location.href = data.url;
    });
  }, []);

  const items = [
    {
      key: "user",
      label: <p className="text-base font-bold">{AuthManager.username}</p>,
    },
    ...((subscribed !== null && !subscribed) ||
    (discordLinked !== null && !discordLinked)
      ? [
          {
            type: "divider",
          },
        ]
      : []),
    ...(discordLinked !== null && !discordLinked
      ? [
          {
            key: "link_discord",
            label: (
              <Link
                to={`https://discord.com/api/oauth2/authorize?client_id=1140700067892826122&redirect_uri=${encodeURIComponent(
                  redirectURI
                )}&response_type=code&scope=identify%20guilds%20guilds.members.read`}
              >
                Link Discord
              </Link>
            ),
          },
        ]
      : []),
    ...(subscribed !== null && !subscribed
      ? [
          {
            key: "subscribe",
            label: <Link to="/subscribe">Subscribe</Link>,
          },
        ]
      : []),
    {
      type: "divider",
    },
    {
      key: "billing",
      label: <Link onClick={goToBilling}>Billing</Link>,
    },
    {
      key: "darkMode",
      label: (
        <Link
          className="flex flex-row items-center gap-3"
          onClick={(e) => {
            e?.stopPropagation();
            setDarkMode((darkMode) => !darkMode);
          }}
        >
          <div className="flex flex-row items-center w-6">
            <FontAwesomeIcon
              className={darkMode ? "text-dark-gray-base" : "text-gray-base"}
              icon={darkMode ? faMoon : faSun}
              size="lg"
            />
          </div>
          <Switch checked={darkMode} onChange={setDarkMode} />
        </Link>
      ),
    },
    {
      type: "divider",
    },
    {
      key: "signOut",
      danger: true,
      label: <Link to="/signout">Sign Out</Link>,
    },
  ];

  return (
    <div
      className={`flex flex-row justify-between items-center box-border w-full h-12 ${className}`}
    >
      <Eye
        className="shrink-0 object-scale-down h-8 w-8 m-2"
        color={constants.colors.secondary}
        strokeWidth="2"
      />
      <div className="flex flex-row justify-center items-center w-8 h-8 m-2">
        <Dropdown
          className="cursor-pointer"
          menu={{ items }}
          trigger={["click"]}
          placement="bottomRight"
          onOpenChange={(open) => setUserOpen(open)}
          open={userOpen}
        >
          <FontAwesomeIcon
            className="softer-text"
            icon={faCircleUser}
            size="lg"
          />
        </Dropdown>
      </div>
    </div>
  );
}
