import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../Contexts";

export default function SignOut() {
  const { signOut } = useContext(AccountContext);
  const navigate = useNavigate();

  useEffect(() => {
    signOut();
    navigate("/");
  }, [signOut, navigate]);

  return;
}
