import { DownOutlined } from "@ant-design/icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dropdown, Skeleton, Space } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountContext } from "../Contexts";
import Badge from "../components/Badge";
import Card from "../components/Card";
import Modal from "../components/Modal";
import SimpleHeader from "../components/SimpleHeader";
import APIManager from "../scripts/APIManager";

export default function Home() {
  const { signedIn } = useContext(AccountContext);
  const [guilds, setGuilds] = useState(null);
  // const [registeredGuildCount, setRegisteredGuildCount] = useState(null);
  const [subscribed, setSubscribed] = useState(null);
  const [subProduct, setSubProduct] = useState(null);
  const [hasAccess, setHasAccess] = useState(null);
  const [discordLinked, setDiscordLinked] = useState(null);
  const [botAddConfirm, setBotAddConfirm] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const redirectURI =
    window.location.protocol +
    "//" +
    window.location.host +
    (location.pathname === "/"
      ? window.location.pathname.slice(0, -1)
      : window.location.pathname.replace(location.pathname, "")) +
    "/home";
  const authRedirectURI = redirectURI + "/discord_auth";

  const requestGuilds = useCallback(
    (clearFirst = true) => {
      if (clearFirst) setGuilds(null);
      APIManager.sendRequest("get_guilds", {}, true).then((data) => {
        if (data.error) setError(`error: ${data.error}`);
        else {
          if (data.discord_linked || hasAccess) {
            setGuilds(data.guilds);
            // setRegisteredGuildCount(
            //   data.guilds.filter(({ registered }) => registered).length
            // );
          }
          setDiscordLinked(data.discord_linked);
        }
      });
    },
    [hasAccess]
  );

  const registerGuild = useCallback(
    async (guild_id, guild_name) =>
      APIManager.sendRequest(
        "register_guild",
        { guild_id, guild_name },
        true
      ).then((data) => {
        if (data.success) requestGuilds(false);
        else if (data.error) alert(data.error);
        else if (data.message) alert(data.message);
      }),
    [requestGuilds]
  );

  const requestSubscribed = useCallback(() => {
    APIManager.sendRequest("get_subscription", {}, true).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else {
        setSubscribed(data.subscribed);
        setSubProduct(data.product);
        setHasAccess(data.has_access);
      }
    });
  }, []);

  useEffect(() => {
    if (signedIn && (subscribed || hasAccess)) {
      const urlParams = new URLSearchParams(location.search);
      let newCode = urlParams.get("code");
      let guildId = urlParams.get("guild_id");
      let permissions = urlParams.get("permissions");
      if (newCode && guildId && permissions) {
        setBotAddConfirm(true);
        navigate("../home", { replace: true });
      } else requestGuilds();
    }
  }, [
    location.search,
    signedIn,
    requestGuilds,
    subscribed,
    hasAccess,
    navigate,
  ]);

  useEffect(() => {
    if (botAddConfirm)
      setTimeout(() => {
        setBotAddConfirm(false);
        requestGuilds();
      }, 3000);
  }, [botAddConfirm, requestGuilds]);

  useEffect(() => {
    if (signedIn) requestSubscribed();
  }, [signedIn, requestSubscribed]);

  useEffect(() => {
    if (signedIn === false) {
      navigate("/", { replace: true });
    }
  }, [navigate, signedIn]);

  const guildTable = useCallback(
    (guilds) => (
      <div className="flex flex-wrap -m-3">
        {guilds.map(({ id, name, icon, registered, analytics, moderation }) => {
          let addBotButtons = [];
          if (registered && !analytics && subProduct !== "moderation")
            addBotButtons.push({
              key: "analytics",
              label: (
                <a
                  rel="noopener noreferrer"
                  href={`https://discord.com/api/oauth2/authorize?client_id=1122927391367512184&permissions=1084479764544&scope=bot&guild_id=${id}&redirect_uri=${encodeURIComponent(
                    redirectURI
                  )}&response_type=code`}
                  onClick={(e) => e.stopPropagation()}
                >
                  Analytics
                </a>
              ),
            });
          if (registered && !moderation && subProduct !== "analytics")
            addBotButtons.push({
              key: "moderation",
              label: (
                <a
                  rel="noopener noreferrer"
                  href={`https://discord.com/api/oauth2/authorize?client_id=1179063431794540564&permissions=1497735883830&scope=bot&guild_id=${id}&redirect_uri=${encodeURIComponent(
                    redirectURI
                  )}&response_type=code`}
                  onClick={(e) => e.stopPropagation()}
                >
                  Moderation
                </a>
              ),
            });
          return (
            <div key={id} className="w-full lg:w-1/2 xl:w-1/3 p-1.5 box-border">
              <Card
                className={`relative overflow-hidden p-[0px] group
                  before:content-[''] before:absolute before:inset-0 before:transition-all ${
                    registered && (analytics || moderation)
                      ? "cursor-pointer hover:before:bg-black/5 dark:hover:before:bg-white/5"
                      : "cursor-default"
                  }`}
                onClick={
                  registered && (analytics || moderation)
                    ? () => navigate(`/${id}`)
                    : null
                }
              >
                <div className="flex flex-col justify-between items-stretch p-4 gap-2">
                  <div className="flex flex-row justify-between items-start gap-1">
                    <div className="flex flex-row items-center overflow-hidden">
                      <div>
                        {icon ? (
                          <img
                            alt={`Icon for ${name}`}
                            width="32px"
                            src={`https://cdn.discordapp.com/icons/${id}/${icon}`}
                            className="block rounded-full group-hover:rounded-xl transition-all"
                          />
                        ) : (
                          <div className="flex justify-center items-center w-[32px] h-[32px] bg-gray-dark rounded-full group-hover:rounded-xl transition-all overflow-hidden">
                            <p
                              className={`text-white ${
                                name.split(" ").length > 3
                                  ? "text-[11px]"
                                  : name.split(" ").length > 4
                                  ? "text-[10px]"
                                  : "text-xs"
                              }`}
                            >
                              {name.split(" ").map((word) => word.charAt(0))}
                            </p>
                          </div>
                        )}
                      </div>
                      <p className="shrink font-normal text-md whitespace-nowrap overflow-hidden text-ellipsis w-full px-3">
                        {name}
                      </p>
                    </div>
                    <div className="shrink-0 flex flex-col justify-between items-end gap-2">
                      {!registered ? (
                        <Button
                          className="font-sans uppercase"
                          onClick={() => registerGuild(id, name)}
                        >
                          Activate SpecifEye
                        </Button>
                      ) : null}
                      {addBotButtons.length > 0 ? (
                        <Dropdown
                          menu={{ items: addBotButtons }}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <Button>
                            <Space className="font-sans uppercase">
                              Add Bot
                              <DownOutlined />
                            </Space>
                          </Button>
                        </Dropdown>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-end gap-1">
                    <p className="text-sm text-gray-medium dark:text-dark-gray-medium">
                      {id}
                    </p>
                    <div className="flex flex-row gap-1">
                      {analytics ? <Badge>Analytics</Badge> : null}
                      {moderation ? <Badge>Moderation</Badge> : null}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          );
        })}
      </div>
    ),
    [navigate, registerGuild, subProduct, redirectURI]
  );

  if (error) return <h1>{error}</h1>;

  if (botAddConfirm)
    return (
      <div className="static min-h-screen flex flex-col bg-white dark:bg-dark-white">
        <SimpleHeader
          subscribed={subscribed}
          discordLinked={discordLinked}
          redirectURI={authRedirectURI}
          goToCheckout={() => navigate("/subscribe")}
        />
        <Modal isActive={true} clickOff={false}>
          The bot has been added successfully!
        </Modal>
      </div>
    );

  if (
    subscribed === null ||
    (subscribed && discordLinked === null) ||
    (((subscribed && discordLinked) || hasAccess) && !guilds)
  )
    return (
      <div className="static min-h-screen flex flex-col bg-white dark:bg-black">
        <div className="grow flex flex-col bg-gradient-to-tr from-[#ffdc00]/20 via-transparent to-[#0099ff]/20">
          <SimpleHeader
            subscribed={subscribed}
            discordLinked={discordLinked}
            redirectURI={authRedirectURI}
            goToCheckout={() => navigate("/subscribe")}
          />
          <div className="flex flex-col gap-5 px-4 sm:px-6 pt-2 pb-6">
            <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />
          </div>
        </div>
      </div>
    );

  return (
    <div className="static min-h-screen flex flex-col bg-white dark:bg-black">
      <div className="grow flex flex-col bg-gradient-to-tr from-[#ffdc00]/20 via-transparent to-[#0099ff]/20 bg-fixed">
        <SimpleHeader
          subscribed={subscribed}
          discordLinked={discordLinked}
          redirectURI={authRedirectURI}
          goToCheckout={() => navigate("/subscribe")}
        />
        <div className="flex flex-col gap-5 px-4 sm:px-6 pt-2 pb-6">
          <div className="flex flex-row items-center justify-between gap-3 mx-2">
            <div className="flex flex-row items-center gap-3">
              <h1 className="softer-text">Home</h1>
            </div>
          </div>
          {(subscribed && discordLinked) || hasAccess ? (
            <>
              {guildTable(guilds.filter(({ registered }) => registered))}
              {guilds.filter(({ registered }) => !registered).length > 0 ? (
                <>
                  <p>Unlinked Guilds:</p>
                  {guildTable(guilds.filter(({ registered }) => !registered))}
                </>
              ) : null}
            </>
          ) : (
            <div className="flex flex-row justify-around">
              <div className="flex flex-col gap-5 max-w-xl w-full">
                <Card className="h-fit">
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center gap-3">
                      <h1 className={`${subscribed ? "opacity-30" : ""}`}>
                        <FontAwesomeIcon
                          icon={subscribed ? faCheckCircle : faCircle}
                          className="pr-3"
                        />
                        <span className="font-normal">Step 1 - </span>
                        Subscribe
                      </h1>
                    </div>
                  </div>
                  <div>
                    {subscribed ? (
                      <p className="opacity-30">COMPLETED</p>
                    ) : (
                      <Button
                        size="large"
                        type="primary"
                        className="font-sans"
                        onClick={() => navigate("/subscribe")}
                        disabled={subscribed}
                      >
                        Subscribe
                      </Button>
                    )}
                  </div>
                </Card>
                <Card className="h-fit">
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center gap-3">
                      <h1
                        className={`${
                          !subscribed || discordLinked ? "opacity-30" : ""
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={
                            subscribed && discordLinked
                              ? faCheckCircle
                              : faCircle
                          }
                          className="pr-3"
                        />
                        <span className="font-normal">Step 2 - </span>Link
                        Discord
                      </h1>
                    </div>
                  </div>
                  <div>
                    {discordLinked ? (
                      <p className="opacity-30">COMPLETED</p>
                    ) : (
                      <Button
                        size="large"
                        type="primary"
                        className="font-sans"
                        href={`https://discord.com/api/oauth2/authorize?client_id=1140700067892826122&redirect_uri=${encodeURIComponent(
                          authRedirectURI
                        )}&response_type=code&scope=identify%20guilds%20guilds.members.read`}
                        disabled={!subscribed || discordLinked}
                      >
                        Link Discord Account
                      </Button>
                    )}
                  </div>
                </Card>
                {/* <Card className="h-fit">
                      <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row items-center gap-3">
                          <h1
                            className={`${
                              !subscribed || !discordLinked ? "opacity-30" : ""
                            }`}
                          >
                            <FontAwesomeIcon icon={faCircle} className="pr-3" />
                            <span className="font-normal">Step 3 - </span>Add a
                            Community
                          </h1>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 items-start">
                        <p
                          className={`${
                            !subscribed || !discordLinked ? "hidden" : ""
                          }`}
                        >
                          Once you've done this, you must reload this page to see
                          your communities.
                        </p>
                        <Button
                          size="large"
                          type="primary"
                          className="font-sans text-white dark:text-dark-white uppercase"
                          href="https://discord.com/api/oauth2/authorize?client_id=1139641486028308691&permissions=1084479764544&scope=bot"
                          target="_blank"
                          disabled={!subscribed || !discordLinked}
                        >
                          Add Bot
                        </Button>
                      </div>
                    </Card> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
