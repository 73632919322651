import {
  faCircleExclamation,
  faCircleInfo,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Input, Skeleton, Switch, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext, SiteContext } from "../../Contexts";
import Badge from "../../components/Badge";
import Card from "../../components/Card";
import Modal from "../../components/Modal";
import ChannelsCard from "../../components/moderation/ChannelsCard";
import EnforcementCard from "../../components/moderation/EnforcementCard";
import ItemsCard from "../../components/moderation/ItemsCard";
import LogCard from "../../components/moderation/LogCard";
import RolesCard from "../../components/moderation/RolesCard";
import SpamCard from "../../components/moderation/SpamCard";
import APIManager from "../../scripts/APIManager";

export default function RulePage(props) {
  const { signedIn } = useContext(AccountContext);
  const {
    guildId,
    activeModal,
    setActiveModal,
    refreshRolesImmediately,
    setRefreshRolesImmediately,
    permissions,
  } = useContext(SiteContext);
  const { ruleId } = props;
  const { reloadNav, openNotification, closeNotification } =
    useContext(SiteContext);
  const [modified, setModified] = useState(null);
  const [rule, setRule] = useState(null);
  const [suggested, setSuggested] = useState(null);
  const [conflict, setConflict] = useState(false);
  const [changes, setChanges] = useState(false);
  const [originalRule, setOriginalRule] = useState(null);
  const [newRuleName, setNewRuleName] = useState(null);
  const [newRuleIsUsername, setNewRuleIsUsername] = useState(null);
  const [newRuleIsUrlRule, setNewRuleIsUrlRule] = useState(null);
  const [invalidDescription, setInvalidDescription] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const isEqualIgnoringField = (obj1, obj2, field) => {
    const newObj1 = JSON.parse(JSON.stringify(obj1));
    const newObj2 = JSON.parse(JSON.stringify(obj2));

    delete newObj1[field];
    delete newObj2[field];

    return JSON.stringify(newObj1) === JSON.stringify(newObj2);
  };

  const requestRule = useCallback(() => {
    APIManager.sendRequest(
      "get_rule",
      {
        guild_id: guildId,
        id: ruleId,
      },
      true,
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else if (data.does_not_exist)
        navigate(`/${guildId}/moderation/dashboard`);
      else {
        setOriginalRule(data.rule);
        setSuggested(data.suggested);
      }
    });
    setModified(null);
    closeNotification("key");
  }, [ruleId, guildId, closeNotification, navigate]);

  const checkRule = useCallback(() => {
    APIManager.sendRequest(
      "check_rule_changes",
      {
        guild_id: guildId,
        id: rule.id,
        rule: rule,
        original_rule: originalRule,
      },
      true
    ).then((data) => {
      if (data.error) {
        openNotification(
          data.warning ? "warning" : "error",
          data.warning ? "Warning" : "An Error has Occured",
          data.error
        );
      } else if (!data.success || data.message) {
        openNotification(
          data.warning ? "warning" : "error",
          data.warning ? "Warning" : "An Error has Occured",
          data.message
        );
      } else {
        if (data.compatible === false) {
          setConflict(true);
        } else {
          setConflict(false);
        }
        if (data.changes) {
          if (!changes)
            openNotification(
              "warning",
              "Outdated Config",
              "You must reload the page to see new changes.",
              0,
              "key"
            );
          setChanges(true);
        }
      }
    });
  }, [changes, guildId, openNotification, originalRule, rule]);

  const sendRule = useCallback(() => {
    APIManager.sendRequest(
      "set_rule",
      {
        guild_id: guildId,
        id: rule.id,
        rule: rule,
        original_rule: originalRule,
        suggested,
        universal: false,
      },
      true
    ).then((data) => {
      if (data.error)
        openNotification(
          data.warning ? "warning" : "error",
          data.warning ? "Warning" : "An Error has Occured",
          data.error
        );
      else if (data.suggested)
        openNotification(
          "success",
          "Suggested",
          "Your change has been suggested."
        );
      else if (data.success) {
        setModified(false);
        openNotification(
          "success",
          "Saved",
          "Your configuration has been saved."
        );
        requestRule();
      } else if (!data.success || data.message) {
        openNotification(
          data.warning ? "warning" : "error",
          data.warning ? "Warning" : "An Error has Occured",
          data.message
        );
      }
    });
  }, [rule, openNotification, guildId, originalRule, suggested, requestRule]);

  const removeRule = useCallback(
    async () =>
      await APIManager.sendRequest(
        "remove_rule",
        {
          guild_id: guildId,
          id: rule.id,
          suggested,
          name: rule.name,
        },
        true
      ).then((data) => {
        if (data.error) {
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
          return -1;
        } else if (data.suggested) {
          openNotification(
            "success",
            "Suggested",
            "Your change has been suggested."
          );
          return -1;
        } else if (data.success) {
          setModified(false);
          openNotification("success", "Removed", "The rule has been deleted.");
          return 1;
        } else if (!data.success || data.message) {
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message
          );
          return -1;
        }
      }),
    [rule, openNotification, guildId, suggested]
  );

  const modifyRule = useCallback(
    async () =>
      await APIManager.sendRequest(
        "modify_rule",
        {
          guild_id: guildId,
          id: rule.id,
          name: newRuleName,
          username_rule: newRuleIsUsername,
          url_rule: newRuleIsUrlRule,
          suggested,
        },
        true
      ).then((data) => {
        if (data.error) {
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
          return -1;
        } else if (data.suggested) {
          openNotification(
            "success",
            "Suggested",
            "Your change has been suggested."
          );
          return -1;
        } else if (data.success) {
          setModified(false);
          openNotification(
            "success",
            "Saved",
            "The rule's name or category has been saved."
          );
          return 1;
        } else if (!data.success || data.message) {
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message
          );
          return -1;
        }
      }),
    [
      openNotification,
      guildId,
      rule,
      newRuleName,
      newRuleIsUsername,
      newRuleIsUrlRule,
      suggested,
    ]
  );

  useEffect(() => {
    if (rule !== null) {
      setModified((modified) => (modified === null ? false : true));
    }
  }, [rule]);

  useEffect(() => {
    if (modified) {
      checkRule();
    }
  }, [modified, rule, checkRule]);

  useEffect(() => {
    if (originalRule) {
      setNewRuleIsUsername(originalRule.content_type === "username");
      setNewRuleIsUrlRule(originalRule.parse_url);
    }
  }, [originalRule, setNewRuleIsUsername, setNewRuleIsUrlRule]);

  useEffect(() => setRule(originalRule), [originalRule, setRule]);

  useEffect(() => {
    if (signedIn) requestRule();
  }, [signedIn, requestRule]);

  if (error) return <h1>{error}</h1>;

  if (!rule)
    return <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <div className="flex flex-row items-center gap-3">
          <h1 className="softer-text">{rule.name}</h1>
          {!rule.is_basic ? (
            <Tooltip
              title={
                permissions?.["Edit Rules"] ||
                permissions?.["Suggest Moderation Edits"]
                  ? null
                  : "You do not have permission."
              }
              placement="bottom"
            >
              <button
                onClick={() => setActiveModal("rename")}
                disabled={
                  !permissions?.["Edit Rules"] &&
                  !permissions?.["Suggest Moderation Edits"]
                }
              >
                <FontAwesomeIcon icon={faPenToSquare} />
              </button>
            </Tooltip>
          ) : null}
          <Switch
            disabled={suggested}
            checked={rule.status}
            onChange={(value) =>
              setRule((rule) => ({
                ...rule,
                status: value,
              }))
            }
          />
          {suggested ? (
            <Tooltip title="You may make any changes to the rule except for turning it on until the rule is approved.">
              <div>
                <Badge color="yellow">Awaiting Approval</Badge>
              </div>
            </Tooltip>
          ) : null}
        </div>
        {!rule.is_basic ? (
          <Tooltip
            title={
              permissions?.["Delete Rules"] ||
              permissions?.["Suggest Moderation Edits"]
                ? null
                : "You do not have permission."
            }
            placement="left"
          >
            <div>
              <Button
                disabled={
                  suggested ||
                  (!permissions?.["Delete Rules"] &&
                    !permissions?.["Suggest Moderation Edits"])
                }
                size="sm"
                danger
                icon={<FontAwesomeIcon icon={faTrash} size="lg" />}
                onClick={() => setActiveModal("remove")}
              />
            </div>
          </Tooltip>
        ) : null}
      </div>

      <div className="flex flex-col gap-3 items-stretch m-2">
        <Card
          header={
            <div className="flex flex-row justify-between items-center">
              <div className="flex flex-row items-center gap-3">
                <h1>Definition</h1>
                {rule.content_type === "message" ? (
                  <Badge>Content Rule</Badge>
                ) : rule.content_type === "username" ? (
                  <Badge>Username Rule</Badge>
                ) : null}
              </div>
            </div>
          }
        >
          <div className="flex flex-col gap-3 m-2">
            {rule.is_basic ? (
              <p>{rule.description}</p>
            ) : (
              <div className="flex flex-row justify-between gap-6">
                <TextArea
                  className="basis-1/2 max-h-64"
                  size="large"
                  placeholder="Your definition"
                  onChange={({ target }) => {
                    setInvalidDescription(
                      target.value.split(" ").filter((term) => term.length > 0)
                        .length < 2
                    );
                    setRule((rule) => ({ ...rule, description: target.value }));
                  }}
                  value={rule.description}
                />
                <div className="basis-1/2">
                  <p className="font-bold text-base">
                    Rule Definition Guidelines
                  </p>
                  <ol className="text-base">
                    <li>
                      Give a clear description of what type of messages
                      constitute a violation.
                    </li>
                    <li>
                      Limit over-moderation by describing types of messages that
                      may be related to the rule definition, but do not
                      constitute a violation.
                    </li>
                    <li>
                      Remember, you can always come back and tweak the rule
                      definition to help the system moderate better.
                    </li>
                  </ol>
                </div>
              </div>
            )}
          </div>
        </Card>

        {rule.spam_rule_setting ? (
          <SpamCard rule={rule} setRule={setRule} />
        ) : null}

        {rule.ban_all_links !== undefined ? (
          <Card header={<h1>Ban All Links</h1>}>
            <div className="flex flex-row justify-between items-center m-2">
              <Switch
                checked={rule.ban_all_links}
                onChange={(value) =>
                  setRule((rule) => ({
                    ...rule,
                    ban_all_links: value,
                  }))
                }
              />
            </div>
          </Card>
        ) : null}

        {rule.content_type !== "username" ? (
          <ChannelsCard
            className="grow shrink-0 max-w-full"
            rule={rule}
            setRule={setRule}
            guildId={guildId}
            disabled={rule.channel_setting.is_universal}
          />
        ) : null}

        <div className="flex flex-row flex-wrap gap-3">
          <LogCard
            className="grow shrink-0 basis-64 box-border"
            rule={rule}
            setRule={setRule}
            guildId={guildId}
            disabled={rule.log_channel.is_universal}
          />
          <RolesCard
            className="grow shrink-0 basis-64"
            rule={rule}
            setRule={setRule}
            guildId={guildId}
            refreshDict={{
              refreshRolesImmediately,
              setRefreshRolesImmediately,
              activeModal,
            }}
            disabled={rule.role_setting.is_universal}
          />
        </div>
        <EnforcementCard
          className="grow shrink-0"
          rule={rule}
          setRule={setRule}
          guildId={guildId}
          refreshDict={{
            refreshRolesImmediately,
            setRefreshRolesImmediately,
            activeModal,
          }}
          disabled={rule.enforcement_setting.is_universal}
        />

        {rule.items || rule.children ? (
          <div className="flex flex-row flex-wrap items-start gap-3">
            {rule.words ? (
              <div className="grow basis-0 flex flex-col gap-6">
                <ItemsCard
                  title="Words"
                  type="words"
                  items={rule.words}
                  rule={rule}
                  setRule={setRule}
                />
              </div>
            ) : null}
            {rule.children ? (
              <div className="grow basis-0 flex flex-col gap-6">
                {rule.children.map((child, index) => (
                  <EnforcementCard
                    disabled={true}
                    key={index}
                    rule={child}
                    title={child.name}
                    setRule={(func) =>
                      setRule((rule) => ({
                        ...rule,
                        children: [
                          ...rule.children.slice(0, index),
                          func(rule.children[index]),
                          ...rule.children.slice(index + 1),
                        ],
                      }))
                    }
                  />
                ))}
              </div>
            ) : null}
          </div>
        ) : null}

        {rule.examples || rule.counter_examples ? (
          <div className="flex flex-row flex-wrap items-start gap-3">
            {rule.examples ? (
              <ItemsCard
                title="Examples"
                type="examples"
                description="Configure examples of items that should be flagged as incidents."
                items={rule.examples}
                rule={rule}
                setRule={setRule}
                className="grow"
              />
            ) : null}
            {rule.counter_examples ? (
              <ItemsCard
                title="Counter Examples"
                type="counter_examples"
                description="Configure examples of items that should not be flagged as incidents."
                items={rule.counter_examples}
                rule={rule}
                setRule={setRule}
                className="grow"
              />
            ) : null}
          </div>
        ) : null}

        {rule.blocked_words.length > 0 || rule.exemptions ? (
          <div className="flex flex-row flex-wrap items-start gap-3">
            {rule.blocked_words.length > 0 ? (
              <ItemsCard
                title="Blocklist"
                type="blocked_words"
                description="Configure the list of words that will be flagged as incidents."
                items={rule.blocked_words}
                rule={rule}
                setRule={setRule}
                className="grow"
              />
            ) : null}
            {rule.exemptions ? (
              <ItemsCard
                title="Exemptions"
                type="exemptions"
                items={rule.exemptions}
                rule={rule}
                setRule={setRule}
                className="grow"
              />
            ) : null}
          </div>
        ) : null}

        {rule.approved_links || rule.banned_links ? (
          <div className="flex flex-row flex-wrap items-start gap-3">
            {rule.approved_links ? (
              <ItemsCard
                title="Approved Links"
                type="approved_links"
                items={rule.approved_links}
                rule={rule}
                setRule={setRule}
                disabled={!rule.ban_all_links}
                className="grow"
              />
            ) : null}
            {rule.banned_links ? (
              <ItemsCard
                title="Banned Links"
                type="banned_links"
                items={rule.banned_links}
                rule={rule}
                setRule={setRule}
                disabled={rule.ban_all_links}
                className="grow"
              />
            ) : null}
          </div>
        ) : null}
      </div>

      <Modal
        isActive={activeModal === "save"}
        onClose={() => setActiveModal(null)}
        className="flex flex-col gap-10 max-w-sm p-10"
      >
        <div className="flex flex-col gap-3">
          <h1>Are you sure you would like to make these changes?</h1>
        </div>
        <div className="flex flex-row-reverse gap-3">
          <Button
            type="primary"
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              sendRule();
            }}
          >
            Confirm
          </Button>
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => setActiveModal(null)}
          >
            Cancel
          </Button>
        </div>
      </Modal>

      <Modal
        isActive={activeModal === "remove"}
        onClose={() => setActiveModal(null)}
        className="flex flex-col gap-10 max-w-sm p-10"
      >
        <div className="flex flex-col gap-3">
          <h1>Are you sure you would like to remove this rule?</h1>
          <p className="text-sm font-normal text-gray-dark dark:text-dark-gray-base">
            This action cannot be undone.
          </p>
        </div>
        <div className="flex flex-row-reverse gap-3">
          <Button
            type="primary"
            size="large"
            className="w-32 font-sans"
            danger
            onClick={() => {
              setActiveModal(null);
              removeRule()
                .then(
                  (value) =>
                    value === 1 && navigate(`/${guildId}/moderation/dashboard`)
                )
                .then(() => reloadNav());
            }}
          >
            Confirm
          </Button>
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => setActiveModal(null)}
          >
            Cancel
          </Button>
        </div>
      </Modal>

      <Modal
        isActive={activeModal === "rename"}
        onClose={() => {
          setActiveModal(null);
          setNewRuleName(null);
        }}
        className="flex flex-col gap-10 max-w-sm p-10"
      >
        <div className="flex flex-col gap-3">
          <h1>Modify Rule</h1>
          <Input
            size="large"
            placeholder="Rule Name"
            onChange={({ target }) =>
              target.value === originalRule.name
                ? setNewRuleName(null)
                : setNewRuleName(target.value)
            }
            value={newRuleName === null ? originalRule.name : newRuleName}
          />
          <div>
            <Checkbox
              onChange={({ target }) => setNewRuleIsUsername(target.checked)}
              checked={newRuleIsUsername}
            >
              Username Rule
            </Checkbox>
          </div>
          <div className="flex flex-row items-center">
            <Checkbox
              onChange={({ target }) => setNewRuleIsUrlRule(target.checked)}
              checked={newRuleIsUrlRule}
            >
              URL Rule
            </Checkbox>
            <Tooltip title="If this box is unchecked the rule will ignore any URLs contained in messages.">
              <FontAwesomeIcon icon={faCircleInfo} size="xs" />
            </Tooltip>
          </div>

          <p className="text-sm font-normal text-gray-dark dark:text-dark-gray-base">
            All unsaved changes will be discarded.
          </p>
        </div>
        <div className="flex flex-row-reverse gap-3">
          <Button
            type="primary"
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              if (!rule.is_basic)
                modifyRule().then((value) => {
                  if (value === 1) {
                    requestRule();
                    reloadNav();
                    setActiveModal(null);
                    setNewRuleName(null);
                  }
                });
            }}
          >
            Confirm
          </Button>
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              setNewRuleName(null);
            }}
          >
            Cancel
          </Button>
        </div>
      </Modal>

      {!(modified === true ? false : true) &&
      JSON.stringify(rule) !== JSON.stringify(originalRule) ? (
        <>
          <Card className="fixed flex flex-row justify-center w-fit left-0 right-0 bottom-0 mb-6 mx-auto">
            <div className="flex flex-row items-center gap-6">
              <div className="flex flex-col gap-1">
                <p>Save your changes</p>
                {conflict ? (
                  <div className="flex flex-row items-center gap-1">
                    <FontAwesomeIcon
                      className="text-red-dark"
                      icon={faCircleExclamation}
                      size="sm"
                    />
                    <p className="text-xs max-w-xs text-red-dark">
                      Conflicting changes (refresh to fix)
                    </p>
                  </div>
                ) : null}
              </div>
              <Tooltip
                title={
                  !permissions?.["Suggest Moderation Edits"] &&
                  ((!isEqualIgnoringField(rule, originalRule, "status") &&
                    !permissions?.["Edit Rules"]) ||
                    (rule.status !== originalRule.status &&
                      !permissions?.["Edit Rule Status"]))
                    ? "You do not have permission."
                    : null
                }
                placement="top"
              >
                <div>
                  <Button
                    type="primary"
                    size="large"
                    className="w-32 font-sans"
                    onClick={() => setActiveModal("save")}
                    disabled={
                      (modified === true ? false : true) ||
                      invalidDescription ||
                      (!permissions?.["Suggest Moderation Edits"] &&
                        ((!isEqualIgnoringField(rule, originalRule, "status") &&
                          !permissions?.["Edit Rules"]) ||
                          (rule.status !== originalRule.status &&
                            !permissions?.["Edit Rule Status"])))
                    }
                  >
                    Save
                  </Button>
                </div>
              </Tooltip>
            </div>
          </Card>
          <div className="h-16"></div>
        </>
      ) : null}
    </>
  );
}
