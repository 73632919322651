import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ConfigProvider, Segmented, theme } from "antd";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../../Contexts";
import Card from "../../components/Card";
import Eye from "../../components/Eye";
import Logo from "../../components/Logo";
import Modal from "../../components/Modal";
import LandingFooter from "../../components/landing/LandingFooter";
import LandingHeader from "../../components/landing/LandingHeader";
import constants from "../../constants";

export default function Subscribe() {
  const { signedIn, goToCheckout } = useContext(AccountContext);
  const { innerWidth } = window;
  const [activeModal, setActiveModal] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedTierIndex, setSelectedTierIndex] = useState(null);
  const navigate = useNavigate();

  const PRICING = {
    moderation: [
      { price: "$75", messages: "0-1k" },
      { price: "$350", messages: "1k-5k" },
      { price: "$665", messages: "5k-10k" },
      { price: "$1,197", messages: "10k-20k" },
      { price: "$2,035", messages: "20k-50k" },
      { price: "Contact Us", messages: "50k+" },
    ],
    analytics: [
      { price: "$50", messages: "0-1k" },
      { price: "$350", messages: "1k-5k" },
      { price: "$665", messages: "5k-10k" },
      { price: "$1,197", messages: "10k-20k" },
      { price: "$2,035", messages: "20k-50k" },
      { price: "Contact Us", messages: "50k+" },
    ],
    bundle: [
      { price: "$120", messages: "0-1k" },
      { price: "$550", messages: "1k-5k" },
      { price: "$1,064", messages: "5k-10k" },
      { price: "$1,915", messages: "10k-20k" },
      { price: "$3,256", messages: "20k-50k" },
      { price: "Contact Us", messages: "50k+" },
    ],
  };

  return (
    <div className="relative no-zoom">
      <div className="-z-10 absolute inset-0 bg-white" />
      <div className="sm:hidden -z-10 absolute w-full flex flex-row justify-center top-[50px]">
        <div className="landing-bg h-[400px] w-[400px]" />
      </div>

      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
          token: {
            colorPrimary: constants.colors["secondary"],
          },
        }}
      >
        <div className="static min-h-screen flex flex-col">
          <LandingHeader
            simple
            landing
            className="fixed box-border h-12 sm:h-16 w-full"
          />
          <div className="h-24 sm:h-0" />
          <div className="flex flex-col items-center justify-center gap-8 box-border sm:min-h-screen sm:py-24">
            <p className="font-landing-header text-2xl text-black font-semibold">
              Pricing Plans
            </p>
            <div className="self-stretch flex flex-col lg:flex-row justify-center items-stretch gap-8 lg:px-24 pt-10">
              {(innerWidth < 640
                ? [
                    { name: "Bundle", plan: "bundle" },
                    { name: "Moderation", plan: "moderation" },
                    { name: "Analytics", plan: "analytics" },
                  ]
                : [
                    { name: "Moderation", plan: "moderation" },
                    { name: "Bundle", plan: "bundle" },
                    { name: "Analytics", plan: "analytics" },
                  ]
              ).map(({ name, plan }) => (
                <Card
                  key={plan}
                  className="
                basis-1/3 bg-[#E1E1E1] sm:max-w-xs sm:self-center lg:self-stretch
                backdrop-blur-md border border-solid
                box-border flex flex-col justify-between items-stretch py-8 mx-4 lg:mx-0
                bg-white border-secondary-light"
                  noMode
                >
                  {plan === "bundle" ? (
                    <div className="absolute -top-10 left-0 right-0 w-40 h-10 mx-auto bg-secondary rounded-t-3xl">
                      <p className="font-landing-header text-white text-center font-bold m-3">
                        Most Popular
                      </p>
                    </div>
                  ) : null}
                  <div className="flex flex-col items-center gap-5">
                    <div className="flex flex-col items-center gap-0 px-3">
                      <div className="flex flex-row items-center gap-2 h-8 mb-2">
                        {plan === "bundle" ? (
                          <Eye
                            className="object-scale-down h-[35px]"
                            color={constants.colors.secondary}
                            strokeWidth="2"
                          />
                        ) : null}
                      </div>
                      <p className="font-landing-header font-semibold text-black text-3xl">
                        {name}
                      </p>
                      {plan === "bundle" ? (
                        <div className="flex flex-col justify-center h-10">
                          <p className="font-landing text-lg text-black">
                            Analytics + Moderation
                          </p>
                        </div>
                      ) : null}
                      <p className="font-landing text-xl font-semibold text-black">
                        From {PRICING[plan][0].price}/month
                      </p>
                    </div>
                    <div className="w-full px-8 text-sm box-border">
                      <p className="font-landing text-black font-semibold pl-6">
                        What you get:
                      </p>
                      {plan === "analytics" ? (
                        <ul className="list-none pl-0">
                          {[
                            "Community feedback Spotlights",
                            "Identify top feedback topics from your community",
                            "Analyze community activity",
                            "Analyze user interactions to find VIP users",
                          ].map((bullet, index) => (
                            <li
                              key={index}
                              className="flex flex-row items-start gap-2"
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                size="lg"
                                className="text-secondary-light"
                              />
                              <p className="font-landing text-black">
                                {bullet}
                              </p>
                            </li>
                          ))}
                        </ul>
                      ) : plan === "moderation" ? (
                        <ul className="list-none pl-0">
                          {[
                            "AI content moderation",
                            "Custom rule creation",
                            "Live incident reporting",
                            "Automatic and manual user action",
                            "Take actions within Discord",
                          ].map((bullet, index) => (
                            <li
                              key={index}
                              className="flex flex-row items-start gap-2"
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                size="lg"
                                className="text-secondary-light"
                              />
                              <p className="font-landing text-black">
                                {bullet}
                              </p>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <ul className="list-none pl-0">
                          {[
                            "All features included in the Moderation and Analytics plans",
                          ].map((bullet, index) => (
                            <li
                              key={index}
                              className="flex flex-row items-start gap-2"
                            >
                              <FontAwesomeIcon
                                icon={faCheck}
                                size="lg"
                                className="text-secondary-light"
                              />
                              <p className="font-landing text-black">
                                {bullet}
                              </p>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <Button
                    className="self-center w-64 h-20 bg-[#FFD86D]"
                    onClick={() => {
                      setActiveModal("plan_options");
                      setSelectedPlan({ plan, name });
                    }}
                    type="primary"
                  >
                    <span className="font-landing-header capitalize text-2xl font-semibold text-black">
                      Subscribe Now
                    </span>
                  </Button>
                </Card>
              ))}
            </div>
            <Modal
              isActive={activeModal === "plan_options"}
              onClose={() => {
                setActiveModal(null);
                setSelectedTierIndex(null);
              }}
              className="overflow-hidden bg-gradient-to-br
              from-white/[15%] to-card-blue/25 backdrop-blur-3xl
              border border-solid border-white/25 pricing-modal
              text-white"
              noMode
            >
              {selectedPlan ? (
                <div className="flex flex-row gap-0 sm:gap-5 -m-2 z-10">
                  <div className="flex flex-col justify-between items-start p-4 sm:p-10 max-w-sm">
                    <div />
                    <div className="flex flex-col gap-5">
                      <div className="flex flex-col items-start gap-0">
                        <Logo header className="h-12" />
                        <h1 className="text-[2.7rem] pl-[32px]">
                          <span
                            className={`font-landing ${
                              selectedPlan.plan === "bundle"
                                ? "bg-clip-text text-transparent bg-gradient-to-br from-[#ffecad] to-[#ffc300]"
                                : "bg-clip-text text-transparent bg-gradient-to-br from-white to-dark-gray-base"
                            }`}
                          >
                            {selectedPlan.name}
                          </span>
                        </h1>
                      </div>
                      <div className="w-full px-0 sm:px-8 box-border">
                        <p className="font-landing">Includes:</p>
                        {selectedPlan.plan === "analytics" ? (
                          <ul className="font-landing list-none pl-0 text-sm sm:text-base">
                            {[
                              "Community feedback Spotlights",
                              "Identify top feedback topics from your community",
                              "Analyze community activity",
                              "Analyze user interactions to find VIP users",
                            ].map((bullet, index) => (
                              <li
                                key={index}
                                className="flex flex-row items-start gap-2"
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  size="lg"
                                  className="text-secondary-light"
                                />
                                <p>{bullet}</p>
                              </li>
                            ))}
                          </ul>
                        ) : selectedPlan.plan === "moderation" ? (
                          <ul className="font-landing list-none pl-0 text-sm sm:text-base">
                            {[
                              "AI content moderation",
                              "Custom rule creation",
                              "Live incident reporting",
                              "Automatic and manual user action",
                              "Take actions within Discord",
                            ].map((bullet, index) => (
                              <li
                                key={index}
                                className="flex flex-row items-start gap-2"
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  size="lg"
                                  className="text-secondary-light"
                                />
                                <p>{bullet}</p>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <ul className="font-landing list-none pl-0 text-xs sm:text-base">
                            {[
                              "Community feedback Spotlights",
                              "Identify top feedback topics from your community",
                              "Analyze community activity",
                              "Analyze user interactions to find VIP users",
                              "AI content moderation",
                              "Custom rule creation",
                              "Live incident reporting",
                              "Automatic and manual user action",
                              "Take actions within Discord",
                            ].map((bullet, index) => (
                              <li
                                key={index}
                                className="flex flex-row items-start gap-1 sm:gap-2"
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  size="lg"
                                  className="text-secondary-light"
                                />
                                <p>{bullet}</p>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                    <p className="font-landing text-xs sm:text-sm pt-5">
                      * messages are based on a three month rolling window over
                      all communities under the account.
                    </p>
                  </div>
                  <div className="shrink-0 grow-0 bg-black/25 w-24 sm:w-fit p-4 sm:p-8 flex flex-col justify-between gap-0">
                    <div className="flex flex-col gap-3">
                      {" "}
                      <p className="font-landing">Choose a tier*:</p>
                      <ConfigProvider
                        theme={{
                          algorithm: undefined,
                          token: {
                            colorPrimary: constants.colors["secondary"],
                            fontFamily: constants.fonts.landing[0],
                          },
                        }}
                      >
                        <Segmented
                          className="bg-white/75"
                          vertical
                          size="medium"
                          options={PRICING[selectedPlan.plan].map(
                            ({ messages }, index) => ({
                              label: (
                                <p className="w-full text-left leading-4 py-1.5 sm:py-2">
                                  {messages} <br className="sm:hidden" />
                                  messages
                                </p>
                              ),
                              value: index,
                            })
                          )}
                          onChange={(value) => setSelectedTierIndex(value)}
                          value={selectedTierIndex}
                        />
                      </ConfigProvider>
                    </div>
                    <div className="flex flex-col gap-3 justify-end h-28">
                      <p className="font-landing font-semibold text-sm sm:text-lg">
                        {selectedTierIndex !== null ? (
                          PRICING[selectedPlan.plan][selectedTierIndex].price
                        ) : (
                          <>
                            <span className="text-xs sm:text-md">From</span>{" "}
                            <span>{PRICING[selectedPlan.plan][0].price}</span>
                          </>
                        )}
                        {selectedTierIndex &&
                        PRICING[selectedPlan.plan][selectedTierIndex].price ===
                          "Contact Us"
                          ? ""
                          : "/month"}
                      </p>
                      <Button
                        type="primary"
                        disabled={
                          selectedPlan === null ||
                          selectedTierIndex === null ||
                          (selectedTierIndex &&
                            PRICING[selectedPlan.plan][selectedTierIndex]
                              .price === "Contact Us")
                        }
                        className="font-landing font-semibold text-lg h-12"
                        onClick={
                          signedIn
                            ? () =>
                                goToCheckout(
                                  selectedPlan.plan,
                                  selectedTierIndex
                                )
                            : () =>
                                navigate(
                                  `/login?plan=${selectedPlan.plan}_${selectedTierIndex}`
                                )
                        }
                      >
                        <span className="normal-case text-base sm:text-lg">
                          Continue
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}
            </Modal>
          </div>

          <LandingFooter />
        </div>
      </ConfigProvider>
    </div>
  );
}
