import AuthManager from "./AuthManager";
import { parse as bigParse, stringify as bigStringify } from "json-bigint";

/**
 * A manager for all API interactions
 */
export default class APIManager {
  /**
   * Call a POST request at specified endpoint with specified body
   * @param {string} endpoint
   * @param {object} body
   * @returns object of the API response
   */
  static sendRequest = async (
    endpoint,
    body,
    useAccessToken = false,
    bigInt = false
  ) => {
    if (useAccessToken) {
      if (
        AuthManager.accessToken !== null &&
        Date.now() / 1000 >= AuthManager.accessTokenExp
      ) {
        AuthManager.refreshToken(true);
      }

      while (!AuthManager.accessToken)
        await new Promise((resolve) => setTimeout(resolve, 100));
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: bigStringify({
        ...body,
        token: useAccessToken ? AuthManager.accessToken : null,
      }),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, requestOptions)
      .then((response) => response.text())
      .then((data) => bigParse(data))
      .catch((error) => ({
        error: error,
      }));
  };

  /**
   * Call a POST request at specified endpoint with specified body for redirect
   * @param {string} endpoint
   * @param {object} body
   * @returns a redirect to the URL
   */
  static sendRedirectRequest = async (endpoint, body) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: bigStringify(body),
      redirect: "manual",
    };
    return fetch(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      requestOptions
    );
  };

  /**
   * Call a POST request at specified endpoint with specified body
   * @param {string} endpoint
   * @param {object} body
   * @returns blob URL of the API response
   */
  static sendBlobRequest = async (endpoint, body) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: bigStringify(body),
    };
    return fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, requestOptions)
      .then((response) => response.blob())
      .then((blob) => window.URL.createObjectURL(blob))
      .catch((error) => ({
        error: error,
      }));
  };

  /**
   * Get the address of an endpoint on the backend
   * @param {string} endpoint
   * @returns string of the URL
   */
  static getEndpointURL = (endpoint) =>
    `${process.env.REACT_APP_API_URL}/${endpoint}`;
}
