import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { faFlag, faLightbulb } from "@fortawesome/free-regular-svg-icons";
import { faGlobe, faScaleBalanced } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ConfigProvider,
  Skeleton,
  Table,
  Tabs,
  Tooltip
} from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SiteContext } from "../Contexts";
import Badge from "../components/Badge";
import constants from "../constants";
import APIManager from "../scripts/APIManager";
import NotFound from "./NotFound";

export default function Suggestions() {
  const { guildId, openNotification } = useContext(SiteContext);
  const [data, setData] = useState(null);
  const [channels, setChannels] = useState(null);
  const [roles, setRoles] = useState(null);
  const [error, setError] = useState(null);
  const [expandedRows, setExpandedRows] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const navigate = useNavigate();

  const requestData = useCallback(() => {
    APIManager.sendRequest(
      "get_suggestions",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else {
        setData(
          data.suggestions
            .map((item) => ({ ...item, key: item._id }))
            .reduce((accumulator, current) => {
              let names = Array.isArray(current.name)
                ? current.name
                : [current.name];
              names.forEach((current_name) => {
                let index = accumulator.findIndex(
                  ({ name, item_type }) =>
                    current_name === name && current.item_type === item_type
                );
                if (index > -1) accumulator[index].items.push(current);
                else
                  accumulator.push({
                    key: `${current.item_type}_${current_name}`,
                    name: current_name,
                    item_type: current.item_type,
                    items: [current],
                  });
              });
              return accumulator;
            }, [])
        );
      }
    });
  }, [guildId]);

  const requestChannels = useCallback(() => {
    APIManager.sendRequest(
      "get_moderation_channels",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else setChannels(data.channels);
    });
  }, [guildId]);

  const requestRoles = useCallback(() => {
    APIManager.sendRequest(
      "get_roles",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else setRoles(data.roles);
    });
  }, [guildId]);

  const acceptSuggestion = useCallback(
    async (id) =>
      APIManager.sendRequest(
        "accept_suggestions",
        {
          guild_id: guildId,
          id: id,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.success)
          openNotification(
            "success",
            "Change Made",
            "The suggested change has been made."
          );
        else
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message || ""
          );
        return data.success;
      }),
    [guildId, openNotification]
  );

  const rejectSuggestion = useCallback(
    async (id) =>
      APIManager.sendRequest(
        "reject_suggestions",
        {
          guild_id: guildId,
          id: id,
        },
        true
      ).then((data) => {
        if (data.error)
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
        else if (data.success)
          openNotification(
            "success",
            "Suggestion Removed",
            "The suggestion has been removed."
          );
        else
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message || ""
          );
        return data.success;
      }),
    [guildId, openNotification]
  );

  const renderBeforeAfter = (before, { field, value }, item) => {
    let fieldContent = null;
    let valueContent = null;

    if (field === "exist") {
      if (before) {
        if (value) return <span>Exists</span>;
        else return <span>Does Not Exist</span>;
      } else {
        if (value) return <span>Created</span>;
        else return <span>Deleted</span>;
      }
    }

    if (field === "enforcement") {
      if (!value) {
        return <span>Not Enforced</span>;
      }
    }

    // Render field
    if (field === "revs") fieldContent = "definition";
    else fieldContent = field;

    // Render value
    if (Array.isArray(value) && field !== "revs")
      valueContent = `[${value
        .map((element) => {
          if (item.item === "channels" && channels !== null)
            return channels[element];
          else if (item.item === "roles" && roles !== null)
            return roles[element];
          else if (field === "revs" && Array.isArray(element))
            return element[0];
          else return element;
        })
        .toString()}]`;
    else if (value === null || value === undefined) valueContent = "Null";
    else if (typeof value === "object" && field !== "revs")
      valueContent = (
        <Tooltip
          className="italic cursor-default"
          title={JSON.stringify(value)}
        >
          Hover to read
        </Tooltip>
      );
    else {
      if (field === "category") {
        if (value === 0) valueContent = "Content Rule";
        else if (value === 1) valueContent = "Username Rule";
      } else valueContent = value?.toString() || "Null";
    }

    return (
      <span>
        {fieldContent}: {valueContent}
      </span>
    );
  };

  const columns = data
    ? [
        {
          title: "Type",
          dataIndex: "item_type",
          key: "item_type",
          width: 70,
          render: (value) => {
            if (value === "universal rule") value = "universal";
            return (
              <Badge
                color={
                  value === "spotlight"
                    ? "gray"
                    : value === "incident"
                    ? "blue"
                    : "yellow"
                }
              >
                {value}
              </Badge>
            );
          },
          sorter: (item1, item2) =>
            item1.item_type < item2.item_type
              ? -1
              : item1.item_type > item2.item_type
              ? 1
              : 0,
        },
        {
          title: "What's Changed",
          dataIndex: "name",
          key: "name",
          render: (value, { item_type }) => {
            if (item_type === "universal rule") value = "Universal Rule";
            return (
              <div
                className={`font-semibold text-base ${
                  item_type === "rule" ? "cursor-pointer" : ""
                }`}
              >
                {value}
              </div>
            );
          },
          onCell: ({ item_type, name }) => ({
            onClick:
              item_type === "rule"
                ? () => {
                    navigate(`/${guildId}/moderation/rule/${name}`);
                  }
                : () => {},
          }),
        },
        {
          title: "Count",
          dataIndex: "items",
          key: "items",
          width: 95,
          render: (items) => <span className="text-base">{items.length}</span>,
          sorter: (item1, item2) => item2.items.length - item1.items.length,
        },
      ]
    : [];

  const expandedColumns = data
    ? [
        {
          title: "What's Changed",
          dataIndex: "id",
          key: "id",
          render: (id, { name, item_type }) => {
            let value = name || id;
            if (Array.isArray(value) && value.length > 1)
              value = `[${value.toString()}]`;
            if (item_type === "rule")
              return <div className="cursor-pointer">{value}</div>;
            else return value;
          },
          onCell: ({ item_type, name }) => ({
            onClick:
              item_type === "rule"
                ? () => {
                    navigate(`/${guildId}/moderation/rule/${name}`);
                  }
                : () => {},
          }),
        },
        {
          title: "Before",
          dataIndex: "before",
          key: "before",
          className: "hidden sm:table-cell",
          render: (...args) => renderBeforeAfter(true, ...args),
        },
        {
          title: "After",
          dataIndex: "after",
          key: "after",
          className: "hidden sm:table-cell",
          render: (...args) => renderBeforeAfter(false, ...args),
        },
        {
          title: "Specific",
          dataIndex: "item",
          key: "item",
          sorter: (item1, item2) =>
            item1.item < item2.item ? -1 : item1.item > item2.item ? 1 : 0,
          filters: data
            .filter(
              ({ item }, index) =>
                data.map(({ item }) => item).indexOf(item) === index
            )
            .map(({ item }) => ({
              value: item,
              text: item,
            })),
          onFilter: (value, record) => record.item === value,
          render: (item) => {
            if (item === "revs") return "definition";
            else if (item === "universal") return "use universal";
            else return item;
          },
        },
        {
          title: "User",
          dataIndex: "username",
          key: "username",
          className: "hidden sm:table-cell",
          sorter: (item1, item2) =>
            item1.username < item2.username
              ? -1
              : item1.username > item2.username
              ? 1
              : 0,
          filters: data
            .filter(
              ({ username }, index) =>
                data.map(({ username }) => username).indexOf(username) === index
            )
            .map(({ username }) => ({
              value: username,
              text: username,
            })),
          onFilter: (value, record) => record.username === value,
        },
        {
          title: "Time",
          key: "time",
          dataIndex: "time",
          className: "hidden sm:table-cell",
          render: (time) =>
            time
              ? `${new Date(time * 1000).toLocaleTimeString("en-us", {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}`
              : "",
          sorter: (item1, item2) => item1.time - item2.time,
        },
        {
          title: "Action",
          className: "hidden sm:table-cell",
          render: (_, { _id: id }) => (
            <div className="flex flex-row gap-1">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#00b96b",
                  },
                }}
              >
                <Button
                  className="font-sans"
                  onClick={() =>
                    acceptSuggestion(id).then((success) => {
                      if (success) {
                        setData(
                          data.filter(({ _id: item_id }) => item_id !== id)
                        );
                      }
                    })
                  }
                  type="primary"
                >
                  Accept
                </Button>
              </ConfigProvider>
              <Button
                className="font-sans"
                onClick={() =>
                  rejectSuggestion(id).then((success) => {
                    if (success) {
                      setData(
                        data.filter(({ _id: item_id }) => item_id !== id)
                      );
                    }
                  })
                }
                danger
              >
                Reject
              </Button>
            </div>
          ),
        },
      ]
    : [];

  useEffect(() => requestData(), [requestData]);

  useEffect(() => requestChannels(), [requestChannels]);

  useEffect(() => requestRoles(), [requestRoles]);

  if (error) return <NotFound error={error} guildId={guildId} />;

  if (!data) return <Skeleton active className="mt-4" paragraph={{ rows: 6 }} />;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <h1 className="softer-text">Suggestions</h1>
      </div>

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: constants.colors.primary["light-blue"],
            fontFamily: constants.fonts["sans-header"][0],
          },
        }}
      >
        <Tabs
          items={[
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faGlobe} /> All
                </span>
              ),
              key: "all",
            },
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faLightbulb} /> Spotlights
                </span>
              ),
              key: "spotlight",
            },
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faFlag} /> Incidents
                </span>
              ),
              key: "incident",
            },
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faScaleBalanced} /> Rules
                </span>
              ),
              key: "rule",
            },
          ]}
          onTabClick={(value) => {
            setActiveTab(value === "all" ? null : value);
          }}
          className="w-full softer-text"
        />
      </ConfigProvider>

      <Table
        className="no-top"
        tableLayout="auto"
        dataSource={data.filter(({ item_type }) =>
          activeTab
            ? item_type === activeTab ||
              (activeTab === "rule" && item_type === "universal rule")
            : true
        )}
        columns={columns}
        pagination={{
          showSizeChanger: true,
          defaultPageSize: 20,
        }}
        size="small"
        expandable={{
          expandedRowKeys: expandedRows,
          expandIcon: ({ expandable, expanded, onExpand, record }) =>
            expandable && expanded ? (
              <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
            ) : expandable ? (
              <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
            ) : null,
          expandedRowRender: (record) => (
            <div className="flex flex-row items-center gap-3">
              <div className="w-px self-stretch bg-blue-dark"></div>
              <Table
                className="grow"
                tableLayout="auto"
                dataSource={record.items}
                columns={expandedColumns}
                pagination={{ hideOnSinglePage: true }}
                size="small"
              />
            </div>
          ),
          onExpand: (expanded, record) => {
            setExpandedRows(expanded ? [record.key] : []);
          },
        }}
      />
    </>
  );
}
