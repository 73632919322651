import { Button, ConfigProvider, theme } from "antd";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../components/Card";
import LandingFooter from "../../components/landing/LandingFooter";
import LandingHeader from "../../components/landing/LandingHeader";
import constants from "../../constants";
import collect from "../../images/landing/collect.png";

export default function Main() {
  const navigate = useNavigate();
  const [scrollStatuses, setScrollStatuses] = useState([-1, -1, -1, -1, -1]);

  const scrollEvent = useCallback(
    (index, event) => {
      const target = event.target;
      let start = target.scrollLeft <= 0;
      let end =
        target.scrollLeft + target.getBoundingClientRect().width + 1 >=
        target.scrollWidth;

      if (
        (start && scrollStatuses[index] !== -1) ||
        (end && scrollStatuses[index] !== 1) ||
        (!start && !end && scrollStatuses[index] !== 0)
      ) {
        setScrollStatuses((old) => [
          ...old.slice(0, index),
          start ? -1 : end ? 1 : 0,
          ...old.slice(index + 1),
        ]);
      }
    },
    [scrollStatuses]
  );

  return (
    <div className="relative no-zoom">
      <div className="-z-10 absolute inset-0 bg-white" />
      <div className="sm:hidden -z-10 absolute w-full flex flex-row justify-center top-[50px]">
        <div className="landing-bg h-[400px] w-[400px]" />
      </div>
      <div className="sm:hidden -z-10 absolute w-full flex flex-row justify-center top-[650px]">
        <div className="landing-bg h-[300px] w-[300px]" />
      </div>
      <div className="sm:hidden -z-10 absolute w-full flex flex-row justify-start -ml-[50px] top-[850px]">
        <div className="landing-bg h-[300px] w-[300px]" />
      </div>

      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: constants.colors["secondary"],
          },
        }}
      >
        <div className="min-h-screen flex flex-col">
          <LandingHeader
            simple
            landing
            className="fixed box-border h-12 sm:h-16 w-full"
          />

          <div className="h-12 sm:h-16" />
          <div className="flex flex-col items-center gap-12 sm:gap-20 sm:pb-20 sm:bg-[url('/src/images/landing/animatedbackground.gif')] sm:bg-cover">
            <div className="h-8" />
            <p className="font-landing-header text-primary-dark font-bold text-4xl sm:text-5xl lg:text-6xl self-center text-center">
              <span
                className="relative inline-block color-wave-text text-transparent bg-clip-text
              after:content-['Unleash'] after:absolute after:inset-0 after:bg-clip-text
              after:bg-gradient-to-r after:from-[#ffffff00] after:to-[#A1F7FDdd]"
              >
                Unleash
              </span>{" "}
              Your Community's
              <br />
              Potential
              {/* <Eye className="ml-1 -mb-2 sm:-mb-1 lg:mb-0" height="40" /> */}
            </p>
            <p className="font-landing text-primary-dark text-center text-xl sm:text-2xl lg:text-3xl leading-6 px-10 sm:w-1/2">
              Allow your community team to focus on driving growth and positive
              engagement by{" "}
              <span className="text-primary-medium font-semibold italic">
                streamlining moderation, data collection and sentiment analysis.
              </span>
            </p>
            <Button
              className="self-center w-64 h-20 border-primary-dark/50"
              onClick={() => navigate("/contact")}
            >
              <span className="font-landing-header capitalize text-2xl font-semibold text-primary-medium">
                Request Demo
              </span>
            </Button>
            <div className="flex flex-col gap-3 items-center sm:mt-16">
              <iframe
                className="block sm:hidden"
                width="300"
                height="180"
                src="https://www.youtube.com/embed/i1vx4b1SajE?si=iznyt0jUmklgNtQI?modestbranding=1&autohide=1&showinfo=0"
                title="SpecifEye Demo"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <iframe
                className="hidden sm:block md:hidden"
                width="600"
                height="360"
                src="https://www.youtube.com/embed/i1vx4b1SajE?si=iznyt0jUmklgNtQI?modestbranding=1&autohide=1&showinfo=0"
                title="SpecifEye Demo"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <iframe
                className="hidden md:block"
                width="900"
                height="540"
                src="https://www.youtube.com/embed/i1vx4b1SajE?si=iznyt0jUmklgNtQI?modestbranding=1&autohide=1&showinfo=0"
                title="SpecifEye Demo"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p className="font-landing text-primary-dark text-center text-sm sm:text-lg leading-4 px-10 sm:pt-10">
                Our product will help you extract community feedback and turn it
                into clear, actionable insights to enable faster, data-driven
                decisions.
              </p>
            </div>
          </div>

          <p
            className="font-landing-header self-stretch bg-primary-dark-blue text-center text-blue-baby
          font-semibold sm:font-bold text-2xl sm:text-5xl py-8 sm:py-16 my-8 sm:mt-0"
          >
            Why Choose
            <br />
            SpecifEye
          </p>

          <div className="flex sm:hidden flex-col items-center gap-8">
            <div className="relative w-full">
              <div
                className={`absolute z-10 left-0 top-0 bottom-0 shadow-[0px_0px_50px_20px_rgba(0,0,0,0.8)] transition-all ${
                  scrollStatuses[0] < 0 ? "opacity-0" : "opacity-100"
                }`}
              />
              <div
                className={`absolute z-10 right-0 top-0 bottom-0 shadow-[0px_0px_50px_20px_rgba(0,0,0,0.8)] transition-all ${
                  scrollStatuses[0] > 0 ? "opacity-0" : "opacity-100"
                }`}
              />
              <div
                className="flex flex-row items-stretch gap-2 overflow-x-scroll no-scrollbar w-full px-4 box-border"
                onScroll={(event) => scrollEvent(0, event)}
              >
                <div className="flex flex-col items-stretch gap-2 w-[90%] shrink-0">
                  <div
                    className="flex flex-row items-center justify-around h-40
                px-8 bg-[#E4E4E4]"
                  >
                    <img
                      className="object-scale-down h-28 -m-5"
                      src={collect}
                      alt="Collection Graphic"
                    />
                    <p className="font-landing text-lg leading-6 text-center text-primary-dark font-semibold">
                      Real-Time
                      <br />
                      Feedback
                      <br />
                      Collection
                    </p>
                  </div>
                  <div className="h-4 bg-[#E4E4E4]"></div>
                </div>
                <div className="flex flex-col items-stretch gap-2 w-[90%] shrink-0">
                  <div className="flex flex-row items-center justify-between h-40 bg-[#E4E4E4]">
                    <p className="font-landing p-4 text-md text-center text-primary-dark font-semibold">
                      Capture and analyze user feedback in real-time. Our system
                      aggregates comments, suggestions, and concerns, providing
                      actionable insights to improve your game and enhance user
                      experience.
                    </p>
                  </div>
                  <div className="h-4 bg-[#E4E4E4]"></div>
                </div>
              </div>
            </div>

            <div className="relative w-full">
              <div
                className={`absolute z-10 left-0 top-0 bottom-0 shadow-[0px_0px_50px_20px_rgba(0,0,0,0.8)] transition-all ${
                  scrollStatuses[1] < 0 ? "opacity-0" : "opacity-100"
                }`}
              />
              <div
                className={`absolute z-10 right-0 top-0 bottom-0 shadow-[0px_0px_50px_20px_rgba(0,0,0,0.8)] transition-all ${
                  scrollStatuses[1] > 0 ? "opacity-0" : "opacity-100"
                }`}
              />
              <div
                className="relative flex flex-row items-stretch gap-2 overflow-x-scroll no-scrollbar w-full px-4 box-border"
                onScroll={(event) => scrollEvent(1, event)}
              >
                <div className="flex flex-col items-stretch gap-2 w-[90%] shrink-0">
                  <div
                    className="flex flex-row items-center justify-around h-40
                px-8 bg-primary-dark-blue"
                  >
                    <p className="font-landing text-lg leading-6 text-center text-white font-semibold">
                      Automated
                      <br />
                      Moderation
                    </p>
                    <svg
                      width="92"
                      height="114"
                      viewBox="0 0 92 114"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M59.1251 1.58333C59.1251 0.777917 58.4722 0.125 57.6667 0.125H16.8334C7.97384 0.125 0.791748 7.30711 0.791748 16.1667V97.8333C0.791748 106.693 7.97384 113.875 16.8334 113.875H75.1668C84.0263 113.875 91.2084 106.693 91.2084 97.8333V40.3578C91.2084 39.5524 90.5555 38.8995 89.7501 38.8995H63.5001C61.0838 38.8995 59.1251 36.9408 59.1251 34.5245V1.58333ZM57.8505 63.792C59.7373 62.2826 62.4905 62.5885 63.9999 64.4753C65.5093 66.3621 65.2034 69.1152 63.3166 70.6246L48.7686 82.2631C48.0179 82.8772 47.0593 83.2467 46.0146 83.25L46.0004 83.25L45.9635 83.2498C44.9366 83.2414 43.994 82.8791 43.2516 82.2789L28.6839 70.6246C26.7971 69.1152 26.4912 66.3621 28.0006 64.4753C29.51 62.5885 32.2632 62.2826 34.15 63.792L41.6254 69.7724V49.7083C41.6254 47.2921 43.5842 45.3333 46.0004 45.3333C48.4166 45.3333 50.3754 47.2921 50.3754 49.7083V69.7721L57.8505 63.792Z"
                        fill="#F3B917"
                      />
                      <path
                        d="M67.8751 3.47412C67.8751 2.3979 68.9988 1.71456 69.8362 2.39059C70.5415 2.96 71.1755 3.62709 71.7165 4.3807L89.2932 28.8684C89.6934 29.426 89.26 30.1495 88.5737 30.1495H69.3334C68.528 30.1495 67.8751 29.4966 67.8751 28.6912V3.47412Z"
                        fill="#F3B917"
                      />
                    </svg>
                  </div>
                  <div className="h-4 bg-primary-dark-blue"></div>
                </div>
                <div className="flex flex-col items-stretch gap-2 w-[90%] shrink-0">
                  <div className="flex flex-row items-center justify-between h-40 bg-primary-dark-blue">
                    <p className="font-landing p-4 text-md text-center text-white font-semibold">
                      Our AI-driven system continuously monitors your Discord
                      server, automating moderation tasks to maintain a healthy
                      community environment, allowing human moderators to focus
                      on more complex issues.
                    </p>
                  </div>
                  <div className="h-4 bg-primary-dark-blue"></div>
                </div>
                <div className="flex flex-col items-stretch gap-2 w-[90%] shrink-0">
                  <div className="flex flex-row items-center justify-between h-40 bg-primary-dark-blue">
                    <p className="font-landing p-4 text-md text-center text-white font-semibold">
                      You can write your community guidelines in natural
                      language, configure auto-enforcement guidelines, and
                      select roles or channels in which the rules should be
                      applied.
                    </p>
                  </div>
                  <div className="h-4 bg-primary-dark-blue"></div>
                </div>
              </div>
            </div>

            <div className="relative w-full">
              <div
                className={`absolute z-10 left-0 top-0 bottom-0 shadow-[0px_0px_50px_20px_rgba(0,0,0,0.8)] transition-all ${
                  scrollStatuses[2] < 0 ? "opacity-0" : "opacity-100"
                }`}
              />
              <div
                className={`absolute z-10 right-0 top-0 bottom-0 shadow-[0px_0px_50px_20px_rgba(0,0,0,0.8)] transition-all ${
                  scrollStatuses[2] > 0 ? "opacity-0" : "opacity-100"
                }`}
              />
              <div
                className="flex flex-row items-stretch gap-2 overflow-x-scroll no-scrollbar w-full px-4 box-border"
                onScroll={(event) => scrollEvent(2, event)}
              >
                <div className="flex flex-col items-stretch gap-2 w-[90%] shrink-0">
                  <div
                    className="flex flex-row items-center justify-around gap-4 h-40
                px-8 bg-primary-medium"
                  >
                    <svg
                      width="108"
                      height="91"
                      viewBox="0 0 108 91"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M30.0121 1.45555C46.8641 0.185421 63.7909 0.268757 80.6295 1.70475L90.7779 2.5702C98.4436 3.22393 104.665 9.04152 105.83 16.6462L106.471 20.8232C108.643 34.9977 108.44 49.435 105.87 63.5427C104.602 70.5036 98.5378 75.5623 91.4623 75.5623H35.3597C34.069 75.5623 32.7984 75.8821 31.6614 76.4931L7.21899 89.6289C5.76629 90.4096 4.01019 90.3694 2.59468 89.5232C1.17916 88.6769 0.3125 87.149 0.3125 85.4998V32.2667C0.3125 16.1505 12.7338 2.75781 28.8044 1.54657L30.0121 1.45555ZM30 32.3749C25.6853 32.3749 22.1875 35.8726 22.1875 40.1874C22.1875 44.5021 25.6853 47.9999 30 47.9999C34.3147 47.9999 37.8125 44.5021 37.8125 40.1874C37.8125 35.8726 34.3147 32.3749 30 32.3749ZM55 32.3749C50.6853 32.3749 47.1875 35.8726 47.1875 40.1874C47.1875 44.5021 50.6853 47.9999 55 47.9999C59.3147 47.9999 62.8125 44.5021 62.8125 40.1874C62.8125 35.8726 59.3147 32.3749 55 32.3749ZM72.1875 40.1874C72.1875 35.8726 75.6853 32.3749 80 32.3749C84.3147 32.3749 87.8125 35.8726 87.8125 40.1874C87.8125 44.5021 84.3147 47.9999 80 47.9999C75.6853 47.9999 72.1875 44.5021 72.1875 40.1874Z"
                        fill="#F5F3F0"
                      />
                    </svg>
                    <p className="font-landing text-lg leading-6 text-center text-white font-semibold">
                      Comprehensive
                      <br />
                      Message
                      <br />
                      Coverage
                    </p>
                  </div>
                  <div className="h-4 bg-primary-medium"></div>
                </div>
                <div className="flex flex-col items-stretch gap-2 w-[90%] shrink-0">
                  <div className="flex flex-row items-center justify-between h-40 bg-primary-medium">
                    <p className="font-landing p-4 text-md text-center text-white font-semibold">
                      With our solution, every message in your Discord server is
                      monitored, ensuring no feedback or important discussion
                      slips through the cracks.
                    </p>
                  </div>
                  <div className="h-4 bg-primary-medium"></div>
                </div>
              </div>
            </div>

            <div className="relative w-full">
              <div
                className={`absolute z-10 left-0 top-0 bottom-0 shadow-[0px_0px_50px_20px_rgba(0,0,0,0.8)] transition-all ${
                  scrollStatuses[3] < 0 ? "opacity-0" : "opacity-100"
                }`}
              />
              <div
                className={`absolute z-10 right-0 top-0 bottom-0 shadow-[0px_0px_50px_20px_rgba(0,0,0,0.8)] transition-all ${
                  scrollStatuses[3] > 0 ? "opacity-0" : "opacity-100"
                }`}
              />
              <div
                className="flex flex-row items-stretch gap-2 overflow-x-scroll no-scrollbar w-full px-4 box-border"
                onScroll={(event) => scrollEvent(3, event)}
              >
                <div className="flex flex-col items-stretch gap-2 w-[90%] shrink-0">
                  <div
                    className="flex flex-row items-center justify-around gap-4 h-40
                px-8 bg-secondary"
                  >
                    <p className="font-landing text-lg leading-6 text-center text-white font-semibold">
                      Contextual
                      <br />
                      Analysis
                    </p>
                    <svg
                      width="87"
                      height="95"
                      viewBox="0 0 87 95"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M43.4408 0C44.1009 0 44.6072 0.585253 44.5198 1.23952C43.0249 12.4373 43.21 23.8024 45.0749 34.9581L45.1541 35.4316C45.2462 35.9825 45.6671 36.4204 46.2139 36.5341C46.7608 36.6479 47.3214 36.4142 47.6255 35.9457L56.4207 22.3972C56.6343 22.0682 57.1158 22.0682 57.3294 22.3972L66.1245 35.9457C66.4287 36.4142 66.9893 36.6479 67.5361 36.5341C68.083 36.4204 68.5039 35.9825 68.596 35.4316L68.6752 34.9581C70.5401 23.8024 70.7252 12.4373 69.2302 1.23952C69.1429 0.585251 69.6491 0 70.3092 0L78.5417 0C83.029 0 86.6667 3.63769 86.6667 8.125V89.375C86.6667 92.3665 84.2415 94.7917 81.25 94.7917H18.9583C9.40747 94.7917 1.50615 87.7291 0.191991 78.5417H0V24.375C0 10.9131 10.9131 0 24.375 0L43.4408 0ZM18.9583 65H78.5417V86.6667H18.9583C12.9752 86.6667 8.125 81.8164 8.125 75.8333C8.125 69.8503 12.9752 65 18.9583 65Z"
                        fill="#0C163F"
                      />
                    </svg>
                  </div>
                  <div className="h-4 bg-secondary"></div>
                </div>
                <div className="flex flex-col items-stretch gap-2 w-[90%] shrink-0">
                  <div className="flex flex-row items-center justify-between h-40 bg-secondary">
                    <p className="font-landing p-4 text-md text-center text-white font-semibold">
                      Our system understands the context of conversations
                      relating to you product, providing deeper insights into
                      user sentiments and emerging trends within your community.
                    </p>
                  </div>
                  <div className="h-4 bg-secondary"></div>
                </div>
              </div>
            </div>

            <div className="relative w-full">
              <div
                className={`absolute z-10 left-0 top-0 bottom-0 shadow-[0px_0px_50px_20px_rgba(0,0,0,0.8)] transition-all ${
                  scrollStatuses[4] < 0 ? "opacity-0" : "opacity-100"
                }`}
              />
              <div
                className={`absolute z-10 right-0 top-0 bottom-0 shadow-[0px_0px_50px_20px_rgba(0,0,0,0.8)] transition-all ${
                  scrollStatuses[4] > 0 ? "opacity-0" : "opacity-100"
                }`}
              />
              <div
                className="flex flex-row items-stretch gap-2 overflow-x-scroll no-scrollbar w-full px-4 box-border"
                onScroll={(event) => scrollEvent(4, event)}
              >
                <div className="flex flex-col items-stretch gap-2 w-[90%] shrink-0">
                  <div
                    className="flex flex-row items-center justify-around gap-4 h-40
                px-8 bg-primary-medium"
                  >
                    <svg
                      className="w-96"
                      width="97"
                      height="97"
                      viewBox="0 0 97 97"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M91.875 61.25H83.125V48.125C83.125 46.9647 82.6641 45.8519 81.8436 45.0314C81.0231 44.2109 79.9103 43.75 78.75 43.75H52.5V35H61.25C62.4103 35 63.5231 34.5391 64.3436 33.7186C65.1641 32.8981 65.625 31.7853 65.625 30.625V4.375C65.625 3.21468 65.1641 2.10188 64.3436 1.28141C63.5231 0.460936 62.4103 0 61.25 0H35C33.8397 0 32.7269 0.460936 31.9064 1.28141C31.0859 2.10188 30.625 3.21468 30.625 4.375V30.625C30.625 31.7853 31.0859 32.8981 31.9064 33.7186C32.7269 34.5391 33.8397 35 35 35H43.75V43.75H17.5C16.3397 43.75 15.2269 44.2109 14.4064 45.0314C13.5859 45.8519 13.125 46.9647 13.125 48.125V61.25H4.375C3.21468 61.25 2.10188 61.7109 1.28141 62.5314C0.460936 63.3519 0 64.4647 0 65.625V91.875C0 93.0353 0.460936 94.1481 1.28141 94.9686C2.10188 95.7891 3.21468 96.25 4.375 96.25H30.625C31.7853 96.25 32.8981 95.7891 33.7186 94.9686C34.5391 94.1481 35 93.0353 35 91.875V65.625C35 64.4647 34.5391 63.3519 33.7186 62.5314C32.8981 61.7109 31.7853 61.25 30.625 61.25H21.875V52.5H74.375V61.25H65.625C64.4647 61.25 63.3519 61.7109 62.5314 62.5314C61.7109 63.3519 61.25 64.4647 61.25 65.625V91.875C61.25 93.0353 61.7109 94.1481 62.5314 94.9686C63.3519 95.7891 64.4647 96.25 65.625 96.25H91.875C93.0353 96.25 94.1481 95.7891 94.9686 94.9686C95.7891 94.1481 96.25 93.0353 96.25 91.875V65.625C96.25 64.4647 95.7891 63.3519 94.9686 62.5314C94.1481 61.7109 93.0353 61.25 91.875 61.25ZM26.25 70V87.5H8.75V70H26.25ZM39.375 26.25V8.75H56.875V26.25H39.375ZM87.5 87.5H70V70H87.5V87.5Z"
                        fill="#F5BA16"
                      />
                    </svg>

                    <p className="font-landing text-center text-white font-semibold">
                      Our intelligent co-pilot is built using the latest LLMs
                      enhanced with Knowledge Graph-based context from your
                      community.
                    </p>
                  </div>
                  <div className="h-4 bg-primary-medium"></div>
                </div>
                <div className="flex flex-col items-stretch gap-2 w-[90%] shrink-0">
                  <div className="flex flex-row items-center justify-between h-40 bg-primary-medium">
                    <p className="font-landing p-4 text-md text-center text-white font-semibold">
                      Quickly identify and resolve issues flagged by users
                      involves efficient issue tracking, prioritization, root
                      cause analysis, and timely solutions. This process aims to
                      minimize resolution time and enhance user satisfaction by
                      addressing problems promptly and effectively.
                    </p>
                  </div>
                  <div className="h-4 bg-primary-medium"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="hidden sm:flex flex-col xl:flex-row xl:flex-wrap items-center gap-8 px-16 sm:py-16">
            <Card
              noMode
              className="xl:basis-1/3 xl:grow group relative h-48 xl:h-52 w-full max-w-[1000px] box-border"
            >
              <div className="absolute opacity-100 group-hover:opacity-0 transition-all inset-0 flex flex-row justify-center items-center gap-32 py-8">
                <img
                  className="object-scale-down h-28 -m-5"
                  src={collect}
                  alt="Collection Graphic"
                />
                <p className="font-landing-header text-center text-[#0C24F6] font-bold text-4xl">
                  Real-Time
                  <br />
                  Feedback
                  <br />
                  Collection
                </p>
              </div>
              <div className="absolute opacity-0 group-hover:opacity-100 transition-all inset-0 flex flex-col justify-center items-center gap-32 py-8">
                <p className="font-landing text-center text-black font-semibold text-xl px-40 xl:px-10">
                  <span className="text-[#0C24F6]">Capture</span> and{" "}
                  <span className="text-[#0C24F6]">analyze</span> user feedback
                  in real-time. Our system aggregates comments, suggestions, and
                  concerns, providing actionable insights to improve your game
                  and enhance user experience.
                </p>
              </div>
            </Card>

            <Card
              noMode
              className="xl:basis-1/3 xl:grow group relative h-48 xl:h-52 w-full max-w-[1000px] box-border bg-primary-dark-blue"
            >
              <div className="absolute opacity-100 group-hover:opacity-0 transition-all inset-0 flex flex-row justify-center items-center gap-32 py-8">
                <svg
                  width="92"
                  height="114"
                  viewBox="0 0 92 114"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M59.1251 1.58333C59.1251 0.777917 58.4722 0.125 57.6667 0.125H16.8334C7.97384 0.125 0.791748 7.30711 0.791748 16.1667V97.8333C0.791748 106.693 7.97384 113.875 16.8334 113.875H75.1668C84.0263 113.875 91.2084 106.693 91.2084 97.8333V40.3578C91.2084 39.5524 90.5555 38.8995 89.7501 38.8995H63.5001C61.0838 38.8995 59.1251 36.9408 59.1251 34.5245V1.58333ZM57.8505 63.792C59.7373 62.2826 62.4905 62.5885 63.9999 64.4753C65.5093 66.3621 65.2034 69.1152 63.3166 70.6246L48.7686 82.2631C48.0179 82.8772 47.0593 83.2467 46.0146 83.25L46.0004 83.25L45.9635 83.2498C44.9366 83.2414 43.994 82.8791 43.2516 82.2789L28.6839 70.6246C26.7971 69.1152 26.4912 66.3621 28.0006 64.4753C29.51 62.5885 32.2632 62.2826 34.15 63.792L41.6254 69.7724V49.7083C41.6254 47.2921 43.5842 45.3333 46.0004 45.3333C48.4166 45.3333 50.3754 47.2921 50.3754 49.7083V69.7721L57.8505 63.792Z"
                    fill="#F3B917"
                  />
                  <path
                    d="M67.8751 3.47412C67.8751 2.3979 68.9988 1.71456 69.8362 2.39059C70.5415 2.96 71.1755 3.62709 71.7165 4.3807L89.2932 28.8684C89.6934 29.426 89.26 30.1495 88.5737 30.1495H69.3334C68.528 30.1495 67.8751 29.4966 67.8751 28.6912V3.47412Z"
                    fill="#F3B917"
                  />
                </svg>
                <p className="font-landing-header text-center text-[#F3B917] font-bold text-4xl">
                  Automated
                  <br />
                  Moderation
                </p>
              </div>
              <div className="absolute opacity-0 group-hover:opacity-100 transition-all inset-0 flex flex-col justify-center items-center gap-32 py-8">
                <p className="font-landing text-center text-white font-semibold text-lg px-16 xl:px-10">
                  Our AI-driven system continuously monitors your Discord
                  server,{" "}
                  <span className="text-[#F3B917]">
                    automating moderation tasks
                  </span>{" "}
                  to maintain a healthy community environment, allowing human
                  moderators to focus on more complex issues. You can write your
                  community guidelines in natural language, configure
                  auto-enforcement guidelines, and select roles or channels in
                  which the rules should be applied.
                </p>
              </div>
            </Card>

            <Card
              noMode
              className="xl:basis-1/3 xl:grow group relative h-48 xl:h-52 w-full max-w-[1000px] box-border bg-primary-medium"
            >
              <div className="absolute opacity-100 group-hover:opacity-0 transition-all inset-0 flex flex-row justify-center items-center gap-12 py-8">
                <svg
                  width="108"
                  height="91"
                  viewBox="0 0 108 91"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M30.0121 1.45555C46.8641 0.185421 63.7909 0.268757 80.6295 1.70475L90.7779 2.5702C98.4436 3.22393 104.665 9.04152 105.83 16.6462L106.471 20.8232C108.643 34.9977 108.44 49.435 105.87 63.5427C104.602 70.5036 98.5378 75.5623 91.4623 75.5623H35.3597C34.069 75.5623 32.7984 75.8821 31.6614 76.4931L7.21899 89.6289C5.76629 90.4096 4.01019 90.3694 2.59468 89.5232C1.17916 88.6769 0.3125 87.149 0.3125 85.4998V32.2667C0.3125 16.1505 12.7338 2.75781 28.8044 1.54657L30.0121 1.45555ZM30 32.3749C25.6853 32.3749 22.1875 35.8726 22.1875 40.1874C22.1875 44.5021 25.6853 47.9999 30 47.9999C34.3147 47.9999 37.8125 44.5021 37.8125 40.1874C37.8125 35.8726 34.3147 32.3749 30 32.3749ZM55 32.3749C50.6853 32.3749 47.1875 35.8726 47.1875 40.1874C47.1875 44.5021 50.6853 47.9999 55 47.9999C59.3147 47.9999 62.8125 44.5021 62.8125 40.1874C62.8125 35.8726 59.3147 32.3749 55 32.3749ZM72.1875 40.1874C72.1875 35.8726 75.6853 32.3749 80 32.3749C84.3147 32.3749 87.8125 35.8726 87.8125 40.1874C87.8125 44.5021 84.3147 47.9999 80 47.9999C75.6853 47.9999 72.1875 44.5021 72.1875 40.1874Z"
                    fill="#F5F3F0"
                  />
                </svg>
                <p className="font-landing-header text-center text-white font-bold text-4xl">
                  Comprehensive
                  <br />
                  Message Coverage
                </p>
              </div>
              <div className="absolute opacity-0 group-hover:opacity-100 transition-all inset-0 flex flex-col justify-center items-center gap-32 py-8">
                <p className="font-landing text-center text-white font-semibold text-xl px-40 xl:px-10">
                  With our solution, every message in your Discord server is
                  monitored, ensuring no feedback or{" "}
                  <span className="text-secondary">
                    important discussion slips
                  </span>{" "}
                  through the cracks.
                </p>
              </div>
            </Card>

            <Card
              noMode
              className="xl:basis-1/3 xl:grow group relative h-48 xl:h-52 w-full max-w-[1000px] box-border bg-secondary"
            >
              <div className="absolute opacity-100 group-hover:opacity-0 transition-all inset-0 flex flex-row justify-center items-center gap-32 py-8">
                <svg
                  width="87"
                  height="95"
                  viewBox="0 0 87 95"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.4408 0C44.1009 0 44.6072 0.585253 44.5198 1.23952C43.0249 12.4373 43.21 23.8024 45.0749 34.9581L45.1541 35.4316C45.2462 35.9825 45.6671 36.4204 46.2139 36.5341C46.7608 36.6479 47.3214 36.4142 47.6255 35.9457L56.4207 22.3972C56.6343 22.0682 57.1158 22.0682 57.3294 22.3972L66.1245 35.9457C66.4287 36.4142 66.9893 36.6479 67.5361 36.5341C68.083 36.4204 68.5039 35.9825 68.596 35.4316L68.6752 34.9581C70.5401 23.8024 70.7252 12.4373 69.2302 1.23952C69.1429 0.585251 69.6491 0 70.3092 0L78.5417 0C83.029 0 86.6667 3.63769 86.6667 8.125V89.375C86.6667 92.3665 84.2415 94.7917 81.25 94.7917H18.9583C9.40747 94.7917 1.50615 87.7291 0.191991 78.5417H0V24.375C0 10.9131 10.9131 0 24.375 0L43.4408 0ZM18.9583 65H78.5417V86.6667H18.9583C12.9752 86.6667 8.125 81.8164 8.125 75.8333C8.125 69.8503 12.9752 65 18.9583 65Z"
                    fill="#0C163F"
                  />
                </svg>
                <p className="font-landing-header text-center text-[#0D173F] font-bold text-4xl">
                  Contextual
                  <br />
                  Analysis
                </p>
              </div>
              <div className="absolute opacity-0 group-hover:opacity-100 transition-all inset-0 flex flex-col justify-center items-center gap-32 py-8">
                <p className="font-landing text-[#0D173F] font-semibold text-xl px-40 xl:px-10">
                  Our system understands the context of conversations relating
                  to you product, providing deeper insights into{" "}
                  <span className="text-white">user sentiments</span> and{" "}
                  <span className="text-white">emerging trends</span> within
                  your community.
                </p>
              </div>
            </Card>
          </div>

          {/* <p
            className="font-landing-header self-stretch bg-primary-dark-blue text-center text-blue-baby
          font-semibold sm:font-bold text-2xl sm:text-5xl py-8 sm:py-16 my-8"
          >
            See how SpecifEye <br className="inline sm:hidden" />
            can help you <br className="hidden sm:inline" />
            with <br className="inline sm:hidden" />
            the use of your data.
          </p>

          <div className="relative flex flex-col items-stretch gap-8 -my-8 py-8 sm:px-32 sm:py-20">
            <div className="absolute -z-10 inset-0 overflow-hidden">
              <div className="absolute h-full sm:h-[500%] sm:-top-[100%] w-full box-content bg-[url('/src/images/landing/blobbackground.png')] bg-cover" />
              <div className="absolute hidden sm:block min-h-full left-right-animation w-[150%] bg-[url('/src/images/landing/waves.png')] bg-cover opacity-50" />
            </div>
            <div className="flex flex-col sm:flex-row items-center gap-8">
              <div className="grow-0 w-[80%] h-48 sm:w-[1500px] mx-auto bg-white box-border border-solid border-2 border-secondary"></div>
              <p className="grow-0 font-landing text-black px-8 text-center sm:text-left">
                Brief Explanation of the above graph goes here. -Sed ut
                perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
              </p>
            </div>
            <div className="flex flex-col sm:flex-row items-center gap-8">
              <div className="grow-0 w-[80%] h-48 sm:w-[1500px] mx-auto bg-white box-border border-solid border-2 border-secondary"></div>
              <p className="grow-0 font-landing text-black px-8 text-center sm:text-left">
                Brief Explanation of the above graph goes here. -Sed ut
                perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                quae ab illo inventore veritatis et quasi architecto beatae
                vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia
                voluptas sit aspernatur aut odit aut fugit, sed quia
              </p>
            </div>
            <Button
              className="self-center w-64 h-20 bg-primary-medium border-primary-medium"
              onClick={() => navigate("/cases")}
            >
              <span className="font-landing-header capitalize text-2xl font-semibold text-white">
                Case Studies
              </span>
            </Button>
          </div>

          <p
            className="font-landing-header self-stretch bg-primary-dark-blue text-center text-blue-baby
          font-semibold sm:font-bold text-2xl sm:text-5xl py-8 sm:py-16 my-8"
          >
            Our goal is to help our <br className="inline sm:hidden" />
            clients achieve more. <br />
            Lets see what they <br className="inline sm:hidden" />
            have to say.
          </p>

          <div className="relative flex flex-col sm:flex-row items-stretch px-5 sm:px-12 sm:py-20 sm:-mt-8 sm:-mb-16 gap-8 sm:gap-12">
            <div className="absolute -z-10 inset-0 overflow-hidden">
              <div className="absolute hidden sm:block h-[500%] -top-[200%] w-full box-content bg-[url('/src/images/landing/blobbackground.png')] bg-cover" />
            </div>
            <Card
              darkMode={false}
              className="sm:basis-1/2 flex flex-col items-stretch bg-[#F4F4F4] px-8"
            >
              <div className="flex flex-row justify-between gap-5">
                <div className="shrink-0 bg-[#909090] rounded-full w-16 h-16"></div>
                <p className="font-landing font-semibold italic text-black">
                  “SpecifEye has been a game-changer!”
                  <br />- channel xyz
                </p>
              </div>
              <p className="font-landing text-black">
                Placeholder testimonial- "As a server admin for a growing
                community of [number] members, managing our Discord was becoming
                a nightmare. That is until we discovered SpecifEye. This bot has
                been a game-changer! The auto-moderation features are
                incredible. It automatically filters out spam, inappropriate
                language, and even handles basic member requests. The custom
                commands are super flexible and let us create unique experiences
                for our community. The best part is the detailed analytics,
                which help us understand our server's activity better. Highly
                recommend this bot to any server admin looking to save time and
                improve their community!"
              </p>
            </Card>
            <Card
              darkMode={false}
              className="sm:basis-1/2 flex flex-col items-stretch bg-[#F4F4F4] px-8 overflow-hidden"
            >
              <div className="flex flex-row justify-between gap-5">
                <div className="shrink-0 bg-[#909090] rounded-full w-16 h-16"></div>
                <p className="font-landing font-semibold italic text-black">
                  “SpecifEye has been a game-changer!”
                  <br />- channel xyz
                </p>
              </div>
              <div className="bg-[#909090] h-96 -mx-8 -mb-6"></div>
            </Card>
          </div> */}

          <LandingFooter />
        </div>
      </ConfigProvider>
    </div>
  );
}
