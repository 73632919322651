import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Spin } from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { SiteContext } from "../../../Contexts";
import APIManager from "../../../scripts/APIManager";
import Card from "../../Card";

export default function ActiveDayHour(props) {
  const { darkMode } = useContext(SiteContext);
  const { className, chartClassName, guildId, startDate, endDate } = props;
  const chartDiv = useRef(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [HOURS] = useState(
    Array.from(
      { length: 24 },
      (_, index) => `${index % 12 || 12}${index < 12 ? "am" : "pm"}`
    )
  );

  const requestData = useCallback(() => {
    setData(null);
    APIManager.sendRequest(
      "get_analytics_active_day_hour",
      {
        guild_id: guildId,
        offset_min: -new Date().getTimezoneOffset(),
        start_date: startDate,
        end_date: endDate,
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else {
        setData(
          data.map(({ hour, value, weekday }) => ({
            weekday,
            value: Number(value),
            hour: HOURS[hour],
          }))
        );
      }
    });
  }, [guildId, HOURS, startDate, endDate]);

  useEffect(() => {
    if (!data) return;

    const root = am5.Root.new(chartDiv?.current);

    let themes = [am5themes_Animated.new(root)];
    if (darkMode) themes.push(am5themes_Dark.new(root));
    root.setThemes(themes);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        theme: "dark",
      })
    );

    // Axes
    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererX.new(root, {}),
        categoryField: "weekday",
      })
    );
    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        categoryField: "hour",
      })
    );

    // Series
    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        categoryXField: "weekday",
        categoryYField: "hour",
        valueField: "value",
        calculateAggregates: true,
      })
    );

    // Heatmap
    series.set("heatRules", [
      {
        target: series.columns.template,
        min: am5.Color.fromHex(0xfefa76),
        max: am5.Color.fromHex(0xfe3527),
        dataField: "value",
        key: "fill",
      },
    ]);
    chart.bottomAxesContainer.children.push(
      am5.HeatLegend.new(root, {
        orientation: "horizontal",
        startColor: am5.Color.fromHex(0xfefa76),
        endColor: am5.Color.fromHex(0xfe3527),
      })
    );

    // Axes Rendering
    xAxis.get("renderer").setAll({
      minGridDistance: 20,
      opposite: true,
    });
    xAxis.data.setAll([
      { weekday: "Mon" },
      { weekday: "Tue" },
      { weekday: "Wed" },
      { weekday: "Thu" },
      { weekday: "Fri" },
      { weekday: "Sat" },
      { weekday: "Sun" },
    ]);
    yAxis.get("renderer").setAll({
      minGridDistance: 20,
      inversed: true,
    });
    yAxis.data.setAll(
      HOURS.map((h) => ({
        hour: `${h}`,
      }))
    );

    // Series
    series.columns.template.setAll({
      tooltipText:
        "[bold]{value}[/] message(s) sent at [bold]{categoryX}[/] at [bold]{categoryY}[/]",
      stroke: am5.Color.fromHex(0xffffff),
      strokeOpacity: 1,
      strokeWidth: 1,
      width: am5.p100,
      height: am5.p100,
    });
    series.data.setAll(data);

    return () => root.dispose();
  }, [data, HOURS, darkMode]);

  useEffect(() => requestData(), [requestData]);

  return (
    <Card
      className={className}
      header={
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row items-center gap-3">
            <h1>Activity by Hour of Week</h1>
          </div>
          <p className="text-sm">
            Time in{" "}
            {
              new Date()
                .toLocaleTimeString("en-us", { timeZoneName: "short" })
                .split(" ")[2]
            }
          </p>
        </div>
      }
    >
      {data ? (
        <div
          ref={chartDiv}
          className={`w-full h-96 m-2 ${chartClassName}`}
        ></div>
      ) : error ? (
        <p className="m-2">{error}</p>
      ) : (
        <div
          className={`flex flex-row justify-center items-center w-full h-96 m-2 ${chartClassName}`}
        >
          <Spin size="large" />
        </div>
      )}
    </Card>
  );
}
