import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Select, Switch, Tooltip } from "antd";
import { parse as bigParse } from "json-bigint";
import { useCallback, useContext, useEffect, useState } from "react";
import { AccountContext, SiteContext } from "../../Contexts";
import APIManager from "../../scripts/APIManager";
import Badge from "../Badge";
import Card from "../Card";
import Modal from "../Modal";

export default function RolesCard(props) {
  const { activeModal, setActiveModal } = useContext(SiteContext);
  const { signedIn } = useContext(AccountContext);
  const {
    className,
    rule,
    setRule,
    guildId,
    disabled,
    refreshDict,
    isUniversal,
  } = props;
  const { refreshRolesImmediately, setRefreshRolesImmediately } = refreshDict;
  const [options, setOptions] = useState(null);
  const [error, setError] = useState(null);

  const requestRoles = useCallback(() => {
    APIManager.sendRequest(
      "get_roles",
      {
        guild_id: guildId,
      },
      true
    ).then((data) =>
      data.error
        ? setError(`error: ${data.error}`)
        : setOptions(
            Object.entries(data.roles).map(([key, value]) => ({
              label: value,
              value: key,
            }))
          )
    );
  }, [guildId]);

  useEffect(() => {
    if (signedIn) requestRoles();
  }, [signedIn, requestRoles]);

  useEffect(() => {
    if (refreshRolesImmediately && activeModal === null) {
      requestRoles();
      setRefreshRolesImmediately(false);
    }
  }, [
    refreshRolesImmediately,
    activeModal,
    setRefreshRolesImmediately,
    requestRoles,
  ]);

  if (error) return <h1>{error}</h1>;

  if (!options) return <Card className={className} loading />;

  return (
    <Card
      className={className}
      header={
        <div className="flex flex-wrap justify-between items-center gap-3">
          <div
            className={`flex flex-row items-center gap-3 ${
              disabled ? "opacity-30" : ""
            }`}
          >
            <h1>Roles</h1>
            <Tooltip title="Configure what roles are being monitored.">
              <FontAwesomeIcon icon={faCircleInfo} />
            </Tooltip>

            <Badge>
              {rule.role_setting.roles.length}{" "}
              {rule.role_setting.apply_to_all ? "Exempt" : null} Role
              {rule.role_setting.roles.length !== 1 ? "s" : ""}
            </Badge>
          </div>
          {!isUniversal ? (
            <div>
              <Checkbox
                checked={rule.role_setting.is_universal}
                onChange={({ target }) =>
                  target.checked
                    ? setActiveModal("set_universal:roles:on")
                    : setRule((rule) => ({
                        ...rule,
                        role_setting: {
                          ...rule.role_setting,
                          is_universal: false,
                        },
                      }))
                }
              >
                Use Universal
              </Checkbox>
            </div>
          ) : null}
        </div>
      }
    >
      <div className="flex flex-col gap-3 m-2">
        <div className={`flex flex-row gap-3 ${disabled ? "opacity-30" : ""}`}>
          <p className="whitespace-nowrap">Apply to all roles</p>
          <Switch
            disabled={disabled}
            checked={rule.role_setting.apply_to_all}
            onChange={(value) =>
              setRule({
                ...rule,
                role_setting: { ...rule.role_setting, apply_to_all: value },
              })
            }
          />
        </div>
        <div className={`flex flex-col gap-3 ${disabled ? "opacity-30" : ""}`}>
          <p className="whitespace-nowrap">
            {rule.role_setting.apply_to_all ? "Exemptions" : "Roles"}:
          </p>
          <Select
            disabled={disabled}
            key={0}
            mode="multiple"
            size="large"
            placeholder={
              rule.role_setting.apply_to_all ? "Exempt roles" : "Select roles"
            }
            onChange={(value) => {
              setRule((rule) => ({
                ...rule,
                role_setting: {
                  ...rule.role_setting,
                  roles: value.map((role) => bigParse(role)),
                },
              }));
            }}
            value={rule.role_setting.roles.map((role) => role.toString())}
            options={options}
            filterOption={(input, option) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
          />
        </div>
      </div>

      <Modal
        isActive={activeModal === "set_universal:roles:on"}
        onClose={() => setActiveModal(null)}
        className="flex flex-col gap-10 max-w-sm p-10"
      >
        <div className="flex flex-col gap-3">
          <h1>
            Using the Universal Rule will clear your existing configuration for
            roles
          </h1>
        </div>
        <div className="flex flex-row-reverse gap-3">
          <Button
            type="primary"
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              setRule((rule) => ({
                ...rule,
                role_setting: { ...rule.role_setting, is_universal: true },
              }));
            }}
          >
            Confirm
          </Button>
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => setActiveModal(null)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Card>
  );
}
