import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Select, Tooltip } from "antd";
import { parse as bigParse } from "json-bigint";
import { useCallback, useContext, useEffect, useState } from "react";
import { AccountContext, SiteContext } from "../../Contexts";
import APIManager from "../../scripts/APIManager";
import AuthManager from "../../scripts/AuthManager";
import Card from "../Card";
import Modal from "../Modal";

export default function LogCard(props) {
  const { activeModal, setActiveModal } = useContext(SiteContext);
  const { signedIn } = useContext(AccountContext);
  const { className, rule, setRule, guildId, disabled, isUniversal } = props;
  const [options, setOptions] = useState(null);
  const [error, setError] = useState(null);

  const requestChannels = useCallback(() => {
    APIManager.sendRequest(
      "get_moderation_channels",
      {
        token: AuthManager.accessToken,
        guild_id: guildId,
      },
      true
    ).then((data) =>
      data.error
        ? setError(`error: ${data.error}`)
        : setOptions(
            Object.entries(data.channels).map(([key, value]) => ({
              label: value,
              value: key,
            }))
          )
    );
  }, [guildId]);

  useEffect(() => {
    if (signedIn) requestChannels();
  }, [signedIn, requestChannels]);

  if (error) return <h1>{error}</h1>;

  if (!options) return <Card className={className} loading />;

  return (
    <Card
      className={className}
      header={
        <div className="flex flex-wrap justify-between items-center gap-3 w-full">
          <div
            className={`flex flex-row items-center gap-3 ${
              disabled ? "opacity-30" : ""
            }`}
          >
            <h1>Logging</h1>
            <Tooltip title="Configure where incidents are being logged.">
              <FontAwesomeIcon icon={faCircleInfo} />
            </Tooltip>
          </div>
          {!isUniversal ? (
            <div>
              <Checkbox
                checked={rule.log_channel.is_universal}
                onChange={({ target }) =>
                  target.checked
                    ? setActiveModal("set_universal:log:on")
                    : setRule((rule) => ({
                        ...rule,
                        log_channel: {
                          ...rule.log_channel,
                          is_universal: false,
                        },
                      }))
                }
              >
                Use Universal
              </Checkbox>
            </div>
          ) : null}
        </div>
      }
    >
      <div
        className={`flex flex-row flex-wrap items-center gap-3 m-2 ${
          disabled ? "opacity-30" : ""
        }`}
      >
        <p className="whitespace-nowrap">Log reports in</p>
        <Select
          disabled={disabled}
          key={0}
          size="large"
          placeholder="Log channel"
          onChange={(value) =>
            setRule((rule) => ({
              ...rule,
              log_channel: {
                ...rule.log_channel,
                channel_id: value === "" ? null : bigParse(value),
              },
            }))
          }
          value={rule.log_channel.channel_id?.toString() || ""}
          options={[
            { label: <span className="italic">None</span>, value: "" },
            ...options,
          ]}
          className="basis-1/3"
        />
      </div>

      <Modal
        isActive={activeModal === "set_universal:log:on"}
        onClose={() => setActiveModal(null)}
        className="flex flex-col gap-10 max-w-sm p-10"
      >
        <div className="flex flex-col gap-3">
          <h1>
            Using the Universal Rule will clear your existing configuration for
            logging
          </h1>
        </div>
        <div className="flex flex-row-reverse gap-3">
          <Button
            type="primary"
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              setRule((rule) => ({
                ...rule,
                log_channel: { ...rule.log_channel, is_universal: true },
              }));
            }}
          >
            Confirm
          </Button>
          <Button
            size="large"
            className="w-32 font-sans"
            onClick={() => setActiveModal(null)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    </Card>
  );
}
