import { Link } from "react-router-dom";
import specifeye from "../images/specifeye.png";

export default function Logo(props) {
  const { link, landingLink, size } = props;

  const image = (
    <img
      src={specifeye}
      height={
        size === undefined || size === "medium"
          ? 22
          : size === "small"
          ? 18
          : 22
      }
      alt="SpecifEye"
    />
  );

  return (
    <div className="flex text-xl font-bold italic">
      {link || landingLink ? (
        <Link
          to={link === true ? "/home" : landingLink === true ? "/" : link}
          style={{ color: "inherit" }}
          className="flex"
        >
          {image}
        </Link>
      ) : (
        image
      )}
    </div>
  );
}
