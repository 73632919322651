import { CloseCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { faBell, faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Input,
  Select,
  Skeleton,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { useCallback, useContext, useEffect, useState } from "react";
import { SiteContext } from "../../Contexts";
import Card from "../../components/Card";
import ActionModal from "../../components/moderation/incidents/ActionModal";
import DeleteModal from "../../components/moderation/incidents/DeleteModal";
import ReportList from "../../components/moderation/incidents/ReportList";
import constants from "../../constants";
import APIManager from "../../scripts/APIManager";
import Badge from "../../components/Badge";

export default function Moderation() {
  const {
    guildId,
    rules,
    refreshReportsImmediately,
    setRefreshReportsImmediately,
    permissions,
  } = useContext(SiteContext);
  const { setActiveModal, activeModal } = useContext(SiteContext);
  const [reports, setReports] = useState(null);
  const [navItems, setNavItems] = useState(null);
  const [ruleId, setRuleId] = useState(-1);
  const [counts, setCounts] = useState(null);
  const [reportsCount, setReportsCount] = useState(null);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [keyword, setKeyword] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [actioningReports, setActioningReports] = useState([]);
  const [deletingReports, setDeletingReports] = useState([]);
  const [reportsToDisplay, setReportsToDisplay] = useState([]);
  const [retrievingData, setRetrievingData] = useState(false);
  // const [tableSettings, setTableSettings] = useState({
  //   pagination: null,
  //   filters: null,
  //   sorter: null,
  // });
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const requestReports = useCallback(
    (clearFirst = true) => {
      setRetrievingData(true);
      if (clearFirst) setReports(null);
      APIManager.sendRequest(
        "get_reports",
        {
          guild_id: guildId,
          page: pageNumber,
          page_size: pageSize,
          tab: activeTab,
          keyword,
          start_date: startDate || null,
          end_date: endDate || null,
          rule_id: ruleId >= 0 ? parseInt(ruleId) || null : null,
        },
        true
      ).then((data) => {
        if (data.code && data.code === "NoPermission") setReports([]);
        else if (data.error) setError(`error: ${data.error}`);
        else {
          setReports(
            data.reports
              .map((report) => ({
                ...report,
                time: new Date(report.time),
              }))
              .sort((item1, item2) => item2.time - item1.time)
          );
          setReportsCount(data.count);
        }
        setRetrievingData(false);
      });
    },
    [
      guildId,
      pageNumber,
      pageSize,
      activeTab,
      keyword,
      startDate,
      endDate,
      ruleId,
    ]
  );

  const requestReportsPerRule = useCallback(() => {
    APIManager.sendRequest(
      "get_reports_per_rule",
      {
        guild_id: guildId,
        rule_ids: rules.map(({ id }) => id),
      },
      true
    ).then((data) => {
      if (data.code && data.code === "NoPermission") setReports([]);
      else if (data.error) setError(`error: ${data.error}`);
      else {
        setCounts(data.rules);
      }
    });
  }, [guildId, rules]);

  useEffect(() => setPageNumber(1), [ruleId]);

  useEffect(() => {
    if (rules) requestReportsPerRule();
  }, [rules, requestReportsPerRule]);

  useEffect(() => {
    if (refreshReportsImmediately && activeModal === null) {
      requestReports(false);
      setRefreshReportsImmediately(false);
    }
  }, [
    refreshReportsImmediately,
    setRefreshReportsImmediately,
    activeModal,
    requestReports,
  ]);

  useEffect(() => {
    if (startDate !== null && endDate !== null) requestReports(false);
  }, [startDate, endDate, requestReports]);

  useEffect(() => {
    if (!rules || !reports || !counts) return;

    setNavItems([
      { label: "All Incidents", value: -1 },
      {
        label: "Basic Rules".toUpperCase(),
        options: rules
          .filter((rule) => rule.is_basic)
          .map((rule) => ({
            label: rule.name,
            value: rule.id,
            //badge: counts[rule.id],
          })),
      },
      {
        label: "Custom Rules".toUpperCase(),
        options: rules
          .filter((rule) => !rule.is_basic)
          .map((rule) => ({
            label: rule.name,
            value: rule.id,
            //badge: counts[rule.id],
          })),
      },
    ]);
  }, [counts, rules, reports, startDate, endDate, setNavItems]);

  // Calculate start and end time for date picker
  useEffect(() => {
    let today = new Date();
    let oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    setStartDate(dayjs(oneWeekAgo));
    setEndDate(dayjs(today));
  }, []);

  useEffect(() => {
    if (reports) setReportsToDisplay(reports);
  }, [activeTab, keyword, reports, ruleId, startDate, endDate]);

  if (error) return <h1>{error}</h1>;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <h1 className="softer-text">Incidents</h1>
      </div>

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: constants.colors.primary["light-blue"],
            fontFamily: constants.fonts["sans-header"][0],
          },
        }}
      >
        <Tabs
          tabBarExtraContent={
            <div className="flex flex-row items-center gap-2 mr-2">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: constants.colors.secondary,
                    fontFamily: constants.fonts["sans-header"],
                  },
                }}
              >
                <Select
                  className="w-48"
                  value={!navItems ? "" : ruleId}
                  onChange={(value) => setRuleId(value)}
                  options={navItems}
                  loading={!navItems}
                  size="large"
                />
                <div className="max-w-40">
                  <Input
                    size="large"
                    placeholder="Keywords"
                    prefix={<SearchOutlined />}
                    allowClear={{
                      clearIcon: (
                        <CloseCircleOutlined
                          className={
                            keyword !== null ? "cursor-pointer" : "opacity-0"
                          }
                          onClick={
                            keyword !== null ? () => setKeyword(null) : null
                          }
                        />
                      ),
                    }}
                    defaultValue={keyword}
                    onPressEnter={({ target }) =>
                      target.value === ""
                        ? setKeyword(null)
                        : setKeyword(target.value)
                    }
                  />
                </div>
              </ConfigProvider>
            </div>
          }
          items={[
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faBell} /> Outstanding
                </span>
              ),
              key: 0,
            },
            {
              label: (
                <span className="flex flex-row items-center gap-3 p-2">
                  <FontAwesomeIcon size="lg" icon={faClipboard} /> Actioned
                </span>
              ),
              key: 1,
            },
          ]}
          onTabClick={(value) => {
            setActiveTab(value);
          }}
          className="w-full softer-text"
        />
      </ConfigProvider>
      <div className="flex flex-row justify-between items-center gap-3 m-2">
        <div className="flex flex-row items-center gap-2">
          <p className="softer-text">
            {selectedItems.length} Item
            {selectedItems.length === 1 ? "" : "s"} Selected:
          </p>
          {activeTab === 0 ? (
            <Tooltip
              title={
                permissions?.["Enforce Incidents"] ||
                permissions?.["Suggest Moderation Edits"]
                  ? null
                  : "You do not have permission."
              }
            >
              <div>
                <Button
                  onClick={() => {
                    setActioningReports(selectedItems);
                    setActiveModal("action");
                  }}
                  disabled={
                    selectedItems.length === 0 ||
                    (!permissions?.["Enforce Incidents"] &&
                      !permissions?.["Suggest Moderation Edits"])
                  }
                >
                  Take Action
                </Button>
              </div>
            </Tooltip>
          ) : null}
          <Tooltip
            title={
              permissions?.["Enforce Incidents"] ||
              permissions?.["Suggest Moderation Edits"]
                ? null
                : "You do not have permission."
            }
          >
            <div>
              <Button
                onClick={() => {
                  setDeletingReports(selectedItems);
                  setActiveModal("delete");
                }}
                disabled={
                  selectedItems.length === 0 ||
                  (!permissions?.["Enforce Incidents"] &&
                    !permissions?.["Suggest Moderation Edits"])
                }
              >
                Delete
              </Button>
            </div>
          </Tooltip>
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center gap-2">
          {reportsCount !== null ? (
            <div className="flex flex-row items-center gap-1">
              <Badge color="yellow">
                {reportsCount} incident
                {reportsCount === 1 ? "" : "s"}
              </Badge>{" "}
              <p className="text-sm softer-text">
                in {activeTab === 0 ? "Outstanding" : "Actioned"} -{" "}
                {ruleId >= 0
                  ? rules.filter(({ id }) => id === ruleId)[0].name
                  : "All"}
              </p>
            </div>
          ) : (
            <Spin />
          )}
          {startDate !== null && endDate !== null ? (
            <DatePicker.RangePicker
              size="large"
              onChange={(_, [start, end]) => {
                setStartDate(start ? dayjs(start) : undefined);
                setEndDate(end ? dayjs(end) : undefined);
              }}
              value={[startDate ? startDate : null, endDate ? endDate : null]}
              separator={
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-dark-gray-base"
                />
              }
            />
          ) : (
            <Spin />
          )}
        </div>
      </div>
      {reports ? (
        <ReportList
          key={0}
          rules={rules}
          setSelectedItems={setSelectedItems}
          setActioningReports={setActioningReports}
          reports={reportsToDisplay}
          retrievingData={retrievingData}
          // setTableSettings={setTableSettings}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          pageSize={pageSize}
          setPageSize={setPageSize}
          activeTab={activeTab}
          reportsCount={reportsCount}
        />
      ) : (
        <Skeleton active className="mt-2" paragraph={{ rows: 6 }} />
      )}

      {selectedItems.length > 0 ? (
        <>
          <Card
            className="fixed flex flex-row justify-center w-fit left-0 right-0 bottom-0 mb-6 mx-auto"
            opaque
          >
            <div className="flex flex-row items-center gap-2 m-2">
              <p>
                {selectedItems.length} Item
                {selectedItems.length === 1 ? "" : "s"} Selected:
              </p>
              {activeTab === 0 ? (
                <Tooltip
                  title={
                    permissions?.["Enforce Incidents"] ||
                    permissions?.["Suggest Moderation Edits"]
                      ? null
                      : "You do not have permission."
                  }
                >
                  <div>
                    <Button
                      onClick={() => {
                        setActioningReports(selectedItems);
                        setActiveModal("action");
                      }}
                      disabled={
                        !permissions?.["Enforce Incidents"] &&
                        !permissions?.["Suggest Moderation Edits"]
                      }
                    >
                      Take Action
                    </Button>
                  </div>
                </Tooltip>
              ) : null}
              <Tooltip
                title={
                  permissions?.["Enforce Incidents"] ||
                  permissions?.["Suggest Moderation Edits"]
                    ? null
                    : "You do not have permission."
                }
              >
                <div>
                  <Button
                    onClick={() => {
                      setDeletingReports(selectedItems);
                      setActiveModal("delete");
                    }}
                    disabled={
                      !permissions?.["Enforce Incidents"] &&
                      !permissions?.["Suggest Moderation Edits"]
                    }
                  >
                    Delete
                  </Button>
                </div>
              </Tooltip>
            </div>
          </Card>
        </>
      ) : null}

      <ActionModal
        actioningReports={actioningReports}
        setActioningReports={setActioningReports}
        refreshList={requestReports}
      />

      <DeleteModal
        deletingReports={deletingReports}
        setDeletingReports={setDeletingReports}
        refreshList={requestReports}
      />
    </>
  );
}
