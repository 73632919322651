import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Modal from "../Modal";
import { SiteContext } from "../../Contexts";
import { Button, Carousel, Checkbox, Input, Spin, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import APIManager from "../../scripts/APIManager";
import TextArea from "antd/es/input/TextArea";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AddRuleModal(props) {
  const { activeModal, setActiveModal } = useContext(SiteContext);
  const { reloadNav, openNotification } = useContext(SiteContext);
  const { guildId } = props;
  const carousel = useRef();
  const [newRuleName, setNewRuleName] = useState(null);
  const [newRuleDefinition, setNewRuleDefinition] = useState(null);
  const [newRuleIsUsername, setNewRuleIsUsername] = useState(false);
  const [newRuleUseUniversal, setNewRuleUseUniversal] = useState(true);
  const [newRuleIsUrlRule, setNewRuleIsUrlRule] = useState(false);
  const [invalidDefinition, setInvalidDefinition] = useState(true);
  const [currentStep, setCurrentStep] = useState(null);
  const [completedTutorial, setCompletedTutorial] = useState(null);
  const navigate = useNavigate();

  const checkTutorial = useCallback(
    async () =>
      await APIManager.sendRequest("check_tutorial_completion", {}, true).then(
        (data) => setCompletedTutorial(data.completed)
      ),
    []
  );

  const setTutorial = useCallback(
    async () =>
      completedTutorial !== true
        ? await APIManager.sendRequest(
            "set_tutorial_completion",
            {},
            true
          ).then((data) => {
            if (data.success) setCompletedTutorial(true);
          })
        : {},
    [completedTutorial]
  );

  const sendRule = useCallback(
    async () =>
      await APIManager.sendRequest(
        "add_rule",
        {
          guild_id: guildId,
          name: newRuleName,
          username_rule: newRuleIsUsername,
          url: newRuleIsUrlRule,
          description: newRuleDefinition,
          use_universal: newRuleUseUniversal,
        },
        true
      ).then((data) => {
        if (data.error) {
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.error
          );
          return;
        } else if (!data.success || data.message) {
          openNotification(
            data.warning ? "warning" : "error",
            data.warning ? "Warning" : "An Error has Occured",
            data.message
          );
          return;
        } else {
          openNotification("success", "Added", "Your rule has been added.");
          return data.rule_id;
        }
      }),
    [
      openNotification,
      newRuleName,
      newRuleDefinition,
      newRuleIsUsername,
      newRuleUseUniversal,
      newRuleIsUrlRule,
      guildId,
    ]
  );

  const steps = useMemo(
    () => [
      <div className="flex flex-col gap-3 px-px">
        <h1>Welcome to the Rule Creation Tutorial!</h1>
        <p className="text-base">
          A moderaiton rule consists of a rule{" "}
          <span className="font-bold">name</span> and a{" "}
          <span className="font-bold">definition</span>. SpecifEye Moderation checks
          messages against each rule's definition, so be sure they are
          informative and specific to help the system achieve peak performance.
          The following tutorial will assist you in crafting high-quality
          definitions for your moderation rule.
        </p>
      </div>,
      <div className="flex flex-col gap-3 px-px">
        <h1>Be explicit about what should not be allowed</h1>
        <p className="font-bold text-base">Examples:</p>
        <ol>
          <li>
            Rule Name: Hate Speech <br /> Definition:{" "}
            <span className="font-bold">
              No bigotry or hate speech, such as but not limited to homophobia,
              transphobia, racism, sexism, ableism, antisemitism, etc.
            </span>{" "}
            Profanity is allowed as long as it is not directed at someone.
          </li>
          <li>
            Rule Name: Politics <br /> Definition:{" "}
            <span className="font-bold">
              Please avoid initiating or engaging in serious discussions about
              politics or religion.
            </span>{" "}
            Casual or metaphorical references and personal identity expressions
            are allowed, provided they do not lead to political or religious
            debates.
          </li>
          <li>
            Rule Name: Discord Invites <br /> Definition:{" "}
            <span className="font-bold">
              Discord invite links are not allowed.
            </span>{" "}
            Any other links, for example Discord links to messages, are allowed.
          </li>
        </ol>
      </div>,
      <div className="flex flex-col gap-3 px-px">
        <h1>Also include what should be allowed</h1>
        <p className="font-bold text-base">Examples:</p>
        <ol>
          <li>
            Rule Name: Hate Speech <br /> Definition: No bigotry or hate speech,
            such as but not limited to homophobia, transphobia, racism, sexism,
            ableism, antisemitism, etc.{" "}
            <span className="font-bold">
              Profanity is allowed as long as it is not directed at someone.
            </span>
          </li>
          <li>
            Rule Name: Politics <br /> Definition: Please avoid initiating or
            engaging in serious discussions about politics or religion.{" "}
            <span className="font-bold">
              Casual or metaphorical references and personal identity
              expressions are allowed, provided they do not lead to political or
              religious debates.
            </span>
          </li>
          <li>
            Rule Name: Discord Invites <br /> Definition: Discord invite links
            are not allowed.{" "}
            <span className="font-bold">
              Any other links, for example Discord links to messages, are
              allowed.
            </span>
          </li>
        </ol>
      </div>,
      <div className="flex flex-col gap-3 px-px">
        <h1>You are all set!</h1>
        <p className="font-bold text-base">
          Congratulations! You are ready to create a new rule.
        </p>
      </div>,
      <div className="flex flex-col gap-3 px-px">
        <h1>Add a Rule</h1>
        <div className="flex flex-row gap-3">
          <div className="basis-1/2 flex flex-col gap-3 px-px">
            <label className="flex flex-col gap-2">
              <p>Rule Name</p>
              <Input
                size="large"
                onChange={({ target }) => setNewRuleName(target.value)}
                value={newRuleName}
              />
            </label>
            <label className="flex flex-col gap-2">
              <p>Definition</p>
              <TextArea
                className="max-h-64"
                size="large"
                onChange={({ target }) => {
                  setInvalidDefinition(
                    target.value.split(" ").filter((term) => term.length > 0)
                      .length < 2
                  );
                  setNewRuleDefinition(target.value);
                }}
                value={newRuleDefinition}
              />
            </label>
            <div>
              <Checkbox
                onChange={({ target }) =>
                  setNewRuleUseUniversal(target.checked)
                }
                checked={newRuleUseUniversal}
              >
                Use Universal Settings
              </Checkbox>
            </div>
            <div>
              <Checkbox
                onChange={({ target }) => setNewRuleIsUsername(target.checked)}
                checked={newRuleIsUsername}
              >
                Username Rule
              </Checkbox>
            </div>
            <div className="flex flex-row items-center">
              <Checkbox
                onChange={({ target }) => setNewRuleIsUrlRule(target.checked)}
                checked={newRuleIsUrlRule}
              >
                URL Rule
              </Checkbox>
              <Tooltip title="If this box is unchecked the rule will ignore any URLs contained in messages.">
                <FontAwesomeIcon icon={faCircleInfo} size="xs" />
              </Tooltip>
            </div>
            <p className="text-sm font-normal text-gray-dark dark:text-dark-gray-dark">
              A configurable page will be created for this rule.
            </p>
          </div>
          <div className="basis-1/2">
            <p className="font-bold text-base">Rule Definition Guidelines</p>
            <ol className="text-base">
              <li>
                Give a clear description of what type of messages constitute a
                violation.
              </li>
              <li>
                Limit over-moderation by describing types of messages that may
                be related to the rule definition, but do not constitute a
                violation.
              </li>
              <li>
                Remember, you can always come back and tweak the rule definition
                to help the system moderate better.
              </li>
            </ol>
            <div className="flex flex-row justify-end">
              <Button
                className="font-sans"
                onClick={() => carousel.current?.goTo(0)}
              >
                Go To Tutorial
              </Button>
            </div>
          </div>
        </div>
      </div>,
    ],
    [
      newRuleDefinition,
      newRuleIsUrlRule,
      newRuleIsUsername,
      newRuleName,
      newRuleUseUniversal,
    ]
  );

  useEffect(() => {
    checkTutorial();
  }, [checkTutorial]);

  useEffect(() => {
    if (currentStep === null && completedTutorial !== null) {
      setCurrentStep(0);
      carousel.current?.goTo(completedTutorial ? steps.length - 1 : 0);
    }
  }, [completedTutorial, currentStep, steps]);

  return (
    <Modal
    className="w-full flex flex-col gap-10 mx-32 box-border p-10 overflow-hidden max-w-5xl"
    isActive={activeModal === "add_rule"}
      onClose={() => {
        setActiveModal(null);
        setNewRuleName(null);
        setNewRuleDefinition(null);
        setNewRuleIsUsername(false);
        setNewRuleUseUniversal(true);
        setNewRuleIsUrlRule(false);
        setInvalidDefinition(true);
      }}
      onOpen={() =>
        carousel.current?.goTo(completedTutorial ? steps.length - 1 : 0)
      }
    >
      {completedTutorial === null ? (
        <Spin size="large" />
      ) : (
        <Carousel
          className="font-sans m-2"
          dots={false}
          ref={carousel}
          infinite={false}
          beforeChange={(_, next) => {
            if (currentStep !== null) setCurrentStep(next);
            if (next === steps.length - 1) setTutorial();
          }}
        >
          {steps.map((step, index) => (
            <div key={index}>{step}</div>
          ))}
        </Carousel>
      )}
      <div className="flex flex-row justify-between gap-3 m-2">
        {currentStep === 0 ? (
          <Button
            key="skip_tutorial_button"
            size="large"
            className="font-sans"
            onClick={() => carousel.current?.goTo(steps.length - 1)}
          >
            Skip Tutorial
          </Button>
        ) : (
          <Button
            key="cancel_button"
            size="large"
            className="w-32 font-sans"
            onClick={() => {
              setActiveModal(null);
              setNewRuleName(null);
              setNewRuleDefinition(null);
              setNewRuleIsUsername(false);
              setNewRuleUseUniversal(true);
              setNewRuleIsUrlRule(false);
              setInvalidDefinition(true);
            }}
          >
            Cancel
          </Button>
        )}
        <div className="flex flex-row gap-3">
          {currentStep !== 0 && currentStep !== steps.length - 1 ? (
            <Button
              key="back_button"
              size="large"
              className="w-32 font-sans"
              onClick={() => {
                carousel.current?.prev();
              }}
            >
              Previous
            </Button>
          ) : null}
          {currentStep < steps.length - 1 ? (
            currentStep === 0 ? (
              <Button
                key="start_tutorial_button"
                disabled={currentStep + 1 >= steps.length}
                type="primary"
                size="large"
                className="font-sans"
                onClick={() => {
                  carousel.current?.next();
                }}
              >
                Start Tutorial
              </Button>
            ) : (
              <Button
                key="next_button"
                disabled={currentStep + 1 >= steps.length}
                size="large"
                className="w-32 font-sans"
                onClick={() => {
                  carousel.current?.next();
                }}
              >
                Next
              </Button>
            )
          ) : (
            <Button
              key="add_rule_button"
              disabled={
                newRuleName === null || newRuleName === "" || invalidDefinition
              }
              type="primary"
              size="large"
              className="w-32 font-sans"
              onClick={() => {
                setActiveModal(null);
                sendRule()
                  .then(
                    (value) =>
                      value &&
                      navigate(
                        `/${guildId}/moderation/rule/${encodeURIComponent(
                          value
                        )}`
                      )
                  )
                  .then(() => reloadNav())
                  .then(() => {
                    setNewRuleName(null);
                    setNewRuleDefinition(null);
                    setNewRuleIsUsername(false);
                    setNewRuleUseUniversal(true);
                    setNewRuleIsUrlRule(false);
                    setInvalidDefinition(true);
                  });
              }}
            >
              Add Rule
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
