import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table, Tooltip } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { SiteContext } from "../../../Contexts";
import DiscordIcon from "../../../images/discord-mark-blue.svg";
import Helper from "../../../scripts/Helper";
import Badge from "../../Badge";
import Modal from "../../Modal";

export default function ReportList(props) {
  const {
    reports,
    rules,
    setSelectedItems,
    setActioningReports,
    className,
    retrievingData,
    // setTableSettings,
    pageNumber,
    setPageNumber,
    pageSize,
    setPageSize,
    activeTab,
    reportsCount,
  } = props;
  const { setActiveModal, permissions } = useContext(SiteContext);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [expandedData, setExpandedData] = useState(null);
  const [expandedRecord, setExpandedRecord] = useState(null);
  const [modalExpanded, setModalExpanded] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const expandedEndRef = useRef(null);

  const fields = [
    Table.SELECTION_COLUMN,
    {
      title: "User",
      key: "user",
      dataIndex: "username",
      // filters: reports
      //   .filter(
      //     ({ user_name }, index) =>
      //       reports.map(({ user_name }) => user_name).indexOf(user_name) ===
      //       index
      //   )
      //   .map(({ user_name }) => ({
      //     value: user_name,
      //     text: user_name,
      //   })),
      // onFilter: (value, record) => record.user_name === value,
      // sorter: (item1, item2) =>
      //   item1.user_name < item2.user_name
      //     ? -1
      //     : item1.user_name > item2.user_name
      //     ? 1
      //     : 0,
      render: (user) => (
        <Badge className="sm:max-w-[6rem]" tooltip>
          {user}
        </Badge>
      ),
    },
    {
      title: "Message",
      key: "message",
      dataIndex: "message",
      render: (message) => (
        <Tooltip title={message}>
          <div className="sm:max-w-xs font-mono sm:whitespace-nowrap text-ellipsis overflow-hidden">
            {message}
          </div>
        </Tooltip>
      ),
      // sorter: (item1, item2) =>
      //   item1.message < item2.message
      //     ? -1
      //     : item1.message > item2.message
      //     ? 1
      //     : 0,
    },
    // {
    //   title: "Discord Link",
    //   key: "discord_link",
    //   dataIndex: "url",
    //   className: "hidden sm:table-cell",
    //   render: (url) =>
    //     url ? (
    //       <Badge
    //         link={url}
    //         onClick={(e) => {
    //           e.stopPropagation();
    //         }}
    //       >
    //         Discord
    //       </Badge>
    //     ) : (
    //       "No link"
    //     ),
    // },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      className: "hidden sm:table-cell",
      render: (status) =>
        status === "unreviewed" ? (
          <Badge key="nr" color="red" active>
            Not Reviewed
          </Badge>
        ) : status === "partial" ? (
          <Badge key="i">Partially Reviewed</Badge>
        ) : status === "reviewed" ? (
          <Badge key="r" color="green">
            Reviewed
          </Badge>
        ) : null,
      // sorter: (item1, item2) => item1.status - item2.status,
    },
    {
      title: "Time",
      key: "time",
      dataIndex: "time",
      className: "hidden sm:table-cell",
      render: (time) =>
        time ? (
          <Tooltip
            title={`${new Date(time).toLocaleTimeString("en-us", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}`}
          >
            {Helper.timeAgo(time)}
          </Tooltip>
        ) : (
          ""
        ),
      // sorter: (item1, item2) => item1.time - item2.time,
    },
    ...(activeTab === 1
      ? [
          {
            title: "Enforcer",
            key: "enforcer",
            dataIndex: ["enforcement_action", "enforcer"],
            // filters: reports
            //   .filter(
            //     ({ user_name }, index) =>
            //       reports
            //         .map(({ user_name }) => user_name)
            //         .indexOf(user_name) === index
            //   )
            //   .map(({ user_name }) => ({
            //     value: user_name,
            //     text: user_name,
            //   })),
            // onFilter: (value, record) => record.user_name === value,
            // sorter: (item1, item2) =>
            //   item1.user_name < item2.user_name
            //     ? -1
            //     : item1.user_name > item2.user_name
            //     ? 1
            //     : 0,
            render: (user) =>
              user?.toLowerCase() === "specifeye" ? (
                <Badge className="sm:max-w-[6rem]" color="blue">
                  SPECIFEYE
                </Badge>
              ) : !!user ? (
                <Badge className="sm:max-w-[6rem]" tooltip>
                  {user}
                </Badge>
              ) : null,
          },
        ]
      : []),
    {
      title: "Rule",
      dataIndex: "violations",
      key: "rule",
      render: (violations) =>
        violations ? (
          <div className="flex flex-row items-center gap-1">
            {violations
              // .filter(
              //   ({ rule_id }, index) =>
              //     violations.map(({ rule_id }) => rule_id).indexOf(rule_id) ===
              //     index
              // )
              .map(({ rule_id }, index) => (
                <Badge key={index}>
                  {rules.filter(({ id }) => id === rule_id)[0].name}
                </Badge>
              ))}
          </div>
        ) : null,
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   dataIndex: "enforcement_action",
    //   width: 100,
    //   className: "hidden sm:table-cell",
    //   render: (actions, report) =>
    //     report.status === "reviewed" ? (
    //       !actions ? (
    //         <Badge color="yellow">False Positive</Badge>
    //       ) : (
    //         <div className="flex flex-row flex-nowrap gap-1">
    //           {actions.action ? (
    //             <Badge>
    //               {actions.action.charAt(0).toUpperCase() +
    //                 actions.action.substring(1).toLowerCase()}
    //             </Badge>
    //           ) : null}
    //           {actions.delete ? <Badge>Delete</Badge> : null}
    //         </div>
    //       )
    //     ) : (
    //       <div className="flex flex-row flex-start">
    //         <Tooltip
    //           title={
    //             permissions?.["Enforce Incidents"] ||
    //             permissions?.["Suggest Moderation Edits"]
    //               ? null
    //               : "You do not have permission."
    //           }
    //         >
    //           <Button
    //             className="w-32 font-sans -my-3"
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               setActioningReports(report);
    //               setActiveModal("action");
    //             }}
    //             disabled={
    //               !permissions?.["Enforce Incidents"] &&
    //               !permissions?.["Suggest Moderation Edits"]
    //             }
    //           >
    //             <span className="font-sans"> Take Action</span>
    //           </Button>
    //         </Tooltip>
    //       </div>
    //     ),
    //   // filters: [
    //   //   {
    //   //     text: "Banned",
    //   //     value: "banned",
    //   //   },
    //   //   {
    //   //     text: "Kicked",
    //   //     value: "kicked",
    //   //   },
    //   //   {
    //   //     text: "Deleted",
    //   //     value: "deleted",
    //   //   },
    //   //   {
    //   //     text: "Timed Out",
    //   //     value: "timed_out",
    //   //   },
    //   //   {
    //   //     text: "Warned",
    //   //     value: "warned",
    //   //   },
    //   // ],
    //   // onFilter: (value, record) => record.actions?.[value],
    // },
  ];

  const messageColumns = [
    {
      title: "User",
      key: "username",
      dataIndex: "username",
      width: 80,
      filters: expandedData
        ? expandedData
            .filter(
              ({ username }, index) =>
                expandedData
                  .map(({ username }) => username)
                  .indexOf(username) === index
            )
            .map(({ username }) => ({
              value: username,
              text: username,
            }))
        : null,
      onFilter: (value, record) => record.username === value,
      render: (user) => (
        <Badge className="max-w-[6rem]" tooltip>
          {user}
        </Badge>
      ),
      // sorter: (item1, item2) =>
      //   item1.username < item2.username
      //     ? -1
      //     : item1.username > item2.username
      //     ? 1
      //     : 0,
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (content, item) => (
        <div className="break-words max-w-md">
          <div className="inline font-semibold text-sm font-mono">
            {content}
          </div>
          <div className="inline pl-2">
            {item.url ? (
              <Link to={item.url} target="_blank">
                <Button
                  type="text"
                  icon={
                    <img
                      src={DiscordIcon}
                      width="16"
                      height="16"
                      alt="Discord Icon"
                      className="-mb-1"
                    />
                  }
                />
              </Link>
            ) : (
              ""
            )}
          </div>
        </div>
      ),
      // sorter: (item1, item2) =>
      //   item1.content.at(-1) < item2.content.at(-1)
      //     ? -1
      //     : item1.content.at(-1) > item2.content.at(-1)
      //     ? 1
      //     : 0,
    },
    {
      title: "Time",
      key: "timestamp",
      dataIndex: "created_at",
      render: (time) =>
        time ? (
          <Tooltip
            title={`${new Date(time).toLocaleTimeString("en-us", {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}`}
          >
            {new Date(time).toLocaleTimeString("en-us", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "numeric",
              minute: "2-digit",
              hour12: true,
            })}
          </Tooltip>
        ) : (
          ""
        ),
      // sorter: (item1, item2) => item1.timestamp - item2.timestamp,
    },
    // {
    //   title: "Replies / Emojis",
    //   key: "RepliesAndEmojis",
    //   width: 80,
    //   render: (_, item) => (
    //     <p>
    //       {item.replycount} / {item.emojicount}
    //     </p>
    //   ),
    //   sorter: (item1, item2) =>
    //     item1.replycount +
    //     item1.emojicount -
    //     item2.replycount -
    //     item2.emojicount,
    // },
  ];

  useEffect(() => {
    setSelectedItems([]);
    setSelectedIndexes([]);
  }, [reports, setSelectedItems]);

  useEffect(() => setModalExpanded(false), [reports]);

  return (
    <>
      <Table
        className={className}
        rowClassName={() => "cursor-pointer"}
        dataSource={reports.map((item) => ({
          ...item,
          key: item.id,
        }))}
        tableLayout="auto"
        columns={fields}
        loading={retrievingData}
        // onChange={(pagination, filters, sorter) => {
        //   setTableSettings({ pagination, filters, sorter });
        // }}
        size="small"
        scroll={{ x: "max-content" }}
        pagination={{
          current: pageNumber,
          defaultPageSize: 10,
          pageSize,
          hideOnSinglePage: false,
          showSizeChanger: true,
          total: reportsCount,
          onChange: (page, pageSize) => {
            setPageNumber(page);
            setPageSize(pageSize);
            setSelectedIndexes([]);
            setSelectedItems([]);
          },
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setExpandedRecord(record);
              setExpandedData(record.messages);
              setModalExpanded(true);
            },
          };
        }}
        rowSelection={{
          selectedRowKeys: selectedIndexes,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedIndexes(selectedRowKeys);
            setSelectedItems(selectedRows);
          },
        }}
      />
      <Modal
        className="max-w-5xl gap-0 mx-6"
        isActive={modalExpanded}
        onClose={() => setModalExpanded(false)}
        onOpen={() =>
          expandedEndRef.current?.scrollIntoView({ behavior: "smooth" })
        }
        header={
          <div className="flex flex-row items-center gap-3">
            <div className="flex flex-row items-center gap-1.5">
              <p className="font-medium">Incident from</p>{" "}
              <Badge>{expandedRecord?.username}</Badge>
            </div>
            {expandedRecord && expandedRecord.status === "reviewed" ? (
              !expandedRecord.actions ? (
                <Badge color="yellow">False Positive</Badge>
              ) : (
                <div className="flex flex-row flex-nowrap gap-1">
                  {expandedRecord.actions.action ? (
                    <Badge>
                      {expandedRecord.actions.action.charAt(0).toUpperCase() +
                        expandedRecord.actions.action
                          .substring(1)
                          .toLowerCase()}
                    </Badge>
                  ) : null}
                  {expandedRecord.actions.delete ? <Badge>Delete</Badge> : null}
                </div>
              )
            ) : (
              <div className="flex flex-row flex-start">
                <Tooltip
                  title={
                    permissions?.["Enforce Incidents"] ||
                    permissions?.["Suggest Moderation Edits"]
                      ? null
                      : "You do not have permission."
                  }
                >
                  <Button
                    className="w-32 font-sans -my-3"
                    onClick={(e) => {
                      setActioningReports([expandedRecord]);
                      setActiveModal("action");
                    }}
                    disabled={
                      !permissions?.["Enforce Incidents"] &&
                      !permissions?.["Suggest Moderation Edits"]
                    }
                  >
                    <span className="font-sans"> Take Action</span>
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        }
        closeButton
      >
        {!!expandedData ? (
          <div className="relative max-h-full box-border -m-2 min-h-[310px] overflow-y-hidden">
            <div className="flex flex-col max-h-full box-border pb-4 px-2 mr-12 overflow-y-auto">
              <div className="flex flex-row p-2">
                <p>Context</p>
              </div>
              <div className="overflow-y-auto h-full">
                <Table
                  className="grow filled-header"
                  sticky={true}
                  rowClassName={(record) => {
                    if (
                      new Date(record.created_at).getTime() ===
                      Math.max(
                        ...expandedData.map(
                          ({ created_at }) => new Date(created_at)
                        )
                      )
                    )
                      return "bg-secondary/25";
                    else return "";
                  }}
                  tableLayout="auto"
                  dataSource={expandedData.map((message) => ({
                    ...message,
                    key: message.entry_id,
                  }))}
                  columns={messageColumns}
                  size="small"
                  pagination={false}
                />
                <div ref={expandedEndRef} />
              </div>
            </div>
            <div
              className={`absolute right-0 top-0 bottom-0
              flex flex-col box-border
              ${expanded ? "w-80" : "w-12"}
              h-full transition-all overflow-hidden
              bg-white dark:bg-dark-white z-50
              border-0 border-l border-solid border-black/10 dark:border-[#303030] shadow-lg`}
              onMouseEnter={() => {
                setExpanded(true);
              }}
              onMouseLeave={() => {
                setExpanded(false);
              }}
            >
              <div className="flex flex-row items-center gap-4 p-2 mx-2 border-0 border-b border-solid border-black/10 dark:border-[#303030]">
                <FontAwesomeIcon icon={faList} />
                <p>Details</p>
              </div>
              <div
                className={`relative grow p-2
                after:absolute after:content-[''] after:inset-0 after:transition-all
                after:bg-gradient-to-r after:from-transparent after:to-white after:dark:to-dark-white
                ${expanded ? "after:left-full" : "after:left-0"}`}
              >
                <div className="flex flex-row items-center px-2 whitespace-nowrap">
                  <p className="shrink-0 softer-text text-sm w-24 py-2">User</p>
                  <Badge className="sm:max-w-[6rem]" tooltip>
                    {expandedRecord.username}
                  </Badge>
                </div>
                <div className="flex flex-row items-center px-2 whitespace-nowrap">
                  <p className="shrink-0 softer-text text-sm w-24 py-2">Time</p>
                  <Tooltip
                    title={`${new Date(expandedRecord.time).toLocaleTimeString(
                      "en-us",
                      {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}`}
                  >
                    {Helper.timeAgo(expandedRecord.time)}
                  </Tooltip>
                </div>
                <div className="flex flex-row items-start px-2 whitespace-nowrap">
                  <p className="shrink-0 softer-text text-sm w-24 py-2">Rule(s)</p>
                  <div className="flex flex-col items-start gap-1 my-1.5">
                    {expandedRecord.violations
                      // .filter(
                      //   ({ rule_id }, index) =>
                      //     violations.map(({ rule_id }) => rule_id).indexOf(rule_id) ===
                      //     index
                      // )
                      .map(({ rule_id }, index) => (
                        <Badge className="max-w-[180px]" key={index}>
                          {rules.filter(({ id }) => id === rule_id)[0].name}
                        </Badge>
                      ))}
                  </div>
                </div>
                <div className="flex flex-row items-center px-2 whitespace-nowrap">
                  <p className="shrink-0 softer-text text-sm w-24 py-2">
                    Status
                  </p>
                  {expandedRecord.status === "unreviewed" ? (
                    <Badge key="nr" color="red" active>
                      Not Reviewed
                    </Badge>
                  ) : expandedRecord.status === "partial" ? (
                    <Badge key="i">Partially Reviewed</Badge>
                  ) : expandedRecord.status === "reviewed" ? (
                    <Badge key="r" color="green">
                      Reviewed
                    </Badge>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
}
