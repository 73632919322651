export default function BarList(props) {
  const { items, itemName, valueName, limit, className } = props;

  const itemsSorted = items
    .sort((item1, item2) => item2.value - item1.value)
    .slice(0, limit);

  const maxValue = itemsSorted[0]?.value || 0;

  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      <div className="flex flex-row justify-between items-center px-3 box-border text-sm">
        <p>{itemName || "Item"}</p>
        <p>{valueName || "Value"}</p>
      </div>
      {itemsSorted.map((item, index) => (
        <div key={index} className="relative h-8">
          <div
            className="absolute h-full bg-secondary dark:bg-primary-medium rounded"
            style={{ width: `${Math.ceil((item.value / maxValue) * 100)}%` }}
          />
          <div className="absolute flex flex-row justify-between items-center h-full w-full px-3 box-border">
            <div className="flex flex-row items-center gap-1.5">
              {item.image ? (
                <img
                  className="h-6 rounded-full -ml-1.5"
                  src={item.image}
                  alt="Identifier"
                />
              ) : null}
              <p className="text-gray-base dark:text-white">{item.name}</p>
            </div>
            <p className="text-gray-base dark:text-white">{item.value}</p>
          </div>
        </div>
      ))}
    </div>
  );
}
