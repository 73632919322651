import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Spin } from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { SiteContext } from "../../../Contexts";
import APIManager from "../../../scripts/APIManager";
import Card from "../../Card";

export default function ActiveAuthors(props) {
  const { darkMode } = useContext(SiteContext);
  const { className, chartClassName, guildId, startDate, endDate } = props;
  const chartDiv = useRef(null);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const requestData = useCallback(() => {
    setData(null);
    APIManager.sendRequest(
      "get_analytics_active_authors",
      {
        guild_id: guildId,
        offset_min: -new Date().getTimezoneOffset(),
        start_date: startDate,
        end_date: endDate,
      },
      true
    ).then((data) =>
      data.error ? setError(`error: ${data.error}`) : setData(data)
    );
  }, [guildId, startDate, endDate]);

  useEffect(() => {
    if (!data) return;

    const root = am5.Root.new(chartDiv.current);

    let themes = [am5themes_Animated.new(root)];
    if (darkMode) themes.push(am5themes_Dark.new(root));
    root.setThemes(themes);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        layout: root.verticalLayout,
      })
    );

    const cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, { behavior: "none" })
    );
    cursor.lineY.set("visible", false);

    // Axes
    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: "day", count: 1 },
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );
    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
        maxPrecision: 0,
        min: 0,
      })
    );
    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: "Active users",
        y: am5.p50,
        centerX: am5.p50,
      })
    );

    // Series
    const settings = {
      name: "Authors",
      valueXField: "ts",
      valueYField: "v",
      xAxis: xAxis,
      yAxis: yAxis,
      legendLabelText: "{name}[/][bold #888]{categoryX}[/]",
      legendRangeLabelText: "{name}[/]",
      legendValueText: "{valueY}",
      legendRangeValueText: "[bold #888]-[/]",
      minBulletDistance: 4,
    };
    let series = am5xy.SmoothedXLineSeries.new(root, settings);
    series.bullets.push(() =>
      am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          radius: 3,
          fill: series.get("fill"),
        }),
      })
    );
    series.fills.template.setAll({
      visible: true,
      fillOpacity: 0.1,
    });
    series.data.setAll(data);
    chart.series.push(series);

    // Legend
    const legend = chart.children.unshift(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        marginTop: -20,
        marginBottom: 10,
      })
    );
    legend.data.setAll(chart.series.values);

    return () => root.dispose();
  }, [darkMode, data]);

  useEffect(() => requestData(), [requestData]);

  return (
    <Card
      className={className}
      header={
        <div className="flex flex-row justify-between items-center w-full">
          <div className="flex flex-row items-center gap-3">
            <h1>Active Authors</h1>
          </div>
          <p className="text-sm">
            Time in{" "}
            {
              new Date()
                .toLocaleTimeString("en-us", { timeZoneName: "short" })
                .split(" ")[2]
            }
          </p>
        </div>
      }
    >
      {data ? (
        <div ref={chartDiv} className={`w-full h-96 m-2 ${chartClassName}`}></div>
      ) : error ? (
        <p className="m-2">{error}</p>
      ) : (
        <div
          className={`flex flex-row justify-center items-center w-full h-96 m-2 ${chartClassName}`}
        >
          <Spin size="large" />
        </div>
      )}
    </Card>
  );
}
