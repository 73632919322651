import { Spin } from "antd";
import { useCallback, useEffect, useState } from "react";
import APIManager from "../../../scripts/APIManager";
import BarList from "../BarList";
import Card from "../../Card";

export default function RepliesByAuthor(props) {
  const { className, guildId, startDate, endDate } = props;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const requestData = useCallback(() => {
    setData(null);
    APIManager.sendRequest(
      "get_analytics_top_replies",
      {
        guild_id: guildId,
        start_date: startDate,
        end_date: endDate,
      },
      true
    ).then((data) =>
      data.error ? setError(`error: ${data.error}`) : setData(data)
    );
  }, [guildId, startDate, endDate]);

  useEffect(() => requestData(), [requestData]);

  return (
    <Card className={className} header={<h1>Replies by Author</h1>}>
      {data ? (
        <BarList
          className="m-2"
          items={data}
          itemName="User"
          valueName="Replies"
          limit={10}
        />
      ) : error ? (
        <p className="m-2">{error}</p>
      ) : (
        <div className="flex flex-row justify-center items-center m-2">
          <Spin size="large" />
        </div>
      )}
    </Card>
  );
}
