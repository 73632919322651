const defaultPermissions = {
  "View Moderation": true,
  "Suggest Moderation Edits": true,
  "View Suggestions": false,
  "Edit Rules": false,
  "Edit Rule Status": false,
  "Edit Universal Rule": false,
  "Create Rules": false,
  "Delete Rules": false,
  "Enforce Incidents": false,
  "View Analytics": true,
  "Suggest Analytics Edits": true,
  "Edit Spotlights": false,
  "Edit Spotlight Processing": false,
  "Action Spotlights": false,
  "Grant Permissions": false,
};

export default defaultPermissions;
